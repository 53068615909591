@import "~less/v2/vars.less";

.title {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: @v2-md-font-size;
    .v2Theme-color(@v2-grey-16,@v2-dark-10);
}

.cardItem {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 15px;
    font-weight: 500;
    font-size: @v2-md-font-size;
    border-radius: 10px;
    cursor: pointer;
    .v2Theme-color(@v2-grey-16,@v2-dark-10);
    .v2Theme-background-color(@v2-table-odd-bg-color,@v2-dark-13);

    .label {
        flex: 1;
        margin-left: 15px;
    }

    &_disable {
        .v2Theme-color(@v2-grey-14,@v2-grey-14);
    }

    :global(.iconfont) {
        font-size: 24px;
    }

    :global {
        .ant-radio-inner {
            width: 20px;
            height: 20px;

            &:after {
                top: 4px;
                left: 4px;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.add {
    margin-top: 8px;
    padding: 15px;
    color: @v2-primary-color;
    font-weight: 500;
    font-size: @v2-md-font-size;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    .v2Theme-background-color(@v2-table-odd-bg-color,@v2-dark-13);
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;