@import "../../less/colors.less";

.dialog {
    :global {
        .ant-modal-body {
            padding: 20px 15px 20px 15px !important;
        }

        .ant-modal-content {
            background-color: @colorBackgroundNew;

            :global(html.theme-dark) & {
                background-color: @colorBackgroundNewDark;
            }
        }

        .anticon-question-circle {
            display: none;
        }

        .ant-modal-confirm-content {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .anticon svg {
            display: none;
        }

        .ant-modal-confirm-btns {
            display: none !important;
        }

        .ant-modal-confirm-title {
            margin-top: 15px !important;
        }
    }
}

.dialog_content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 40px;
}

@signButtonWrap: {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 26px;
    padding: 3px;
    border: none;
    border-radius: 2px;
};

@signButtonLargeWrap: {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 26px;
    padding: 3px;
    background: rgba(255, 101, 30, 0.2);
    border: none;
    border-radius: 2px;
};

.signInButtonStyle {
    @signButtonWrap();
    background: rgba(255, 101, 30, 0.2);

    > span {
        color: @other_btn_mark_light;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
    }
}

.signUpButtonStyle {
    @signButtonWrap();
    background: transparent;
    border: 1px solid #e5e5e5;

    > span {
        color: @colorAccent;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
    }
}

.primaryButtonStyle {
    @signButtonWrap();
    width: 90px;
    height: 27px;
    background: @other_btn_mark_light !important;

    > span {
        color: white;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
    }
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;

    .img {
        width: 20px;
        height: 20px;
        margin-top: 10px;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;