html,
body {
    --color-primary: @color-primary;
    --color-primary-50: @color-primary-50;
    --color-primary-100: @color-primary-100;
    --color-primary-200: @color-primary-200;
    --color-primary-300: @color-primary-300;
    --color-primary-400: @color-primary-400;
    --color-primary-500: @color-primary-500;
    --color-primary-600: @color-primary-600;
    --color-primary-700: @color-primary-700;
    --color-primary-800: @color-primary-800;
    --color-primary-900: @color-primary-900;
}

.theme-light,
.theme-normal {
    --color-hover: var(--color-primary-400);
    --color-active: var(--color-primary-600);
    --color-green: 0 176 112;
    --color-red: 240 72 72;
    --color-picol: 0 148 255;
    --color-warning: 240 186 48;
    --color-unactivated: 220 223 230;
    --color-error: 240 72 72;
    --color-accent: 39 40 42;
    --color-accent-weak: 63 64 70;
    --color-accent-sub: 113 115 122;
    --color-secondary: 158 166 184;
    --color-secondary-sub: 244 244 245;

    /* 旧边线色 */
    --color-divider: 228 231 237;

    /* 旧背景色 */
    --color-list-row: 250 251 252;
    --color-card: 255 255 255;
    --color-new-card: 255 255 255;
    --color-drop-down: 255 255 255;
    --color-bg: 242 243 245;

    /* 新版字体颜色 */

    /* 一级文字色 */
    --color-text-100: 39 40 42;

    /* 二级文字色 */
    --color-text-200: 63 64 70;

    /* 三级文字色 */
    --color-text-300: 113 115 122;

    /* 四级文字色 */
    --color-text-400: 161 162 170;

    /* 五级文字色 */
    --color-text-500: 244 244 245;

    /* 新版hover */

    /* hover1 */
    --color-hover-100: 237 237 238;

    /* hover2 */
    --color-hover-200: 230 230 232;

    /* 新版分割线颜色 */

    /* 分割线1 */
    --color-divider-100: 233 233 234;

    /* 分割线2 */
    --color-divider-200: 229 229 229;

    /* 新增底色，最底色 */
    --color-bg-body: 244 244 245;

    /* 新增 card 背景色 */
    --color-bg-100: 250 250 250;
    --color-bg-200: 237 238 240;
    --color-bg-300: 225 226 229;
    --color-bg-400: 161 162 170;

    /* 新增弹窗背景色 */
    --color-bg-modal: 250 250 250;
}

.theme-dark {
    --color-hover: var(--color-primary-400);
    --color-active: var(--color-primary-600);
    --color-green: 10 191 127;
    --color-red: 235 71 71;
    --color-picol: 0 148 255;
    --color-warning: 240 186 48;
    --color-unactivated: 220 223 230;
    --color-error: 240 72 72;
    --color-accent: 244 244 245;
    --color-accent-weak: 161 162 170;
    --color-accent-sub: 113 115 122;
    --color-secondary: 98 103 115;
    --color-secondary-sub: 39 40 42;

    /* 旧边线色 */
    --color-divider: 53 57 66;

    /* 旧背景色 */
    --color-list-row: 26 30 36;
    --color-card: 30 34 41;
    --color-new-card: 26 30 36;
    --color-drop-down: 40 44 51;
    --color-bg: 18 21 25;

    /* 新版字体颜色 */

    /* 一级文字色 */
    --color-text-100: 244 244 245;

    /* 二级文字色 */
    --color-text-200: 161 162 170;

    /* 三级文字色 */
    --color-text-300: 113 115 122;

    /* 四级文字色 */
    --color-text-400: 63 64 70;

    /* 五级文字色 */
    --color-text-500: 39 40 42;

    /* 新版hover */

    /* hover1 */
    --color-hover-100: 37 38 44;

    /* hover2 */
    --color-hover-200: 32 33 39;

    /* 新版分割线颜色 */

    /* 分割线1 */
    --color-divider-100: 37 38 44;

    /* 分割线2 */
    --color-divider-200: 32 33 39;

    /* 新增底色，最底色 */
    --color-bg-body: 0 0 0;

    /* 新增card 背景色 */
    --color-bg-100: 12 13 18;
    --color-bg-200: 26 27 30;
    --color-bg-300: 39 40 42;
    --color-bg-400: 63 64 70;

    /* 新增弹窗背景色 */
    --color-bg-modal: 26 27 30;
}

.ticker {
    --color-increase: 10 191 127;
    --color-decrease: 235 71 71;
}

.theme-dark.ticker {
    --color-increase: 0 176 112;
    --color-decrease: 240 72 72;
}

.ticker-reversed {
    --color-increase: 235 71 71;
    --color-decrease: 10 191 127;
}

.theme-dark.ticker-reversed {
    --color-increase: 240 72 72;
    --color-decrease: 0 176 112;
}

html {
    --font-base: "HarmonyOS Sans", sans-serif;
    --font-mono: var(--font-base);
}

html[lang="zh-CN"] {
    --font-base: "HarmonyOS Sans", "Microsoft YaHei", sans-serif;
    --font-mono: var(--font-base);
}

html[lang="zh-TW"] {
    --font-base: "HarmonyOS Sans", "PingFang TC", "Microsoft YaHei", sans-serif;
    --font-mono: var(--font-base);
}

html[lang="ko"] {
    --font-base: "HarmonyOS Sans", "Noto Sans KR", sans-serif;
    --font-mono: var(--font-base);
}

html[lang="ja"] {
    --font-base: "HarmonyOS Sans", "Noto Sans JP", sans-serif;
    --font-mono: var(--font-base);
}
