@import "~less/v2/vars.less";

.transferInfoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0 40px;

    .label {
        color: @v2-grey-20;
        font-size: @v2-sm-font-size;
        &_light {
            //color: @v2-primary-color;
        }
    }

    .value {
        margin-top: 2px;
        font-size: @v2-md-font-size;
        .v2Theme-color(@v2-grey-16,@v2-dark-10);

        &_light {
            .v2Theme-color(@v2-primary-color,@v2-primary-color);
        }
    }

    .copy {
        width: 26px;
        height: 26px;
        color: @v2-primary-color;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        background-color: fade(@v2-primary-color, 10);
        border-radius: 50%;
        cursor: pointer;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;