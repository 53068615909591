@import "~less/v2/vars.less";

.box {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.selectItem {
    display: flex;
    align-items: center;
    width: 100%;
    .itemInfo {
        display: flex;
        flex: 1;
        flex-direction: column;
        color: #000;
        text-align: left;
        div:first-child {
            font-weight: 700;
            // font-size: 18px;
            line-height: 1.2;
        }
        div:last-child {
            font-size: 14px;
            line-height: 1.2;
            span {
                display: inline-block;
                color: #666;
            }
        }
    }
}

.select {
    width: 100%;
    :global(.ant-select-selection-item) {
        .selectItem {
            > span:last-child {
                display: none;
            }
        }
    }
}

.agreement {
    :global(.pi-modal-body) {
        padding: unset;
    }
}

.agreementDes2 {
    margin-top: 16px;
    margin-bottom: 32px;
}

.agreementDes3 {
    margin-top: 32px;
}

.agreementItem {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.container {
    width: 100%;
    max-width: 600px;
    min-height: 700px;
    margin: 0 auto;
    padding: 50px 20px;

    .selectTitleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }
    .selectTitle {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: @v2-md-font-size;
        .v2Theme-color(@v2-grey-16,@v2-dark-10);
    }

    .addText {
        color: @v2-primary-color;
        font-size: @v2-md-font-size;
        cursor: pointer;

        :global {
            .iconfont {
                margin-right: 2px;
            }
        }
    }
    .selectDes {
        margin-bottom: 16px;
        font-size: @v2-sm-font-size;
        .v2Theme-color(@v2-grey-14,@v2-dark-12);
    }

    .tips {
        font-size: @v2-sm-font-size;
        .v2Theme-color(@v2-grey-14,@v2-dark-12);
    }

    .bottomTips {
        .tips();
        margin-bottom: 20px;
    }

    .addBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        margin-bottom: 16px;
        color: @v2-primary-color;
        font-size: 13px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        .v2Theme-background-color(@v2-table-odd-bg-color,@v2-dark-13);

        :global {
            .iconfont {
                margin-right: 2px;
            }
        }
    }

    .submit {
        display: block;
        margin: 50px auto auto;
        padding: 0 100px;
    }
    :global {
        .ant-form-item {
            margin-bottom: 16px;
        }
        .t-checkbox {
            &:hover {
                .v2DarkTheme({
                    color: white;
                });
            }
        }
    }
}

.selectACHItem {
    display: flex;
    align-items: center;
    width: 100%;
    .itemACHInfo {
        display: flex;
        flex: 1;
        flex-direction: column;
        //color: #000;
        text-align: left;
        div:first-child {
            font-weight: 700;
            // font-size: 18px;
            line-height: 1.2;
        }
        div:last-child {
            font-size: 14px;
            line-height: 1.2;
            span {
                display: inline-block;
                //color: #666;
            }
        }
    }
}

.selectACH {
    width: 100%;
    :global(.ant-select-selection-item) {
        .selectACHItem {
            > span:last-child {
                display: none;
            }
        }
    }
}

.subTitle {
    .v2Theme-color(@v2-grey-14,@v2-dark-12);
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Content {
        width: 500px;
        max-width: 100%;
    }

    &Confirm {
        margin-top: 50px;
        padding: 0 60px;
    }
}

.main {
    :global {
        .ant-modal-body {
            height: 60px;
        }
        .ant-modal-footer {
            border-top: unset;
        }
    }
}

:global(.ant-modal) {
    padding-bottom: 0 !important;
}

.tipsCoverItem {
    :global {
        .ant-col {
            position: unset;
        }
        .ant-form-item-control-input {
            position: unset;
        }
    }
}

.tipsCover {
    @apply absolute w-full h-full top-0 z-10 text-accent-sub;
    .v2Theme-background-color(rgba(255, 255, 255, 0.8),rgba(41, 43, 48, 0.77));
    display: flex;
    flex-direction: column;

    padding: 0 60px;
    backdrop-filter: blur(4px);

    .tipsTitle {
        margin-bottom: 24px;
    }

    button {
        display: block;
        margin: 30px auto 0 auto;
        padding: 0 60px;
    }
}

.tutorial {
    margin-top: -16px;
    color: @v2-grey-20;
    font-size: @v2-sm-font-size;
    text-align: center;

    strong {
        color: @v2-primary-color;
        font-size: @v2-sm-font-size;
    }
}

.checkVisible {
    opacity: 0.1;
    user-select: none;
    pointer-events: none;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;