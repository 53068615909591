@import "~less/v2/vars.less";

.summaryItem {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    font-size: @v2-sm-font-size;
    .v2Theme-color(@v2-grey-16,white);

    .summaryValue {
        font-weight: 500;
    }
}

.amount {
    margin-bottom: 12px;
}

.tips {
    margin-top: 8px;
    color: @v2-grey-14;
    font-size: @v2-sm-font-size;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;