@import "~less/v2/vars.less";
.onlyWhiteList {
    display: flex;
    max-width: 100%;
    margin-bottom: 30px;
    padding: 16px 20px;
    border-radius: 16px;
    @apply w-full;
    .v2Theme-background-color(#fff, #27292e);
    &Title {
        font-size: 14px;
        line-height: 20px;
        .v2Theme-color(@v2-grey-1, @v2-grey-9);
    }
    &Warn {
        font-size: 12px;
        line-height: 17px;
        .v2Theme-color(@v2-grey-5, @v2-grey-5);
    }
    &Text {
        flex: 1;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;