@import "~less/v2/vars.less";

.filter-box {
    display: flex;
    flex: 1;
    // height: 20px;
    color: #9fa7b8;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    .v2MediaTablet({
        width: 40%;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    });
}
.filter-item {
    > span {
        cursor: pointer;
    }
    & + & {
        margin-left: 40px;
    }
    &-active {
        position: relative;
        color: #f6641b;
        &::after {
            position: absolute;
            bottom: -4px;
            left: 50%;
            width: 10px;
            height: 3px;
            background-color: #f6641b;
            border-radius: 20px;
            transform: translateX(-50%);
            content: "";
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;