@import "~less/v2/vars.less";

.selectChannel {
    :global {
        .deleteIcon {
            display: none;
        }
    }
}

.shortcuts {
    :global {
        .pi-radio-button-wrapper {
            width: 78px;
            margin-right: 0;
            background-color: #f7f8fa;
            @apply dark:bg-divider !border-none flex justify-center rounded-none py-8px h-36px font-m hover:text-primary;

            .v2MediaTablet({
                width: 60px;
                height: 32px;
            });

            &:first-child {
                border-bottom-left-radius: 8px;
            }

            &:last-child {
                border-bottom-right-radius: 8px;
            }
        }
    }
}

.submitBtn {
    background: linear-gradient(270.24deg, #fb9600 14.4%, #ff6928 89.04%) !important;
}

.amount {
    :global {
        .pi-input-outline {
            border: none;
        }
        .pi-input-prefix {
            margin-left: 0;
            font-size: 24px;
        }
    }

    input {
        font-size: 24px;
        @apply font-b !pl-6px !caret-primary;
        //caret-color: ;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;