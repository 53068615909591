@import "~less/colors.less";
@import "~less/v2/vars.less";

@placeholdercolor: @other_bottom_tip_text;
@placeholdercolor_dark: #858ca3;

@greenbasecolor: rgba(39, 165, 154, 0.38);

@garybasecolor: @helper_input_light;
@garybasecolor_dark: #383e4b;

@redbasecolor: rgba(239, 83, 79, 0.38);

@dangerbasecolor: @trade_warnerror_dark;

.self-beauty-input {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 12px;
    vertical-align: top;
    input {
        height: 30px;
        padding: 8px;
        color: inherit !important;
        font-weight: 400;
        font-size: inherit;
        line-height: 14px;
        background: none;
        &:focus {
            box-shadow: none;
        }
    }
    .ant-input {
        border: 1px solid #d9d9d9;
    }
    .ant-input-number {
        width: 100%;
        color: inherit;
        background: none;
        &:focus {
            border-color: unset;
            box-shadow: none;
        }
        .ant-input-number-handler-wrap {
            display: none;
            background: none;
            .ant-input-number-handler {
                color: inherit;
                .anticon {
                    color: inherit;
                }
            }
        }
    }
    .shadow-placeholder {
        position: absolute;
        top: 0;
        left: 0.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        height: 100%;
        overflow: hidden;
        color: #d9d9d9;
        font-size: 12px;
        background: none;
        .placeholder-text {
            display: inline-block;
            padding: 0 4px;
            color: inherit;
            background-color: #ffffff;
        }
        &.holder-top {
            transition: top linear 150ms;
            &.active {
                top: -1.5em;
                .placeholder-text {
                    // background: myselfcolot;
                }
            }
        }
    }
    &.step-row {
        .ant-input-number {
            font-size: inherit;
            input {
                padding-right: 54px;
            }
            .ant-input-number-handler-wrap {
                display: block;
                // opacity: 1;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                width: 54px;
                border: none;
                border-left: 1px solid #d9d9d9;
                &::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 1px;
                    height: 16px;
                    margin-top: -7px;
                    margin-left: -0.5px;
                    background-color: #d9d9d9;
                    content: "";
                }
                .ant-input-number-handler {
                    flex: 1;
                    width: 50%;
                    height: 100%;
                    border: none;
                    border-radius: 0;
                    &:hover {
                        height: 100% !important;
                    }
                    .anticon {
                        color: #d9d9d9;
                        &.ant-input-number-handler-up-inner,
                        &.ant-input-number-handler-down-inner {
                            top: 50%;
                            left: 50%;
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            margin-top: -8px;
                            margin-left: -8px;
                            transform: none;
                            svg {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }
    }
    &.step-btn-add {
        svg {
            display: none;
        }
        .anticon {
            &::before {
                display: block;
                width: 16px;
                height: 16px;
                color: inherit;
                font-size: 16px;
                line-height: 14px;
                text-align: center;
                content: "";
            }
            &.ant-input-number-handler-up-inner {
                &::before {
                    width: 16px;
                    height: 18px;
                    font-size: 0;
                    text-indent: -1000px;
                    background-image: url("../../images/trading/add_sub.png");
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-size: auto;
                    transform: scale(0.5);
                    content: "+";
                }
            }
            &.ant-input-number-handler-down-inner {
                &::before {
                    width: 16px;
                    height: 18px;
                    font-size: 0;
                    text-indent: -1000px;
                    background-image: url("../../images/trading/add_sub.png");
                    background-repeat: no-repeat;
                    background-position: -18px 0;
                    background-size: auto;
                    transform: scale(0.5);
                    content: "-";
                }
            }
        }
    }
    &.always-step-btn {
        .ant-input-number {
            .ant-input-number-handler-wrap {
                opacity: 1;
            }
        }
    }
    &.red-light {
        .ant-input {
            border-color: @redbasecolor;
            &:focus {
                box-shadow: none;
            }
        }
        .ant-input-number {
            border-color: @redbasecolor;
            &.ant-input-number-focused {
                box-shadow: none;
            }
        }
        &.step-row {
            .ant-input-number {
                .ant-input-number-handler-wrap {
                    border-color: @redbasecolor;
                    &::after {
                        background-color: @redbasecolor;
                    }
                }
                .ant-input-number-handler {
                    .anticon {
                        color: @redbasecolor;
                    }
                }
            }
        }
        &.step-btn-add {
            .anticon {
                &.ant-input-number-handler-up-inner {
                    &::before {
                        background-position: -32px 1px;
                    }
                }
                &.ant-input-number-handler-down-inner {
                    &::before {
                        background-position: -45px 1px;
                    }
                }
            }
        }
        .shadow-placeholder {
            color: @placeholdercolor;
        }
    }
    &.green-light {
        .ant-input {
            border-color: @greenbasecolor;
            &:focus {
                box-shadow: none;
            }
        }
        .ant-input-number {
            border-color: @greenbasecolor;
            &.ant-input-number-focused {
                box-shadow: none;
            }
        }
        &.step-row {
            .ant-input-number {
                .ant-input-number-handler-wrap {
                    border-color: @greenbasecolor;
                    &::after {
                        background-color: @greenbasecolor;
                    }
                }
                .ant-input-number-handler {
                    .anticon {
                        color: @greenbasecolor;
                    }
                }
            }
        }
        &.step-btn-add {
            .anticon {
                &.ant-input-number-handler-up-inner {
                    &::before {
                        background-position: 0 1px;
                    }
                }
                &.ant-input-number-handler-down-inner {
                    &::before {
                        background-position: -16px 1px;
                    }
                }
            }
        }
        .shadow-placeholder {
            color: @placeholdercolor;
        }
    }
    &.danger-light {
        input {
            color: @dangerbasecolor !important;
        }
        .ant-input {
            border-color: @dangerbasecolor;
            &:focus {
                box-shadow: none;
            }
        }
        .ant-input-number {
            border-color: @dangerbasecolor;
            &.ant-input-number-focused {
                box-shadow: none;
            }
        }
        &.step-row {
            .ant-input-number {
                .ant-input-number-handler-wrap {
                    border-color: @dangerbasecolor;
                    &::after {
                        background-color: @dangerbasecolor;
                    }
                }
                .ant-input-number-handler {
                    .anticon {
                        color: @dangerbasecolor;
                    }
                }
            }
        }
        .shadow-placeholder {
            color: @dangerbasecolor;
        }
    }
    &.gray-light {
        .ant-input {
            border-color: @garybasecolor;
            &:focus {
                box-shadow: none;
            }
        }
        .ant-input-number {
            border-color: @garybasecolor;
            &.ant-input-number-focused {
                box-shadow: none;
            }
        }
        &.step-row {
            .ant-input-number {
                .ant-input-number-handler-wrap {
                    border-color: @garybasecolor;
                    &::after {
                        background-color: @garybasecolor;
                    }
                }
                .ant-input-number-handler {
                    .anticon {
                        color: @garybasecolor;
                    }
                }
            }
        }
        &.step-btn-add {
            .anticon {
                &.ant-input-number-handler-up-inner {
                    &::before {
                        background-position: -64px 1px;
                    }
                }
                &.ant-input-number-handler-down-inner {
                    &::before {
                        background-position: -80px 1px;
                    }
                }
            }
        }
        .shadow-placeholder {
            color: @placeholdercolor;
        }
    }
}

html.theme-dark .self-beauty-input {
    .shadow-placeholder {
        .placeholder-text {
            color: @placeholdercolor_dark;
            background-color: #20232c;
        }
    }
    &.red-light {
        &.step-btn-add {
            .anticon {
                &.ant-input-number-handler-up-inner {
                    &::before {
                        background-position: -32px -17px;
                    }
                }
                &.ant-input-number-handler-down-inner {
                    &::before {
                        background-position: -46px -17px;
                    }
                }
            }
        }
    }
    &.green-light {
        &.step-btn-add {
            .anticon {
                &.ant-input-number-handler-up-inner {
                    &::before {
                        background-position: 0 -17px;
                    }
                }
                &.ant-input-number-handler-down-inner {
                    &::before {
                        background-position: -16px -17px;
                    }
                }
            }
        }
    }
    &.gray-light {
        .ant-input {
            border-color: @garybasecolor_dark;
        }
        .ant-input-number {
            border-color: @garybasecolor_dark;
        }
        &.step-row {
            .ant-input-number {
                .ant-input-number-handler-wrap {
                    border-color: @garybasecolor_dark;
                    &::after {
                        background-color: @garybasecolor_dark;
                    }
                }
                .ant-input-number-handler {
                    .anticon {
                        color: @garybasecolor_dark;
                    }
                }
            }
        }
        &.step-btn-add {
            .anticon {
                &.ant-input-number-handler-up-inner {
                    &::before {
                        background-position: -64px -18px;
                    }
                }
                &.ant-input-number-handler-down-inner {
                    &::before {
                        background-position: -80px -18px;
                    }
                }
            }
        }
        .shadow-placeholder {
            color: @garybasecolor_dark;
        }
    }
    &.danger-light {
        .shadow-placeholder {
            .placeholder-text {
                color: @dangerbasecolor;
            }
        }
    }
}

.self-beauty-tips {
    position: absolute;
    top: 6px;
    right: 8px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background-color: @v2-grey-18;
    border-radius: 50%;
}

html.theme-dark .self-beauty-tips {
    background-color: @v2-dark-18;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;