@import "~less/v2/vars.less";
@import "~less/colors";

@header-height: 60px;
@header-mob-height: 60px;
@block-padding: 10px;
.fixedHolder {
    width: 100%;
    height: @header-height;
    .v2MediaTablet({
        height: @header-mob-height;
    });
}
.headerContainer,
.header {
    transition: all 0.3s ease;
}
.headerPadding(@tb: 0) {
    padding: @tb 30px;
    .v2MediaTablet({
        padding: @tb 20px;
    });
}
.headerContainer {
    position: relative;
    z-index: 99;
    width: 100%;
    line-height: @header-height;
    @apply bg-list-row;
    &.koFont {
        font-family: @home-ko-font-family;
    }
    &.tradingView {
        margin-bottom: 5px;
    }
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        &:not(.showBanner) {
            border-bottom: solid @v2-primary-bg-color-2 1px;
        }
        @apply border-divider;

        .v2DarkTheme({
            border-color: black;
        });
    }
    &.transparent {
        background-color: transparent !important;
        border-bottom: none !important;
        box-shadow: none !important;
        .header {
            .headerPadding;
            > .navLeft,
            > .navRight {
                > .navItem {
                    .signBtn {
                        border-color: #fff;
                        &:hover {
                            border-color: @primary-color;
                        }
                    }
                    &:not(:hover) {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    font-family: poppins, sans-serif;
    .headerPadding();
    > .headerLogo,
    > .navRight {
        flex: 1;
    }
    > .navLeft,
    > .navRight {
        > .navItem {
            max-height: @header-height;
            padding: 0 14px;
            margin: 0 1px;
            white-space: nowrap;
            .v2MediaTablet({
                max-height: @header-mob-height;
            });
        }
    }
    > .navLeft {
        flex: 2;
        justify-content: center;
        text-align: center;
    }
    > .navRight {
        justify-content: right;
        text-align: right;
        > .navItem {
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                .avatarPc {
                    border-color: @primary-color;
                }
            }
        }
    }
}

.headerLogo {
    .v2MediaTablet({
        flex: 1;
    });
    img {
        width: 140px;

        @apply mobile:w-[120px];
    }
    flex-shrink: 0;
}
.navLeft,
.navRight {
    display: flex;
    flex: 1;
    line-height: @header-height;
    > .navItem {
        line-height: @header-height;
        &.active {
            color: @primary-color;
        }
    }
    .v2MediaTablet({
        &,> .navItem {
            line-height: @header-mob-height;
        }
    });
}
.navChecker {
    display: none;
}
.navItem {
    :global(.t-report-link) {
        transition: all 0.1s ease-in-out;
    }

    @apply !text-text-100 !text-[16px] hover:!text-primary relative inline-flex items-center font-m  align-middle;

    .navItemInner {
        @apply h-full flex items-center gap-6px;
    }

    .navSubArrow {
        .transitionAll();

        @apply text-text-200;
    }

    .navSubWrapper {
        .transitionAll(0.1s);

        position: absolute;
        top: 100%;
        overflow: hidden;
        white-space: normal;
        visibility: hidden;
        opacity: 0;
        z-index: 10000;

        &,
        &.center {
            left: 50%;
            transform: translateX(-50%);
        }

        &.right {
            right: 0;
            left: unset;
            transform: translateX(30px);
        }

        &.left {
            left: 0;
            left: unset;
            transform: translateX(-30px);
        }

        .navSub {
            .transitionAll(0.1s);

            max-height: calc(100vh - 80px);
            overflow: auto;
            display: flex;
            margin: 1px 30px 30px 5px;
            padding: 10px 8px;
            line-height: 1.15;
            text-align: left;
            border-radius: 8px;
            transform: translateY(-110%);
            box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);

            @apply bg-bg-modal;

            .navSubGroupWrapper {
                // flex: 1;
                min-width: 220px;

                &.middle {
                    min-width: 272px;
                }

                .navSubGroupTitle {
                    @apply mt-8px mb-10px ml-16px font-b text-lg text-text-100;
                }

                .navSubGroup {
                    &.row2 {
                        .navItem {
                            width: 50%;
                            max-width: 50%;
                        }
                    }

                    .navItem {
                        @apply block w-full text-base text-text-300 font-r rounded-6px pl-16px pr-28px py-16px hover:!text-text-100;

                        &.centerAlign {
                            text-align: center;
                        }

                        .navItemArrowIcon {
                            .transitionAll();

                            font-size: 24px;
                            vertical-align: middle;
                            top: 50%;
                            transform: translateY(-50%);
                            opacity: 0;

                            @apply absolute text-text-100 right-10px;
                        }

                        &.rightEff {
                            @apply text-text-300 flex flex-nowrap justify-between;

                            :last-child {
                                .navItemArrowIcon {
                                    margin-left: 5px;

                                    @apply text-accent-weak;
                                }

                                @apply text-text-100 text-base align-middle;
                            }

                            &:hover {
                                :last-child {
                                    @apply !text-text-100;
                                }
                            }
                        }

                        &.noHover {
                            cursor: auto;
                        }

                        &:not(.noHover):hover {
                            @apply bg-hover-100;

                            &.rightEff {
                                :last-child {
                                    @apply !text-text-100;
                                }
                            }

                            &:not(.rightEff) {
                                .navItemArrowIcon {
                                    opacity: 1;
                                }
                            }
                        }

                        &:has(.navItem:hover) {
                            background-color: transparent;
                        }

                        .alwaysShowArrow {
                            .transitionAll();

                            top: 50%;
                            transform: translateY(-50%);
                            opacity: 1;

                            @apply absolute text-text-100 right-10px;
                        }
                    }
                }
            }

            .navSubMenuGroupWrapper {
                .navSubGroupWrapper;

                // @apply border-0 border-l-2 border-l-bg-400 border-solid;

                margin: -10px 0;
                padding-left: 6px;
            }
        }
    }

    &.notMobile {
        .navSubWrapper {
            pointer-events: none;
        }

        &:hover {
            &::before {
                opacity: 1;
            }

            .navSubArrow {
                transform: rotateZ(180deg);
            }

            .navSubWrapper {
                visibility: visible;
                pointer-events: all;
                opacity: 1;

                .navSub {
                    transform: translateY(0);
                }
            }
        }
    }
}

@swWidth: 20px;
.navMobileSwitcher {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80px;
    i {
        position: relative;
        width: @swWidth;
        &,
        &::before,
        &::after {
            display: inline-block;
            width: @swWidth;
            height: 2px;
            .v2Theme-background-color(#000, #fff);
            .transitionAll();
        }
        &::before,
        &::after {
            position: absolute;
            left: 0;
            content: "";
        }
        &::before {
            top: -7px;
        }
        &::after {
            bottom: -7px;
        }
    }
    &.active {
        i {
            .v2Theme-background-color(transparent, transparent);
            &::before {
                top: 0;
                transform: rotate(45deg);
            }
            &::after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
    }
}

.navMobileWrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - @header-height);
    overflow: hidden;
    border-top: 1px solid #f0f0f2;
    visibility: hidden;
    .v2DarkTheme({
       border-color: #353942;
    });
    .navMobile {
        width: 100%;
        height: 100%;
        padding: 20px;
        overflow-y: auto;
        transform: translateY(-110%);
        .transitionAll(0.4s, all, ease);
        .v2Theme-background-color(#fff, #222632);
        .navMobileBottomHolder {
            width: 100%;
            height: 30%;
        }
    }
    &.visible {
        visibility: visible;
        .navMobile {
            transform: translateY(0);
        }
    }

    .navLeft,
    .navRight {
        display: block;
        > .navItem {
            display: block;
            line-height: 60px;
            border-bottom: 1px solid #f0f0f2;
            .navSubArrow {
                &.mobileOpen {
                    transform: rotateZ(180deg);
                }
            }
            .v2DarkTheme({
                border-bottom-color: #353942;
            });
            &.active {
                border-bottom: none;
            }
            &::before {
                display: none;
            }
            .navItemInner {
                display: flex;
                justify-content: space-between;
            }
            .navSubWrapper {
                position: relative;
                left: unset;
                height: 0;
                margin: 0 -20px;
                transform: none;
                .v2Theme-background-color(#f5f6f7, #1c1f29);
                .navSubGroupWrapper {
                    min-width: auto;
                    max-width: 100%;
                    margin-top: 10px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
                .navSubGroupTitle {
                    margin-bottom: 0;
                    padding-left: 0;
                }
                .navSub {
                    display: block;
                    margin: 0;
                    background-color: transparent;
                    .transitionAll(0.1s, transform);
                    .navItem {
                        padding: 15px 0;
                        &:not(.noHover, .rightEff)::before {
                            right: 5px;
                            color: #3d4047;
                            // opacity: 1;
                        }
                    }
                }
                &.mobileOpen {
                    height: auto;
                    visibility: visible;
                    opacity: 1;
                    .navSub {
                        .transitionAll(0.2s, transform);
                        transform: translateY(0);
                    }
                }
            }
        }
    }
    .navRight {
        > .navItem {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.signBtnMobileBox {
    display: flex;
    margin: 0 -7px;
}
.signBtnMobile {
    flex: 1;
    margin: 0 7px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    border: 1px solid #f0f0f2;
    border-radius: 12px;
    .v2DarkTheme({
        &:not(:first-child) {
            border-color: #F0F0F2;
        }
    });
    &:first-child {
        color: @primary-color !important;
        border-color: @primary-color;
    }
}

.signBtn {
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 32px;
    border: 1px solid #000;
    border-radius: 32px;

    &:hover {
        color: #fff;
        background-color: @primary-color;
        border-color: @primary-color;
    }

    .v2DarkTheme({
      border-color: #fff;
      &:hover {
        border-color: @primary-color;
      }
    });
}

.balanceInfo {
    .bTitle {
        margin-top: 0;
        color: #9fa7b8;
        font-weight: 500;
        font-size: 14px;
    }
    h1 {
        margin-bottom: 0;
        color: #000;
        font-weight: 600px;
        font-size: 24px;
        small {
            display: block;
            margin-top: 5px;
            font-size: 14px;
        }
    }
}

.avatarPc {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    border: 2px solid transparent;
    border-radius: 50%;
    .transitionAll();
    .avatar {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.accountInfo {
    display: flex;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    .avatar {
        width: 60px;
        height: 60px;
    }
    .useInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: calc(100% - 60px);
        padding-left: 20px;
        white-space: nowrap;
        h3 {
            flex: 1;
            max-height: 22px;
            margin-bottom: 5px;
            font-size: 18px;
            line-height: 22px;
        }
        span {
            min-height: 16px;
            color: #9fa7b8;
            font-size: 14px;
            line-height: 16px;
            word-break: break-all;
        }
    }
}

@markOrange: #ff651e;
.download_alert_box {
    padding: 0 !important;
}

.download_alert {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 18px 10px;
    background-color: @colorBackground !important;
    box-shadow: 0 2px 4px 0 rgba(4, 38, 253, 0.08);

    .download_alert_left {
        display: flex;
        flex: 1;
        justify-content: flex-start;
    }

    .download_alert_logo_box {
        margin-right: 8px;

        .download_alert_logo {
            width: 44px;
            height: 44px;
        }
    }

    .download_alert_txtbox {
        .download_alert_name {
            color: @markOrange;
            font-weight: @fontNormalBold;
            font-size: 20px;
            line-height: 23px;
        }

        .download_alert_desc {
            margin: 0;
            color: #969dad;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
        }
    }

    .download_alert_down_btnbox {
        .download_alert_down_btn {
            padding: 5px 12px;
            color: #fcfcfc;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            background: @markOrange;
            border: none;
            border-radius: 17px;
            outline: none;
        }
    }
}

.usdDepositItem {
    display: flex;
    align-items: center;
    min-width: 320px;
    :global(.iconfont) {
        margin-right: 10px;
    }
}

.usdDepositDes1 {
    margin-left: 6px;
    padding: 1px 10px;
    color: @v2-primary-color;
    font-size: @v2-sm-font-size;
    background-color: @v2-primary-bg-color;
    border-radius: 12px;

    &Disable {
        color: @v2-grey-1;
        background-color: @v2-grey-8;
    }
}

.usdDepositDes2 {
    color: @v2-grey-14;
    font-size: @v2-sm-font-size;
}

/* 按钮的样式 */
.buyCrypto {
    width: 96px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(270deg, #e533e8, #fc8440, #aa1bed, #5039fc) center / cover;
    background-size: 800% 800%;
    animation: AnimationName 7s cubic-bezier(0.28, 0.84, 0.42, 1) infinite;
}

@keyframes AnimationName {
    0% {
        background-position: 30% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 30% 50%;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;