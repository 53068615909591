@import "../colors";

html.light,
html.theme-normal {
    .usericont {
        i {
            margin-right: 10px;
        }

        .ant-select-dropdown-menu-item-active {
            background-color: yellow;
        }
    }

    .singborder {
        .ant-input-password-icon {
            color: @colorText !important;
        }
    }

    .container {
        background-color: @colorBackground;
    }

    .index-jdrp-menu {
        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:hover {
            background-color: @colorText;
        }

        .ant-dropdown-menu-item {
            color: @colorTextAccent;
        }
    }

    .arvanumbercolor {
        font-size: 12px;
        text-align: right;
    }

    .listUno {
        font-weight: 600;
        // color: #27a59a;
        font-size: 22px;
        text-align: left;
    }

    .listDos {
        padding-left: 10px;
        font-weight: 600;
        // color: #27a59a;
        font-size: 12px;
        line-height: 30px;
        text-align: left;
    }

    .listTres_text1 {
        color: @colorText;
        font-weight: 400;
        font-size: 12px !important;
        text-align: left;
    }

    .listTres_text2 {
        color: @colorTextAccent;
        font-size: 12px !important;
        text-align: right;
    }

    .listCuarro_text1 {
        color: #858ca3;
        font-weight: 400;
        font-size: 12px !important;
        text-align: left;
    }

    .listCuarro_text2 {
        color: @colorTextAccent;
        font-size: 12px;
        text-align: left;
    }

    .listCuarro_text3 {
        color: @colorAccent;
        font-weight: 500;
        font-size: 10px;
        text-align: right;
    }

    .listUno,
    .listDos,
    .listTres {
        margin-bottom: 10px;
    }

    .setmysele_title {
        height: 36px;
        color: @colorText;
        font-size: 12px;
        text-align: left;
    }

    .setmysele_number {
        height: 36px;
        color: @colorTextAccent;
        font-weight: 500;
        font-size: 12px;
        text-align: right;
    }

    .setmysele_title {
        color: @colorText;
    }

    .setmysele_number {
        color: #fff;
        font-weight: 500;
        font-size: 12px;
    }

    .number_position {
        position: relative;
    }

    .number_Baifenbi {
        position: absolute;
        top: 10px;
        right: 36px;
        z-index: 3;
    }

    .number_Baifenbi label {
        display: inline-block;
        height: 16px;
        margin-right: 4px;
        padding: 0 6px;
        color: white;
        font-size: 12px;
        line-height: 16px;
        background-color: @colorAccent;
        border-radius: 1000px;
    }

    // video
    .rh5v-DefaultPlayer_component {
        height: 100%;
    }

    .rh5v-Overlay_inner {
        border-radius: 50%;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
    }

    .swiper-slide {
        /* Center slide text vertically */
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }

    .swiper-pagination-bullet-active {
        background-color: #387dea !important;
    }

    .swiper-pagination-bullet {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: rgba(255, 255, 255, 0.651);
        border-radius: 100%;
        opacity: unset;
    }

    // input默认样式
    .formItem {
        background-color: #f2f2f2;
    }

    input:-webkit-autofill:focus {
        background-color: #f2f2f2;
        background-image: none;
        border-color: #f2f2f2;
    }

    input:-webkit-autofill:hover {
        background-image: none;
    }

    // .ant-input:focus{
    //   box-shadow: unset !important;
    // }
    // input:focus{
    //   box-shadow: unset !important;
    // }

    ::-webkit-scrollbar-track {
        @apply bg-transparent;
    }

    ::-webkit-scrollbar-thumb {
        @apply rounded-4px;
        @apply bg-divider/50;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: @scrollbarThumb;
        cursor: pointer;
    }

    .main .Resizer.horizontal {
        height: 3px;
        background-color: @horizontal;
    }

    .ant-table-thead > tr,
    .ant-table-thead > tr > th {
        border-bottom-color: @leaderboardTabletitlecolor;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: unset;
    }
}

.isBuButtonBag {
    color: @colorBackground !important;
    background: linear-gradient(90deg, #5793f1 0%, #2d71da 100%) !important;
    border: 1px solid transparent !important;
    border-color: #5793f1 !important;
}

.selected {
    border-left: 3px solid #2d71da !important;
}
