@import "~less/v2/vars.less";

.container {
    max-width: 680px;
    min-height: 700px;
    margin: auto;
    padding: 50px 10px;

    .step {
        font-weight: 500;
        font-size: @v2-md-font-size;
        .v2Theme-color(@v2-grey-16,@v2-dark-10);
    }

    .step2 {
        .step();
        margin-top: 30px;
    }

    .stepDes {
        margin: 4px 0 16px;
        font-size: @v2-sm-font-size;
        .v2Theme-color(@v2-grey-14,@v2-dark-10);
    }

    .tips {
        margin: 30px 0 12px;
        font-size: @v2-md-font-size;
        .v2Theme-color(@v2-grey-14,@v2-dark-10);
    }
    .tips_email {
        margin: 8px 0 24px;
        font-size: @v2-md-font-size;
        .v2Theme-color(@v2-grey-14,@v2-dark-10);
    }

    .amount {
        margin: 8px 0 14px 0;
    }

    .summaryItem {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        font-size: @v2-sm-font-size;
        .v2Theme-color(@v2-grey-16,@v2-dark-10);

        .summaryValue {
            font-weight: 500;
        }
    }

    .swap {
        margin-top: 6px;
        color: @v2-primary-color;
        font-size: @v2-sm-font-size;
        cursor: pointer;
    }

    .next {
        display: block;
        width: 255px;
        margin-top: 50px;
        margin-right: auto;
        margin-left: auto;
    }
}
.confirmItem {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: @v2-md-font-size;
}

.result {
    margin: 80px auto auto;
}

.success {
    margin: 100px auto auto;
}

.confirm {
    display: block;
    width: 255px;
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;
}

.cardForm {
    margin-top: 16px;
}

.switchCardForm {
    margin-top: 8px;
    padding: 15px;
    color: @v2-primary-color;
    font-weight: 500;
    font-size: @v2-md-font-size;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    .v2Theme-background-color(@v2-table-odd-bg-color,@v2-dark-13);
}

.itemTips {
    width: 12px;
    height: 12px;
    margin-left: 4px;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;