@import "../../less/colors";

.search {
    &::placeholder {
        color: @colorText;
    }

    :global {
        .ant-input-search-icon {
            color: unset;
        }
    }
}

.searchStyle {
    padding-right: 8px;
    padding-left: 8px;

    :global {
        .ant-input-affix-wrapper {
            position: unset;
            width: 100%;
            background-color: @windowBackground;
            border: unset !important;
            border-radius: 15px;
            :global(html.theme-dark) & {
                background-color: @windowBackgroundDark;
            }

            &-focused,
            &:focus {
                box-shadow: none;
            }
        }

        .ant-input-search-icon::before {
            border-left: unset !important;
        }

        .ant-input-clear-icon {
            color: @colorText !important;
        }

        .ant-input {
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 8px;
            background-color: unset;

            border: unset !important;
            border-radius: 15px;
        }

        .ant-input-affix-wrapper > input.ant-input {
            padding-left: 5px;
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;