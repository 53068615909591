@import "../../../less/variables.less";
@import "../../../less/colors";

.no_data_title {
    margin-top: 20px;
    color: @colorText;
}

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 320px;
    background-color: @colorBackground;
    :global(html.theme-dark) & {
        background-color: @colorBackgroundDark;
    }
}

.titleStyle {
    color: @colorTextAccent;
    font-size: 20px;
    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
    }
}

.contentStyle {
    margin-top: 9px;
    margin-bottom: 14px;
    color: @colorText;
    font-size: 20px;
}

.buttonStyle {
    color: @white !important;
    background: #ff9851;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;