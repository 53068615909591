@import "../colors.less";

li {
    list-style: none;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.arvatetextcolor {
    font-size: 12px;
    text-align: left;
}

.slider_fal {
    position: relative !important;
    margin-left: 10px;
}

.client_wrap .client_listul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
}

.client_wrap .client_listul .client_listul_chiled {
    font-size: 18px;
}

.client_wrap .client_listul .client_listul_chiled a {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 14px;
    line-height: 32px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    border-radius: 2px;
}

.client_wrap .client_listul .client_listul_chiled .client_chiled_i {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    // border-radius: 50%;
    padding-top: 3px;
    overflow: hidden;
    // margin: 0 10px 0 0;
    text-align: center;
    vertical-align: top;
}

.client_wrap .client_listul .client_listul_chiled .client_chiled_iAnd {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    // border-radius: 50%;
    padding-top: 3px;
    overflow: hidden;
    text-align: center;
    vertical-align: top;
}

.client_wrap .client_listul .client_listul_chiled a.download .client_chiled_i img {
    position: absolute;
    left: 0;
    width: 20px;
    -webkit-transition: top 0.5s;
    -o-transition: top 0.5s;
    transition: top 0.5s;
}

.client_wrap .client_listul .client_listul_chiled a.download .client_chiled_i img.icon {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.client_wrap .client_listul .client_listul_chiled a.download .client_chiled_i img.download_icon {
    top: 42px;
    left: 2px;
    width: 20px;
}

.client_wrap .client_listul .client_listul_chiled a.download .client_chiled_iAnd img {
    position: absolute;
    left: 0;
    width: 20px;
    -webkit-transition: top 0.5s;
    -o-transition: top 0.5s;
    transition: top 0.5s;
}

.client_wrap .client_listul .client_listul_chiled a.download .client_chiled_iAnd img.icon {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.client_wrap .client_listul .client_listul_chiled a.download .client_chiled_iAnd img.download_icon {
    top: 42px;
    left: 2px;
    width: 26px;
}

// .client_wrap .client_listul .client_listul_chiled a.download:hover .client_chiled_i img.icon {
//   top: -22px
// }

// .client_wrap .client_listul .client_listul_chiled a.download:hover .client_chiled_i img.download_icon {
//   top: 1px
// }

.wrap_buttonhover:hover .client_wrap .client_listul .client_listul_chiled a.download .client_chiled_i img.icon {
    top: -22px;
}

.wrap_buttonhover:hover .client_wrap .client_listul .client_listul_chiled a.download .client_chiled_i img.download_icon {
    top: 5px;
}

.wrap_buttonhover:hover .client_wrap .client_listul .client_listul_chiled a.download .client_chiled_iAnd img.icon {
    top: -22px;
}

.wrap_buttonhover:hover .client_wrap .client_listul .client_listul_chiled a.download .client_chiled_iAnd img.download_icon {
    top: 5px;
}

.leader_banner_list1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    // opacity: 0;
    background-image: url(../../images/compent/leaderboard_banner400.jpg);

    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.leader_banner_list_en {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    // opacity: 0;
    background-image: url(../../images/compent/leaderboard_banner400_en.jpg);

    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.leader_banner_list_tw {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    // opacity: 0;
    background-image: url(../../images/compent/Whitepaper400_tw.jpg);

    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.leader_banner_list1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../images/compent/leaderboard_banner500.jpg);
}

.leader_banner_list2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url(../../images/compent/Whitepaper500_cn.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.leader_banner_list_en {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // opacity: 0;
    background-image: url(../../images/compent/leaderboard_banner500_en.jpg);
}

.leader_banner_list2_tw {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    // opacity: 0;
    background-image: url(../../images/compent/Whitepaper500_tw.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.leader_banner {
    position: relative;
    width: 100%;
    height: 540px;
}

.headercontainer {
    display: flex;
    flex-direction: row;
    height: 45px;
    background-color: @colorBackground;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    // .ant-dropdown-link{
    //   i{
    //     color: #fff;
    //   }
    // }
    // .paibibaheadero{
    //   content:'';
    //   i{
    //     color: #fff !important;
    //   }
    // }

    :global(html.theme-dark) & {
        background-color: @colorBackgroundDark;
    }
}

.propsbackground {
    background: rgba(255, 152, 81, 1) !important;

    .headercontainer {
        color: #ffffff;
        background-color: rgba(255, 152, 81, 1) !important;
        box-shadow: unset !important;

        i {
            color: #ffffff !important;
        }

        .rightBarContainer {
            .pionexSettingColor {
                color: #ffffff !important;
            }
        }

        .rightBarContainer {
            color: #ffffff !important;

            .menuContainer {
                color: #ffffff !important;

                .ant-dropdown-link {
                    i {
                        color: #ffffff !important;
                    }
                }

                .paibibaheadero {
                    content: "";

                    i {
                        color: #ffffff !important;
                    }
                }
            }
        }

        .pionexLogoHed {
            display: block;
        }

        .fundpionexLogo {
            display: block;
            width: 192px;
            height: 45px;
            background: url(../../images/home/logo/logofund.svg);
            background-repeat: space;
            background-position: 20px 8px;
            background-size: auto;
            cursor: pointer;
        }
    }
}

.propsbackground {
    .headercontainer {
        .fundpionexLogo_Box {
            .fundpionexLogo {
                background-color: aquamarine;
            }
        }
    }
}

@media screen and (min-width: 767.98px) {
    .wrap_buttonhover:active .client_wrap .client_listul .client_listul_chiled a.download .client_chiled_i img.icon {
        top: -22px;
    }

    .wrap_buttonhover:active .client_wrap .client_listul .client_listul_chiled a.download .client_chiled_i img.download_icon {
        top: 1px;
    }

    .wrap_buttonhover:active .client_wrap .client_listul .client_listul_chiled a.download .client_chiled_iAnd img.icon {
        top: -22px;
    }

    .wrap_buttonhover:active .client_wrap .client_listul .client_listul_chiled a.download .client_chiled_iAnd img.download_icon {
        top: 1px;
    }
}

@media screen and (max-width: 991.98px) {
    // .propsbackground{
    //   background:rgba(255,152,81,1) !important;
    .headercontainer {
        color: #ffffff;
        background-color: rgba(255, 152, 81, 1) !important;
        box-shadow: unset !important;

        i {
            color: #ffffff !important;
        }

        .rightBarContainer {
            .pionexSettingColor {
                color: #ffffff !important;
            }
        }
    }

    // }
}

@media screen and (max-width: 1560px) {
    .leader_banner {
        height: 400px;
    }

    .productSection {
        height: 400px;
    }

    .leader_banner_list1 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // opacity: 0;
        background-image: url(../../images/compent/leaderboard_banner400.jpg);
        // background-size: auto 135%;
    }

    .leader_banner_list2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // opacity: 0;
        background-image: url(../../images/compent/Whitepaper400_cn.jpg);
        // background-size: auto 135%;
    }

    .leader_banner_list_en {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // opacity: 0;
        background-image: url(../../images/compent/leaderboard_banner400_en.jpg);
    }

    .leader_banner_list2_tw {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // opacity: 0;
        background-image: url(../../images/compent/Whitepaper400_tw.jpg);
    }
}

input::-webkit-credentials-auto-fill-button {
    position: absolute;
    top: 10px !important;
    right: 10px;
    display: block !important;
    padding-top: 20px !important;
    color: red;
    background-color: red;
    // display: none !important;
    visibility: hidden;
    pointer-events: none;
}

// input:focus::-webkit-contacts-auto-fill-button {
//   opacity: 0 !important;
//   display: none !important;
//   visibility: hidden;
//   pointer-events: none;
//   position: absolute;
//   top: 10px !important;
//   right: 10px;

// }

// input[type=password]:focus::-webkit-contacts-auto-fill-button {
//   opacity: 0 !important;
//   display: none !important;
//   visibility: hidden;
//   pointer-events: none;
//   position: absolute;

//   top: 10px !important;
//   right: 10px;
//   padding-top: 20px;

// }

// input[type=password] {
//   -moz-appearance: textfield;
// }

// input[type=password]::-webkit-inner-spin-button,
// input[type=password]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
//   opacity: 0 !important;
//   display: none !important;
//   visibility: hidden;
//   pointer-events: none;
//   position: absolute;
//   top: 10px !important;
//   right: 0;
// }

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none !important;
//   margin: 0;
// }

input[type="number"] {
    -moz-appearance: textfield;
}
