@import "../../../../less/colors.less";

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92px;
    margin-right: 10px;
    background-color: @colorBackground;
    box-shadow: 0 2px 4px rgba(221, 221, 221, 0.5);

    :global(html.theme-dark) & {
        background: #1f232d;
        box-shadow: none;
    }

    .headerText {
        width: 1010px;
        color: @indexTextColor;
        font-weight: 500;
        font-size: 24px;

        :global(html.theme-dark) & {
            color: #ffffff;
        }
    }
}

.listView {
    display: flex;
    flex: 1;

    width: 1010px;
    height: 100%;
    margin: 0 auto;
    overflow: auto;

    & > div {
        width: 100%;
    }

    :global {
        .ant-list {
            .ant-list-pagination {
                padding: 10px 0;
                background-color: @colorBackground;

                :global(html.theme-dark) & {
                    background-color: @colorBackgroundDark;
                }

                .ant-pagination {
                    .ant-pagination-disabled {
                        display: none;
                    }

                    .ant-pagination-item {
                        background-color: @colorBackground2;

                        :global(html.theme-dark) & {
                            background-color: @colorBackground2Dark;
                        }

                        a {
                            color: @colorText;
                        }
                    }

                    .ant-pagination-next {
                        padding: 0 10px;
                        background-color: @colorBackground2;
                        border-color: @colorBackground2;

                        :global(html.theme-dark) & {
                            background-color: @colorBackground2Dark;
                            border-color: @colorBackground2Dark;
                        }

                        a {
                            color: @colorText !important;
                        }
                    }

                    .ant-pagination-prev {
                        padding: 0 10px;
                        background-color: @colorBackground2;
                        border-color: @colorBackground2;

                        :global(html.theme-dark) & {
                            background-color: @colorBackground2Dark;
                            border-color: @colorBackground2Dark;
                        }

                        a {
                            color: @colorText !important;
                        }
                    }

                    .ant-pagination-item-active {
                        background-color: @colorBackground2;
                        border-color: @colorAccent;

                        :global(html.theme-dark) & {
                            background-color: @colorBackground2Dark;
                        }
                    }

                    .ant-pagination-item-container {
                        .ant-pagination-item-ellipsis {
                            color: @colorText;
                        }
                    }
                }
            }
        }
    }
}

.pageHeader {
    display: flex;
    width: 1010px;
    height: 80px;

    margin: 20px auto 0 auto;
    padding: 0 20px;
    background-color: @colorBackground;

    :global(html.theme-dark) & {
        background-color: @colorBackgroundDark;
    }
}

.pageHeaderInner {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom-color: @colorText5;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    :global(html.theme-dark) & {
        border-bottom-color: @colorText5Dark;
    }
}

.listHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 1010px;
    height: 56px;
    margin: 0 auto;
    color: @indexCardTextColor;
    background-color: @colorBackground;

    :global(html.theme-dark) & {
        color: @indexCardTextColorDark;
        background-color: @colorBackgroundDark;
    }
}

.listHeaderItem {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    font-size: 13px;
    background-color: @colorBackground;

    &:last-child {
        flex: 1.5;
    }

    :global(html.theme-dark) & {
        background-color: @colorBackgroundDark;
    }
}

.itemContainerColor2 {
    background-color: #fafbfc;

    :global(html.theme-dark) & {
        background-color: #1a1e24;
    }
}

.itemContainerColor1 {
    background-color: @colorBackground;

    :global(html.theme-dark) & {
        background-color: @colorBackgroundDark;
    }
}

.itemCheckBox {
    padding: 0 20px;
}

.itemContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 56px;
    font-size: 14px;
}

.subItem {
    display: flex;
    flex: 1;
    align-items: center;

    &:last-child {
        flex: 1.5;
    }
}

.checkboxGroup {
    flex-direction: column;

    :global {
        .ant-checkbox-group-item {
            display: flex;
            flex: 1;
            align-items: center;

            &:last-child {
                flex: 1;
            }
        }
    }
}

.modalItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalContainer {
    :global {
        .ant-modal-content {
            .ant-modal-header {
                border-bottom: none;
            }

            .ant-modal-body {
                padding: 0 24px 24px 24px;
            }
        }
    }
}

.transferButton {
    width: 88px;
    height: 36px;
}

.checkboxAll {
    display: flex;

    :global {
        .ant-checkbox {
            .ant-checkbox-inner {
                border: 1px solid #999999;
            }
        }

        :global(html.theme-dark) & {
            .ant-checkbox {
                .ant-checkbox-inner {
                    background-color: #1f232d;
                    border: 1px solid #858ca3;
                }
            }

            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: #1890ff;
                    border: 0 solid #858ca3;
                }
            }
        }
    }
}

.checkboxItem {
    display: flex;

    :global {
        .ant-checkbox {
            .ant-checkbox-inner {
                border: 1px solid #999999;
            }
        }

        :global(html.theme-dark) & {
            .ant-checkbox {
                .ant-checkbox-inner {
                    background-color: #1f232d;
                    border: 1px solid #858ca3;
                }
            }

            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: #1890ff;
                    border: 0 solid #858ca3;
                }
            }
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;