@import "../../../../less/colors";
.btn {
    display: flex;
    margin-top: 20px;
}

.register {
    width: 72px;
    height: 28px;
    font-size: 12px;
    border-radius: 6px;
}

.sign {
    width: 72px;
    height: 28px;

    margin-right: 10px;
    font-size: 12px;
    border-radius: 6px;
}

.title {
    margin-bottom: 20px;
    color: @colorText;
    font-size: 14px;

    :global(html.theme-dark) & {
        color: @colorText2Dark;
    }
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;