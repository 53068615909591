@import "~less/v2/vars.less";

.container {
    max-width: 680px;
    min-height: 700px;
    margin: auto;
    padding: 20px 10px 50px 10px;

    .title {
        margin: 0 auto 40px auto;
        font-weight: 600;
        font-size: @v2-h1-font-size;
        text-align: center;
        .v2Theme-color(@v2-grey-16,white);
    }

    .steps {
        width: 120px;
        margin: auto auto 20px;
    }
}

.hint {
    margin-top: 12px;
    padding: 20px;

    font-size: @v2-md-font-size;
    border-radius: 12px;
    .v2Theme-color(#e0a100,@v2-warn-color-dark);
    .v2Theme-background-color(@v2-warn-bg,@v2-dark-19);

    .hintContent {
        white-space: break-spaces;

        :global(.iconfont) {
            margin-right: 4px;
        }
    }

    .toHint {
        margin-top: 8px;
        color: @v2-primary-color;
        cursor: pointer;
    }
}

.subTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    font-weight: 500;
    font-size: @v2-md-font-size;

    .v2Theme-color(@v2-grey-16,@v2-dark-10);

    a {
        display: flex;
        align-items: center;
        line-height: 20px;
        .v2Theme-color(@v2-grey-16,@v2-dark-10);
    }
    :global {
        .iconfont {
            margin-right: 4px;
            font-size: 16px;
        }
    }
}

.itemsContainer {
    margin-top: 12px;
    border-radius: 12px;
    .v2Theme-background-color(@v2-table-odd-bg-color,@v2-dark-14);
}
.infoItems {
    .itemsContainer();
    padding-bottom: 20px;
}

.uniHint {
    display: flex;
    flex-direction: row;
    padding: 6px 40px 0 40px;
    color: @v2-primary-color;
    font-size: 12px;
    .v2Theme-color(#DB8B00,@v2-warn-color-dark);
}
.step {
    //bg-primary w-24px h-24px rounded-20px text-white
    width: 24px;
    height: 24px;
    border-style: none;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    .v2Theme-background-color(@v2-primary-color);
}

.divider {
    height: 1px;
    margin: 0 20px 0 20px;
    .v2Theme-background-color(@v2-grey-18,@v2-dark-13);
}

.tutorial {
    margin-top: 8px;
    color: @v2-grey-20;
    font-size: @v2-sm-font-size;
    text-align: center;

    strong {
        color: @v2-primary-color;
        font-size: @v2-sm-font-size;
    }
}

.btns {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .back {
        width: 160px;
        margin-right: 22px;
    }
}

.result {
    margin: 80px auto auto;
}

.again {
    display: block;
    min-width: 120px;
    margin: 50px auto auto;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;