@import "~less/v2/vars.less";
.formStyle {
    :global {
        .ant-picker-range {
            .ant-picker-clear {
                right: 30px !important;
            }
        }
        .ant-input {
            border-color: @v2-grey-18;
            .v2DarkTheme({
                border-color: @v2-dark-13;
            });
        }
        .t-input-wrapper-has-error {
            .ant-input {
                border-color: @v2-danger-color;
                .v2DarkTheme({
                    border-color: @v2-danger-color;
                });
            }
        }
        .ant-form-item-has-error {
            .ant-select-selection-placeholder {
                color: @v2-danger-color;
                opacity: 1;
            }
        }
        .ant-form-item-with-help {
            margin-bottom: 24px;
        }
        .t-select.size-large .ant-select-selector {
            font-size: 14px !important;
        }
        .ant-picker-range {
            height: 39px;
            border-radius: 8px;
        }
        .ant-picker-input {
            > input::placeholder {
                .v2DarkTheme({
                    color: #626773 !important;
                });
                .v2NormalTheme({
                   color:#9ea6b8 !important;
                });
            }
        }
    }
    .picker {
        width: 100%;
    }
    .pickerMrb {
        margin-bottom: 8px;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;