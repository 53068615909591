@import "../colors";

html.theme-dark {
    .usericont {
        i {
            margin-right: 10px;
        }

        .ant-select-dropdown-menu-item-active {
            background-color: yellow;
        }
    }

    ::-webkit-scrollbar-thumb {
        background: #383f6657;
        border-radius: 0;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #4b507057;
    }

    .singborder {
        .ant-input-password-icon {
            color: @colorText !important;
        }
    }

    .container {
        background-color: @colorBackgroundDark;
    }

    .index-jdrp-menu {
        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:hover {
            background-color: @colorText;
        }

        .ant-dropdown-menu-item {
            color: @colorTextAccentDark;
        }
    }

    .arvanumbercolor {
        font-size: 12px;
        text-align: right;
    }

    .listUno {
        font-weight: 600;
        // color: #27a59a;
        font-size: 22px;
        text-align: left;
    }

    .listDos {
        padding-left: 10px;
        font-weight: 600;
        // color: #27a59a;
        font-size: 12px;
        line-height: 30px;
        text-align: left;
    }

    .listTres_text1 {
        color: @colorText;
        font-weight: 400;
        font-size: 12px !important;
        text-align: left;
    }

    .listTres_text2 {
        color: @colorTextAccentDark;
        font-size: 12px !important;
        text-align: right;
    }

    .listCuarro_text1 {
        color: #858ca3;
        font-weight: 400;
        font-size: 12px !important;
        text-align: left;
    }

    .listCuarro_text2 {
        color: @colorTextAccentDark;
        font-size: 12px;
        text-align: left;
    }

    .listCuarro_text3 {
        color: @colorAccent;
        font-weight: 500;
        font-size: 10px;
        text-align: right;
    }

    .listUno,
    .listDos,
    .listTres {
        margin-bottom: 10px;
    }

    .setmysele_title {
        height: 36px;
        color: @colorText;
        font-size: 12px;
        text-align: left;
    }

    .setmysele_number {
        height: 36px;
        color: @colorTextAccentDark;
        font-weight: 500;
        font-size: 12px;
        text-align: right;
    }

    .setmysele_title {
        color: @colorText;
    }

    .setmysele_number {
        color: #ffffff;
        font-weight: 500;
        font-size: 12px;
    }

    .number_position {
        position: relative;
    }

    .number_Baifenbi {
        position: absolute;
        top: 10px;
        right: 36px;
        z-index: 3;
    }

    .number_Baifenbi label {
        display: inline-block;
        height: 16px;
        margin-right: 4px;
        padding: 0 6px 0 6px;
        color: white;
        font-size: 12px;
        line-height: 16px;
        background-color: @colorAccent;
        border-radius: 1000px;
    }

    // video
    .rh5v-DefaultPlayer_component {
        height: 100%;
    }

    .rh5v-Overlay_inner {
        border-radius: 50%;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
    }

    .swiper-slide {
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        font-size: 18px;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }

    .swiper-pagination-bullet-active {
        background-color: #387dea !important;
    }

    .swiper-pagination-bullet {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: rgba(255, 255, 255, 0.651);
        border-radius: 100%;
        opacity: unset;
    }

    // input默认样式
    .formItem {
        background-color: #191b26;
    }

    input:-webkit-autofill:focus {
        background-color: @windowBackgroundDark !important;
        background-image: none;

        // -webkit-box-shadow: 0 0 0 60px @windowBackgroundDark inset !important;

        border-color: @windowBackgroundDark !important;
    }

    input:-webkit-autofill:hover {
        // background-color: @windowBackgroundDark !important;
        background-image: none;
        // -webkit-box-shadow: 0 0 0 60px @windowBackgroundDark inset;

        border-color: @windowBackgroundDark !important;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    textarea {
        color: @singTextBackground !important;
        caret-color: @singTextBackground;
    }

    input::-webkit-input-placeholder {
        color: @colorText !important;
    }

    input:-moz-placeholder {
        color: @colorText !important;
    }

    input::-moz-placeholder {
        color: @colorText !important;
    }

    input:-ms-input-placeholder {
        color: @colorText !important;
    }

    //.ant-input {
    //    -webkit-box-shadow: 0 0 0 60px @colorBackground2Dark inset !important;
    //    box-shadow: 0 0 0 60px @colorBackground2Dark inset !important;
    //    // -webkit-text-fill-color: @colorBackground;
    //
    //}
    //
    //.ant-input:focus {
    //    -webkit-box-shadow: 0 0 0 60px @colorBackground2Dark inset !important;
    //    box-shadow: 0 0 0 60px @colorBackground2Dark inset !important;
    //
    //}

    input:focus {
        color: @colorBackground !important;
    }

    .main .Resizer.horizontal {
        height: 3px;
        background-color: @horizontalDark;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: unset;
    }
}

.isBuButtonBag {
    color: @colorBackground !important;
    background: linear-gradient(90deg, #5793f1 0%, #2d71da 100%) !important;
    border: 1px solid transparent !important;
    border-color: #5793f1 !important;
}

.selected {
    border-left: 3px solid #2d71da !important;
}
