@import "../colors";

html.theme-light {
    .ant-modal {
        color: @colorTextAccent;

        &-content,
        &-header {
            background-color: @colorBackground;
        }
    }

    .ant-btn-background-ghost {
        background-color: @leaderboardTabletitlecolor;
    }

    .ant-btn-background-ghost {
        color: @colorTextAccent;
        border-color: @colorTextAccent;
    }

    .ant-message {
        z-index: 1102;
        color: @colorTextAccent;

        .ant-message-notice {
            .ant-message-notice-content {
                padding: 12px 32px;
                color: white;
                font-size: 16px;
                background: rgba(0, 0, 0, 0.8);
                border-radius: 4px;
            }
            .ant-message-notice-content .ant-message-error {
                color: @colorError;
            }

            .ant-message-notice-content .ant-message-warning {
                color: @colorError;
            }
        }
    }

    .ant-btn.disabled,
    .ant-btn:disabled {
        color: #cccccc;
        background: #9a9a9a;
        border: 1px solid #9a9a9a;
    }

    .ant-table {
        color: @colorTextAccent;
    }

    .ant-table-thead > tr,
    .ant-table-thead > tr > th {
        border-bottom-color: @colorDivider2;
    }

    .ant-table-placeholder {
        background-color: @colorBackground;
        border: none;
    }

    .ant-table-thead > tr > th {
        color: @colorTextAccent;
        background-color: unset;
    }

    .ant-table-thead > tr > th.ant-table-column-has-actions {
        // background: palegoldenrod !important;
        background-clip: unset !important;
    }

    .ant-tabs {
        color: @colorTextAccent;
    }

    .ant-tabs-nav {
        color: @publicTextColor !important;
    }

    .ant-progress-inner {
        background-color: @windowBackground;
    }

    input::-webkit-input-placeholder {
        color: @colorText !important;
    }

    input:-moz-placeholder {
        color: @colorText !important;
    }

    input::-moz-placeholder {
        color: @colorText !important;
    }

    input:-ms-input-placeholder {
        color: @colorText !important;
    }

    .ant-modal-confirm-title {
        margin-left: 15px;
        color: @colorTextAccent;
        font-weight: 600 !important;
    }

    .ant-modal-confirm-body .ant-modal-confirm-content {
        color: @colorTextAccent;
    }

    .ant-empty-description {
        color: @colorTextAccent;
    }

    .ant-dropdown {
        color: @colorTextAccent;
    }

    .ant-dropdown-menu {
        padding: 0;
    }

    .ant-dropdown-menu-item-divider {
        margin: 0;
    }

    .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover {
        background-color: #858ca3;
    }

    .ant-dropdown-menu-item:hover {
        background-color: #e8e9ea;
    }

    .ant-drawer-bottom .ant-drawer-content-wrapper {
        right: 0;
        width: 350px;
    }

    @media screen and (max-width: 600px) {
        .ant-drawer-bottom .ant-drawer-content-wrapper {
            right: 0;
            width: 100%;
            // height: 546px !important;
        }
    }

    // .ant-drawer-right .ant-drawer-content-wrapper {
    //   height: 62%;
    // }

    .ant-drawer-right .ant-drawer-content-wrapper {
        right: 0;
        bottom: 0;
    }

    .ant-drawer-header {
        position: relative;
        padding: 8px;
        color: @colorTextAccent;
        background-color: @horizontal;
        border-bottom: 1px solid @cardHeaderBg;
        border-radius: 4px 4px 0 0;
    }

    .ant-drawer-close {
        position: absolute;
        top: 12px;
        right: 14px;
        z-index: 10;
        display: block;
        width: unset;
        height: unset;
        padding: 0;
        color: @colorTextAccent;
        font-weight: 700;
        font-size: 12px;
        font-style: normal;
        line-height: unset;
        text-align: center;
        text-transform: none;
        text-decoration: none;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        transition: color 0.3s;
        text-rendering: auto;

        :global {
            i {
                -webkit-transition: all 0.6s ease-in-out;
                -moz-transition: all 0.6s ease-in-out;
                -o-transition: all 0.6s ease-in-out;
                transition: all 0.6s ease-in-out;
            }
        }
    }

    .ant-drawer-close:hover i {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    .ant-drawer-content {
        height: 100%;
    }

    .ant-drawer-wrapper-body {
        // background-color: #232732;
        display: flex;
        flex-direction: column;
        height: 100% !important;
        overflow: auto !important;
    }

    .ant-drawer-body {
        flex: 1;
        padding: 15px 10px 0 10px;
    }

    .swiper-container {
        overflow: inherit;
    }

    // 各种tab的 transform 卡帧问题
    .ant-tabs-top .ant-tabs-ink-bar-animated,
    .ant-tabs-bottom .ant-tabs-ink-bar-animated {
        -webkit-transform: translate(0);
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000px;
        -webkit-transition: -webkit-transform;
    }

    .gutter-example .ant-row > div {
        background: transparent;
        border: 0;
    }

    .ant-btn {
        color: #999999;
        border-color: #666666;
        box-shadow: unset;
    }

    .ant-btn:hover,
    .ant-btn:focus {
        color: @colorAccent;
        border-color: @colorAccent;
        box-shadow: unset;
    }

    // .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus {
    //   color: white;
    // }

    .ant-btn-primary {
        color: white;
        // background-color: @colorAccent !important;
        background: @isbtnPrimary;
        border-color: transparent;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        color: white;
        background-color: ~`colorPalette("@{isbtnPrimary}", 5) `;
        border-color: transparent;
    }

    .ant-btn-danger {
        color: white;
        background-color: @colorDanger;
        border-color: transparent;
    }

    .ant-btn-danger:hover,
    .ant-btn-danger:focus {
        color: white;
        background-color: ~`colorPalette("@{colorDanger}", 5) `;
        border-color: transparent;
    }

    .ant-select-selection {
        color: @singTextBackground;
        background-color: @singInputBackgroundDark;
        border-color: @singInputBackgroundDark;
    }

    .ant-select-arrow .ant-select-arrow-icon {
        color: @invitationCodeBg !important;
    }

    .ant-dropdown-menu {
        background-color: @leaderindexblacktext;
        // color: @colorBackgroundDark;
    }

    .ant-list {
        color: @colorTextAccent;
    }

    // .ant-input-group .ant-input {
    //     height: 40px;
    //     color: #858ca3;
    //     font-size: 12px;
    //     background-color: @windowBackground;
    //     border: none !important;
    // }

    // .ant-input-group-addon {
    //     position: relative;
    //     color: @colorText !important;
    //     background-color: @windowBackground !important;
    //     border: 1px solid @colorDivider2;
    // }

    // .ant-input-group-addon::before {
    //     position: absolute;
    //     top: 14px;
    //     left: 0;
    //     display: inline-block;
    //     width: 1px;
    //     height: 10px;
    //     background-color: #a9aebe;
    //     content: "";
    // }

    .ant-slider-step {
        height: 6px !important;
        background: #e3e2ef24;
    }

    .ant-checkbox-wrapper {
        color: @singTextBackgroundDark;
    }

    .ant-checkbox-wrapper:hover {
        color: @v2-primary-color;
    }

    #currencyPair {
        .ant-menu-item {
            background-color: @aPairBg;
        }

        .ant-menu-horizontal {
            background-color: @aPairBg;
        }
    }

    .index-jdrp-menu {
        color: @colorTextAccent;
        background-color: @leaderindexblacktext;

        .deop_menu_divider {
            background-color: @colorDivider;
        }

        span {
            color: @singTextBackgroundDark;

            .ant-dropdown-menu-submenu-arrow-icon {
                color: @colorTextAccent;
            }
        }
    }

    // .ant-input {
    //     color: @colorTextAccent;
    //     background-color: transparent;
    //     border: 1px solid @horizontal;
    // }

    // .ant-input-search-icon {
    //     color: @colorText;
    // }

    // .ant-input[disabled] {
    //     background-color: @windowBackground;
    //     border: none;
    // }

    // .ant-select-lg .ant-select-selection__rendered,
    // .ant-input-affix-wrapper .ant-input-suffix {
    //     color: @singTextBackgroundDark;
    // }

    // .ant-input-affix-wrapper .ant-input-suffix .ant-input-password-icon {
    //     color: @inputIconBg;
    // }

    .ant-table-tbody > tr > td {
        border-bottom-color: #ececec;
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
        background: @hoverTablesHoverBg;
    }

    .ant-select-selection__rendered {
        color: @invitationCodeBg;
    }

    .ant-slider-rail {
        background-color: @windowBackground;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: transparent;
    }

    .ant-tabs-nav .ant-tabs-tab-active {
        h4 {
            color: @leaderboardTabletitlecolor !important;
        }
    }

    .ant-pagination-item {
        background-color: transparent;
        border-color: transparent;

        a {
            color: @colorText;
        }
    }

    .ant-pagination-item-active {
        background-color: transparent;
        border-color: @colorAccent;

        a {
            color: @colorTextAccent;
        }
    }

    .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
            color: @colorText;
        }
    }

    .ant-rate {
        color: #ffab50;
    }

    .ant-select-dropdown {
        background-color: @colorBackground;
    }

    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
        color: @colorText;
    }

    .ant-menu-horizontal > .ant-menu-item-selected {
        // font-weight: bold;
        color: @leaderboardTabletitlecolor;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: @colorTextAccent;
    }

    .ant-tree li .ant-tree-node-content-wrapper {
        color: @colorTextAccent;

        i {
            color: @colorTextAccent;
        }
    }

    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: #858ca3;
    }

    .ant-select-dropdown-menu-item-active {
        background-color: unset;
    }

    .ant-select-dropdown-menu-item-selected {
        background-color: unset;
    }

    .ant-progress-status-success .ant-progress-bg {
        background-color: @textRaiseColor;
    }

    // 中签+——+——+——+——+——++————++——————+
    .ant-modal-title {
        color: @singTextBackgroundDark;
    }

    .ant-modal-close {
        color: @singTextBackgroundDark;
    }

    .ant-modal-footer {
        border-top-color: transparent;
    }

    .ant-drawer-content {
        background-color: @colorBackground;
    }

    .ant-tabs-ink-bar {
        background-color: @leaderboardTabletitlecolor;
    }

    .ant-tabs-tab-active {
        color: @leaderboardTabletitlecolor;
    }

    .ant-tabs-tab-active h4 {
        color: @leaderboardTabletitlecolor;
    }

    ::selection {
        color: @colorBackground;
        background: @leaderboardTabletitlecolor;
    }

    .ant-radio-button-wrapper-checked {
        box-shadow: -1px 0 0 0 transparent;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
        color: @leaderboardTabletitlecolor;
    }

    .ant-divider {
        color: @colorTextAccent;
        background-color: @colorDivider2;
        border-top: 1px solid @colorDivider2;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        box-shadow: -1px 0 0 0 transparent;
    }

    .ant-notification-bottomLeft {
        bottom: 0 !important;
        margin-left: 0;
    }

    .ant-notification-notice {
        margin-bottom: 0;
    }

    .ant-notification-notice-message {
        margin-left: 0;
    }

    .ant-notification-notice-close {
        top: 30px;
        color: @colorTextAccent;
    }

    .ant-tooltip-arrow::before {
        // background-color: rgb(87, 147, 241);
        background-color: @colorAccent2;
    }

    // .ant-radio-button-wrapper:hover{
    //   color: unset !important;
    // }

    .ant-tabs-dropdown-menu {
        padding: 0;
        background-color: @colorBackground;
    }

    .ant-select {
        color: @colorTextAccent;
    }

    .ant-select-item {
        color: @colorTextAccent;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: unset;
    }
}
