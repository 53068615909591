.checkoutContainer {
    :global {
        .frame--activated {
            @apply mt-16px border-solid rounded-10px border-divider border;
            height: 48px;
            padding: 10px;

            &.frame--focus {
                @apply border-secondary;
            }
        }
    }

    .cvvContainer {
        display: flex;
        .formItem {
            &:first-child {
                margin-right: 16px;
            }
        }
    }

    .cardSample {
        margin: 32px 0 48px;
    }

    .formItem {
        .errorLabel {
            @apply hidden;
        }
    }
    .formItemError {
        :global {
            .frame--activated {
                @apply !border-red;
            }

            .pi-input {
                @apply !text-red;
            }
        }

        .errorLabel {
            @apply block text-red mt-4px;
        }
    }
}

.modal {
    :global {
        .pi-modal-body {
            padding-right: 80px;
            padding-left: 80px;
        }
    }
}

.title {
    @apply text-accent mt-12px;
    margin-bottom: 42px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;