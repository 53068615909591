@import "~less/v2/vars.less";

.container {
    padding: 20px;
    border-radius: 16px;
    .v2Theme-background-color(@v2-table-odd-bg-color,@v2-dark-14);

    .title {
        .v2Theme-color(@v2-grey-16,@v2-dark-10);
        font-weight: 500;
        font-size: @v2-md-font-size;
    }
}

.verifyItem {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .verifyText {
        flex: 1;

        .verifyTitle {
            .v2Theme-color(@v2-grey-16,@v2-dark-10);
            font-size: @v2-md-font-size;
        }

        .verifyDes {
            color: @v2-grey-14;
            font-size: @v2-sm-font-size;
        }
    }

    .verifyBtn {
        &:disabled {
            .v2ConstantTheme({
                background-color: @v2-grey-14 !important;
                color: white !important;
            });
        }
    }
}

.verifySuccess {
    display: flex;
    align-items: center;
    font-size: @v2-md-font-size;

    :global(.iconfont) {
        margin-right: 4px;
        color: @v2-success-color;
    }
}

.verifyFailed {
    .v2Theme-color(@v2-danger-color,@v2-danger-color);
    font-size: @v2-sm-font-size;
    cursor: pointer;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;