@import "~less/v2/vars.less";
@import "~less/colors.less";

.feeCard {
    overflow: hidden;
    border-radius: 16px;
    .v2Theme-background-color(#ffffff,#1e2229);

    :global {
        .ant-card-head {
            border-bottom: none;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        .ant-card-body {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;