@import "../../less/colors.less";
@import "../../less/v2/vars.less";

.withdrawWrapper {
    flex: 1;
    width: 100%;
    height: 100px;
    background-color: @colorBackground;

    :global(.ant-btn-primary) {
        background: @colorAccent !important;
    }

    :global(html.theme-dark) & {
        background-color: @colorBackgroundDark;
    }
    :global {
        .center-container {
            display: flex;
            flex-direction: column;
        }
    }
}

.contentWrapper {
    margin-top: 12px;
}

.content_top {
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
}

.coinInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .v2MediaTablet({
        display: block
    });
    .coinInfoItem {
        flex: 1;
        background: rgba(255, 255, 255, 1);
        border-radius: 6px;

        :global(html.theme-dark) & {
            background: @v2-grey-15;
            box-shadow: none;
        }

        :global {
            .ant-select {
                font-weight: bold;
            }
        }
    }

    .coinInfoItemMiddle {
        position: relative;
        flex: 1.8;
        margin-left: 16px;
        background: rgba(255, 255, 255, 1);
        border-radius: 6px;

        :global(html.theme-dark) & {
            background: rgba(31, 35, 45, 1);
            box-shadow: none;
        }

        .AddressViewBox {
            width: 100%;
            height: 100%;
        }
        .v2MediaTablet({
            margin-top: 16px;
            margin-left: 0;
            min-height: 200px;
        });
    }
}

.exclamationIcon {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 6px;
    color: #858ca3;
    cursor: pointer;
}

.inputAllCount {
    display: inline-block;
    padding-right: 10px;
    color: @colorAccent2;
    font-weight: normal;
    cursor: pointer;
}

.inputAllLine {
    display: inline-block;
    margin-right: 8px;
    color: #999999;

    :global(html.theme-dark) & {
        color: #343640;
    }
}

.coinName {
    color: #666666;
    font-weight: normal;

    :global(html.theme-dark) & {
        color: #858ca3;
    }
}

.canResendPrimary {
    color: #ff874f !important;
    font-weight: 400;
    font-size: 12px;
    background: none;
    border: none;

    &:hover {
        background: none;
    }
}

:global {
    .ant-input-group-addon {
        border: none !important;

        &::before {
            color: #999999;
        }
    }

    html.theme-dark {
        .ant-input-group-addon {
            border: none !important;

            &::before {
                color: #343640;
            }
        }
    }
}

:global(html.theme-dark) {
    background-color: @windowBackgroundDark !important;
}

.unableResendPrimary {
    color: #a1a1a1;
    font-weight: 400;
    font-size: 12px;
    background: none;
    border: none;

    &:hover {
        background: none;
    }
}

.coinInfo {
    .attention {
        padding: 24px 10px 10px;
        background-color: @colorBackground3;
        border-color: @colorAccent2;
        border-width: 1px;

        :global(html.theme-dark) & {
            background-color: @colorBackground3Dark;
        }

        :global(.ant-card-head-title) {
            color: #ffffff;
        }
    }
}

.historyTable {
    margin-top: 16px;
    padding-bottom: 20px;
    background-color: @colorBackground3;
    border-radius: 6px;

    .historyHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        h3 {
            font-weight: bold;
            font-size: 24px;
            .v2MediaTablet({
                font-size: 18px;
            });
        }
        .v2MediaTablet({
            display: block;
        });
    }

    :global {
        .ant-checkbox-inner {
            background-color: transparent;
            border: 1px solid @colorTextAccent;

            :global(html.theme-dark) & {
                border: 1px solid @colorTextAccentDark;
            }
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #1890ff !important;
            border: 1px solid #2c75ff !important;
        }

        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border: 1px solid @colorTextAccent;

            :global(html.theme-dark) & {
                border: 1px solid @colorTextAccentDark;
            }
        }
    }

    :global(html.theme-dark) & {
        background-color: @v2-grey-15;
        box-shadow: none;
    }
}

.QRWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px;
    background: rgba(255, 255, 255, 1);

    :global(html.theme-dark) & {
        background: rgba(57, 64, 84, 1);
    }
}

.popover {
    :global {
        .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
            border-top-color: @windowBackground;
            border-right-color: @windowBackground;
            border-bottom-color: @windowBackground;
            border-left-color: @windowBackground;

            :global(html.theme-dark) & {
                border-top-color: @windowBackgroundDark;
                border-right-color: @windowBackgroundDark;
                border-bottom-color: @windowBackgroundDark;
                border-left-color: @windowBackgroundDark;
            }
        }
    }
}

.hideQRWrapper {
    opacity: 0;
}

.tabPane {
    :global {
        .ant-tabs-tab {
            padding: 12px 0;
            font-size: 16px;

            h4 {
                color: @transfomModetext;
                font-weight: normal;

                :global(html.theme-dark) & {
                    color: @colorText;
                }
            }
        }

        .ant-tabs-nav .ant-tabs-tab-active h4 {
            font-weight: bold !important;
        }
    }
}

.cardContent {
    height: 100%;
    padding: 24px 10px 10px;
    text-align: left;

    :global {
        .ant-form {
            .ant-row {
                margin-bottom: 10px !important;
            }
        }
    }
}

.selectDropdown {
    overflow: auto;
}

@media screen and (max-width: 991.98px) {
    .selectDropdown {
        max-height: 140px;
        overflow: auto;
    }
}

.selectIcon {
    height: 23px;
    margin-top: -5px;
    vertical-align: middle;
}

.copyBtn {
    position: relative;
    top: -2px;
    width: 90px;
    height: 25px;
    color: @trade_actionbtn_bg_light !important;
    font-weight: @fontNormalBold;
    font-size: 12px;
    background: @helper_alert_hover_tab_light !important;
    border: none;
    border-radius: 14px;

    :global(html.theme-dark) & {
        color: @trade_actionbtn_bg_dark !important;
        background: @helper_alert_hover_tab_dark !important;
    }
}

.attentionButton {
    .button {
        width: 90px;
        height: 25px;
        color: white !important;
        font-weight: @fontNormalBold;
        font-size: 12px;
        background: rgba(255, 112, 44, 1);
        border: none;
        border-radius: 2px;

        :global(html.theme-dark) & {
            color: white !important;
        }
    }

    :global {
        .ant-btn:hover,
        .ant-btn:focus {
            color: inherit;
        }

        .ant-btn:disabled {
            color: #cfcfcf !important;
            background: rgba(226, 227, 230, 1);
            border: none rgba(226, 227, 230, 1) !important;
        }
    }
}

.popoverStyle {
    :global {
        .ant-popover-arrow {
            display: none;
        }
    }
}

.customAddress {
    .tipFont {
        color: @singTextBackgroundDark;
        font-weight: bold;
        font-size: 18px;
        word-break: break-word;

        :global(html.theme-dark) & {
            color: @singTextBackground;
        }
    }

    :global {
        .ant-popover-arrow {
            display: none;
        }
    }
}

.cardContent {
    .addressPhone {
        display: none;
    }

    .balanceTipWrapper {
        .tipFont {
            color: @singTextBackgroundDark;
            font-size: 16px;
            word-break: break-word;

            :global(html.theme-dark) & {
                color: @singTextBackground;
            }
        }
    }

    .depositcard_left_tioop {
        h3 {
            color: @singTextBackgroundDark;

            :global(html.theme-dark) & {
                color: @singTextBackground;
            }
        }

        .isdeposit_text {
            color: @singTextBackgroundDark;

            :global(html.theme-dark) & {
                color: @singTextBackground;
            }
        }
    }
}

.depositcard_left_tioop {
    padding-left: 12px;
}

.addrBtnGroup {
    float: right;

    :global {
        .ant-btn {
            margin-left: 10px;
            line-height: 24px;
        }
    }
}

.coinTitleIcon {
    &:extend(.selectIcon);
}

.tradingEntries {
    margin: 0 -5px;

    :global {
        .ant-btn {
            margin: 0 5px 10px;
            color: @colorText !important;
            border-color: @colorText !important;

            // &:hover {
            //   border-color: @white  !important;
            //   color: @white  !important;
            // }
        }
    }
}

.feeNotice {
    margin-top: 10px;
    margin-bottom: 20px;
    color: @singTextBackgroundDark;

    :global(html.theme-dark) & {
        color: @singTextBackground;
    }
}

.input {
    height: 40px;
    font-size: 12px;
    border: none !important;

    :global {
        .ant-input-group .ant-input {
            font-size: 16px;
        }
    }
}

.inputlabel {
    color: @colorText4;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    :global & {
        color: @colorTextDark4;
    }
}

.isdeposit_text {
    margin-bottom: 0;
    padding-left: 28px;
    font-size: 13px;
    line-height: 28px;

    p:nth-of-type(1) {
        margin-bottom: 4px;
    }
}

.bulb_style {
    margin-right: 8px;
}

.cardContent {
    .rightcard_h2 {
        color: @singTextBackgroundDark;
        font-weight: 600;
        font-size: 20px;

        :global(html.theme-dark) & {
            color: @singTextBackground;
        }

        img {
            margin-right: 10px;
        }
    }

    .price_holder_h {
        color: @singTextBackgroundDark;

        :global(html.theme-dark) & {
            color: @singTextBackground;
        }
    }

    .rightcard_bottom_tradingstyle {
        color: @singTextBackgroundDark;

        :global(html.theme-dark) & {
            color: @singTextBackground;
        }
    }
}

.rightcard_title_bottom_content {
    padding-left: 48px;
}

.price_holder_h {
    font-size: 16px;
}

.rightcard_bottom_tradingstyle {
    margin-bottom: 20px;
    font-size: 16px;
}

.balanceItemText {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titleText {
        display: flex;
        align-items: center;
        color: @singTextBackgroundDark;
        font-size: 14px;

        :global(html.theme-dark) & {
            color: @singTextBackground;
        }

        :global(.iconfont) {
            margin-left: 2px;
        }
    }

    .titleBold {
        color: @singTextBackgroundDark;
        font-weight: bold;
        font-size: 14px;

        :global(html.theme-dark) & {
            color: @singTextBackground;
        }
    }
}
.balanceWarn {
    color: @v2-grey-5;
    font-size: 14px;
}

.baseSwitchBtn {
    height: auto;
    padding: 3px 5px;
    line-height: normal;

    & + .baseSwitchBtn {
        margin-left: 8px;
    }
}

.coindetaill_button {
    width: 100px;
    color: @colorText;
    border-color: @colorText;
}

.tipscontent_dos {
    color: @colorWarning !important;
}

.withdrawContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    h3 {
        color: @singTextBackgroundDark;
        font-size: 14px;

        :global(html.theme-dark) & {
            color: @singTextBackground;
        }
    }
}

.tabPane {
    :global {
        .ant-tabs-bar {
            height: 50px;
            margin: 5px 0 0 0;
            padding: 5px 24px 10px 24px;
            background-color: @colorBackground;

            .ant-tabs-nav-container {
                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-scroll {
                        .ant-tabs-nav {
                            .ant-tabs-tab {
                                margin-right: 24px !important;

                                h4 {
                                    font-size: 14px;
                                    // :global(html.theme-dark) & {
                                    //   color: @invitationCodeBgDark;
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    :global(html.theme-dark) & {
        :global {
            .ant-tabs-bar {
                background-color: @colorBackgroundDark;
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .QRWrapper {
        margin-top: 20px;
    }

    .cardContent {
        padding: 10px 10px 10px;

        .addressPhone {
            display: flex;
            flex-direction: column;
        }

        .addressView {
            display: none;
        }
    }
}

.dwHistoryWarning {
    display: flex;
    background: linear-gradient(160deg, rgba(255, 152, 81, 1) 0%, rgba(254, 91, 61, 1) 100%);
    border: 1px solid rgba(255, 151, 81, 0);
}

.withdrawSuccess {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5%;
    text-align: center;
    background: none;
    border: none;
    @keyframes scaletop {
        0% {
            transform: rotate(-180deg);
        }
        25% {
            transform: rotate(-90deg);
        }
        75% {
            transform: rotate(210deg);
        }
        100% {
            transform: rotate(-180deg);
        }
    }

    .successIcon {
        position: relative;
        width: 174px;
        height: 174px;
        margin-right: auto;
        margin-bottom: 5%;
        margin-left: auto;

        img {
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }

    .successTitle {
        height: 28px;
        margin-bottom: 4%;
        color: @increaseTextHeavy;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;

        :global(html.theme-dark) & {
            color: @increaseTextHeavyDark;
        }
    }

    .successTips {
        width: 78%;
        max-width: 702px;
        margin-right: auto;
        margin-bottom: 7%;
        margin-left: auto;
        color: @colorText6;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        word-wrap: normal;
        word-break: normal;
        // text-align: left;
        :global(html.theme-dark) & {
            color: @colorTextDark6;
        }
    }

    .reBackRouter {
        .reBackBtn {
            width: 60%;
            max-width: 260px;
            height: 40px;
            color: rgba(255, 255, 255, 1);
            font-weight: 500;
            font-size: 14px;
            line-height: 33px;
            background: @isbtnPrimary;
            border: none;
            border-radius: 32px;

            &:hover {
                color: @hoverTablesHoverBg;
            }

            :global(html.theme-dark) & {
                &:hover {
                    color: @hoverTablesHoverBgDark;
                }
            }
        }
    }
    .addNewBtn {
        color: @v2-primary-color !important;
    }
}

.blurAddress {
    filter: blur(40px);
}

.blurArea {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 27px 20px;
    color: @colorTextAccent;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // background-color:#fff;

    :global(html.theme-dark) & {
        // background-color:#242833;
        color: @colorTextAccentDark;
    }

    .blurTitle {
        height: 25px;
        color: @colorAccent2;
        color: rgba(255, 135, 79, 1);
        font-weight: 500;
        font-size: 18px;
        font-size: 18px;
        line-height: 25px;
        text-align: left;
    }

    .blurTip {
        margin-right: auto;
        // width: 332px;
        margin-left: auto;
        text-align: left;
    }

    .blurBtn {
        position: absolute;
        right: 27px;
        bottom: 30px;
        color: inherit !important;
        border: none;

        &:hover {
            color: @colorAccent2 !important;
        }

        &::after {
            margin-left: 6px;
            content: ">";
        }
    }
}

.withdrawDisableBtn {
    color: #ffffff;
    background: #a1a1a1 !important;
}

.withdrawSubmitBtn {
    color: #ffffff;
    background: @isbtnPrimary !important;
}

.withdraw {
    width: 100%;
    height: 100%;

    .withdrawInputStyle {
        height: 35px !important;
        font-size: 14px !important;
    }

    .editWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 15px;

        :global {
            .ant-checkbox-inner {
                background-color: transparent;
                border: 1px solid @colorTextAccent;

                :global(html.theme-dark) & {
                    border: 1px solid @colorTextAccentDark;
                }
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #1890ff !important;
                border: 1px solid #2c75ff !important;
            }

            .ant-checkbox-input:focus + .ant-checkbox-inner {
                border: 1px solid @colorTextAccent;

                :global(html.theme-dark) & {
                    border: 1px solid @colorTextAccentDark;
                }
            }
        }

        .sendButton {
            margin-top: 0;
            :global {
                .ant-btn:disabled {
                    background-color: transparent;
                }
            }
        }
    }

    .withdrawReceiveArea {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        margin-left: 10px;
        padding-right: 10px;
        padding-left: 10px;
        background: @windowBackground;

        :global(html.theme-dark) & {
            background: @windowBackgroundDark;
        }

        span {
            color: @colorTextAccent;
            font-size: 14px;

            :global(html.theme-dark) & {
                color: @colorTextAccentDark;
            }
        }
    }

    :global {
        .ant-btn {
            border-color: transparent !important;
        }

        .ant-btn-text:hover,
        .ant-btn-text:focus {
            background: transparent !important;
        }
        .self-beauty-input .shadow-placeholder .placeholder-text {
            .v2DarkTheme({
                background-color: @v2-grey-15 !important;
            });
        }
    }
    .balanceTipWrapper {
        color: @v2-grey-5;
    }
}

@media screen and (max-width: 991.98px) {
    .withdrawWrapper {
        padding-left: 15px;
    }

    .content_top {
        padding-right: unset;
        padding-bottom: 20px;
        padding-left: unset;
    }

    .card_history_style {
        :global {
            .ant-card-head {
                padding: 0 10px !important;
            }
        }
    }

    .withdraw {
        width: 100%;
        height: 100%;

        .withdrawReceiveArea {
            flex: 2;
        }
    }
}

.onlyWhiteList {
    margin-bottom: 10px;
    font-size: 12px;
    :global {
        .t-report-link {
            margin-left: 10px;
            color: @v2-primary-color !important;
            font-size: 12px;
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;