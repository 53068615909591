@import "~less/v2/vars.less";
.picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ccc;
    > span {
        position: relative;
        padding: 0 4px;
        border-radius: 8px;
        .icon {
            position: absolute;
            top: 50%;
            right: 4px;
            transform: translateY(-50%);
        }
        .v2NormalTheme({
            border: 1px solid #E4E7ED;
         });

        .v2DarkTheme({
             border: 1px solid #353942;
         });
        > input {
            width: 100%;
            height: 38px;
            padding-left: 10px;
            background-color: transparent;
            border: none;
            outline: none;
            &:focus {
                border: none;
                outline: none;
            }
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;