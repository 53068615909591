@import "~less/variables.less";
@import "../../less/colors.less";
@import "~less/v2/vars.less";
.walletContainer {
    padding-bottom: 50px;
}
.pieBalanceWrapper {
    background-color: @colorBackground;
    & > .pieView {
        min-height: 195px;
    }

    :global(html.theme-dark) & {
        background-color: @v2-grey-15;
    }
}

.pieHeadView {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 28px;
}

.pieHeadViewTip {
    width: 7px;
    height: 24px;
    background-color: #ff874f;
    border-radius: 2px;
}

.pieTitleStyle {
    margin-left: 10px;
    color: @colorTextAccent;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
    }
}

.pieHeaderFilter {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    padding-right: 30px;
    padding-left: 20px;
    line-height: 32px;
    // border-bottom:1px solid @colorDivider2;
}

.pieTotalAmount {
    display: flex;
    justify-content: center;
    margin-right: 70px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
}

.balanceWrapper {
    width: 100%;
    margin-top: 12px;
    padding-bottom: 20px;
    overflow: hidden;
    background-color: @colorBackground;
    border-radius: 16px;

    :global(html.theme-dark) & {
        background-color: @v2-grey-15;
        box-shadow: none;
    }
    :global {
        .ant-table-column-sorter-full {
            margin-top: -6px;
        }
        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
            font-size: 10px;
        }
        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
            color: @v2-primary-color;
        }
    }
}

.headerFilter {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 8px;

    :global(html.theme-dark) & {
        background-color: @v2-grey-15;
        box-shadow: none;
    }

    .headerFilterTitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        .v2Theme-color(@v2-grey-16, @v2-dark-10);
    }
    .headerFilterRight {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .v2MediaTablet({
        &,.headerFilterRight{
            display: block
        }
        .headerFilterTitle {
            margin-bottom: 15px;
        }
        .headerFilterRight {
            > *:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    });
}

.headerFilterViewStyle {
    padding-top: 25px;
}

.totalAmount {
    display: inline-block;
    margin-right: 70px;
    margin-bottom: 0;
    font-size: 16px;
}

.hideSmall {
    margin-right: 40px;
    .v2Theme-color(@v2-grey-16, @v2-dark-10);
}

.coinSearch {
    display: inline-block;
    height: 42px;
    margin-right: 42px !important;

    :global(.ant-input) {
        color: @buTextColor;
        background-color: transparent;
        border-color: @colorText;
        border-radius: 21px !important;
    }

    :global(.ant-input-suffix) {
        color: @buTextColor;
    }
}

.coinTable {
    margin-top: 20px;

    :global {
        .ant-table-thead {
            tr {
                th {
                    color: @chongbiTableTrThBg;
                    background-color: @colorBackground !important;
                    border-bottom: none !important;

                    :global(html.theme-dark) & {
                        color: @chongbiTableTrThBgDark;
                        background-color: @colorBackgroundDark !important;
                    }
                }
            }
        }

        .ant-table-tbody {
            tr {
                td {
                    // color: @capTitletextcolor;
                    border-bottom: none !important;

                    :global(html.theme-dark) & {
                        color: @capTitletextcolorDark;
                    }
                }
            }
        }
    }

    // th {
    //   color: @buTextColor !important;
    //   border-bottom: none !important;
    //   background-color: red !important;
    //   :global(html.theme-dark) & {
    //     background-color: pink !important;
    //   }
    // }

    // td {
    //   color: @buTextColor !important;
    //   background-color: @windowBackground2 !important;
    //   border-bottom-color: @windowBackground2 !important;
    // }

    :global {
        .ant-table-placeholder {
            color: @colorTextHint;
            background-color: @colorBackground !important;
            // background-color: @buTableRowBg;
            border: none;

            :global(html.theme-dark) & {
                background-color: @colorBackgroundDark !important;
            }
        }

        .ant-btn {
            margin-right: 4px;
        }
    }
}

.searchStyle {
    :global {
        .ant-input {
            border-radius: 30px !important;
        }

        .ant-input-suffix {
            margin-right: 10px;
        }
    }
}

.keyBalanceWrapper {
    margin-top: 10px;
    // padding: 20px 20px;

    :global {
        .ant-tabs {
            .ant-tabs-bar {
                padding-right: 24px;
                padding-left: 24px;

                .ant-tabs-nav-container {
                    .ant-tabs-nav-wrap {
                        .ant-tabs-nav-scroll {
                            .ant-tabs-nav {
                                .ant-tabs-tab {
                                    margin-right: 24px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.pieView {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.balaceListtableBoder {
    width: 100%;
    height: 4px;
    background-color: @colorBackground2;

    :global(html.theme-dark) & {
        background-color: @colorBackground2Dark;
    }
}

.pieListItemLiner {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.pieListItemLinerRight {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0 10px;
}

.tabPaneWrap {
    background-color: red;
}

.isBuTabsColorBg {
    min-width: 1200px;
    margin-top: 4px;

    :global(html.theme-dark) & {
        background-color: @windowBackground2Dark;
    }

    :global {
        // .ant-tabs{
        .ant-tabs-bar {
            margin: 10px 0 20px 0;
            padding-right: 24px;
            padding-left: 24px;

            .ant-tabs-nav-container {
                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-scroll {
                        .ant-tabs-nav {
                            .ant-tabs-tab {
                                margin-right: 24px !important;
                            }

                            .ant-tabs-tab-active {
                                color: @colorAccent !important;
                                border-color: @colorAccent !important;

                                h4 {
                                    color: @colorAccent !important;
                                }
                            }

                            .ant-tabs-tab:hover {
                                color: @colorAccent !important;
                            }

                            .ant-tabs-ink-bar {
                                background-color: @colorAccent !important;
                            }
                        }
                    }
                }
            }
        }

        // }
    }
}

.borrowHeader {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    padding-left: 16px;
    line-height: 32px;
}

.borrowHeaderTitle {
    display: inline-block;
    margin-bottom: 0;
    font-size: 16px;
}

.borrowHeaderContent {
    display: inline-block;
    margin-right: 70px;
    margin-bottom: 0;
    margin-left: 16px;
    font-size: 14px;
}

@media screen and (max-width: 767.98px) {
    .pieHeaderFilter {
        padding-left: 8px !important;
    }

    .coinTable {
        margin-top: 2px !important;
        padding-left: 4px;
    }

    :global {
        //   .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
        //     height: 1em;
        //     margin-top: 0.35em;
        //     margin-left: 0.57142857em;
        //     color: #bfbfbf;
        //     line-height: 1em;
        //     text-align: center;
        //     transition: all 0.3s;
        // }

        .ant-table-thead {
            tr {
                th:nth-of-type(5) {
                    margin-left: 10px;
                }

                th {
                    padding: 0;
                    padding-bottom: 10px;
                    font-size: 12px;

                    .ant-table-header-column {
                        .ant-table-column-sorters {
                            position: relative;

                            .ant-table-column-sorter {
                                // position: absolute;
                                // top: 6px;
                                // right: -10px;
                                display: none;
                            }
                        }
                    }

                    .ant-table-column-sorter {
                        .ant-table-column-sorter-inner {
                            margin-top: -0.6em;
                            margin-left: 0.2em;
                        }
                    }

                    :global(html.theme-dark) & {
                        color: @chongbiTableTrThBgDark;
                        background-color: @colorBackgroundDark !important;
                    }
                }
            }
        }

        .ant-table-tbody {
            tr {
                td {
                    padding: 0;
                    padding-bottom: 10px;
                    font-size: 12px;
                    border-bottom: none !important;
                }
            }
        }
    }

    .pieBalanceWrapper {
        padding: 0;
    }

    .pieTotalAmount {
        margin-right: 0;
    }
}

.historyBalanceWrap {
    margin-right: 16px;
    &:last-child {
        margin-right: 0;
    }
}

.smallAssetTransfer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 40px;
    font-size: 14px;
    cursor: pointer;
    .v2Theme-color(@v2-grey-16, @v2-dark-10);
}

.smallAssetSvg {
    display: flex;
    align-items: center;
    justify-content: center;
    .v2Theme-color(@v2-grey-16, @v2-dark-10);
}

@media screen and (max-width: 991.98px) {
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;