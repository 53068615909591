@import "../colors";

// @font-face{
//     font-family: 'NumberFont';
//     src : url('../../fonts/iconfont.ttf');
// }

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: roboto, poppins, sans-serif;
    // font-family: "Roboto", "PingFang SC", "Microsoft YaHei", robotoregular, Roboto, "Helvetica Neue", Helvetica, Arial, "PingFangSC-Regular", "PingFangSC-Medium", -apple-system,
    //     BlinkMacSystemFont, "Segoe UI", "Hiragino Sans GB", SimSun, sans-serif, -apple-system, "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: transparent;
}

.grecaptcha-badge {
    visibility: hidden;
}

* {
    box-sizing: border-box;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background-color: transparent;
}

.order_title {
    margin-right: 6px;
    font-weight: 600;
}

.order_text {
    font-size: 12px;
}

.tr-icon-btn {
    color: @colorText;
    cursor: pointer;
    transition: color 0.2s ease;
    user-select: none;

    &:hover {
        color: @colorAccent;
    }
}

.Resizer {
    cursor: col-resize;

    &.horizontal {
        cursor: row-resize;
    }

    &.disabled {
        cursor: default;
    }
}

@keyframes loadingRotateLoading {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0);
    }
}

.rotation {
    display: inline-block;
    animation: loadingRotateLoading 0.8s linear infinite;
}

.style_text {
    color: @colorText;
    font-weight: normal;
    font-size: 11px;
}

.style_icon {
    height: 18px;
    vertical-align: middle;
}

.klineRed {
    color: #f04848;
}

.klineGreen {
    color: #00b070;
}

.style_error_tips {
    color: #f04848;
    font-size: 12px;
}

.pionex-flex-column {
    display: flex;
    flex-direction: column;
}

.pionex-flex-row {
    display: flex;
    flex-direction: row;

    img {
        -webkit-filter: invert(50%);
        filter: invert(50%);

        :global(html.theme-dark) & {
            -webkit-filter: invert(100%);
            filter: invert(100%);
        }
    }
}

.pionex-cursor-pointer {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.pionex-seesaw {
    margin-bottom: 6px;
}

.pionex-bottomlength {
    margin-bottom: 10px;
}

.pionex-cardpaddingherader {
    padding: 14px 20px 4px 20px;
}

.pionex-cardcontent {
    padding: 0 20px 4px 20px;
}

.bg_coin_icon {
    background: url("../../images/coin_icon_sprite.png");
}

.number-font-family {
    font-family: "Jeremy", "PingFang SC", "Microsoft YaHei", sans-serif;
}

.pi-faq-question-list li {
    list-style: disc;
}
