@import "../../../../../less/colors";

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0;
    font-family: Roboto;

    .tabStyle {
        margin: 0 16px;

        :global {
            .pi-tabs-nav {
                margin: 0 !important;
            }
        }
    }

    :global(.normal) & {
        :global {
            .pi-tabs-nav-wrap::after {
                background: linear-gradient(to left, rgb(250, 251, 251) 40%, transparent 100%) !important;
            }

            .pi-tabs-nav-wrap::before {
                background: linear-gradient(to right, rgb(250, 251, 251) 40%, transparent 100%) !important;
            }
        }
    }

    :global(.dark) & {
        :global {
            .pi-tabs-nav-wrap::after {
                background: linear-gradient(to left, rgb(26, 30, 36) 40%, transparent 100%) !important;
            }

            .pi-tabs-nav-wrap::before {
                background: linear-gradient(to right, rgb(26, 30, 36) 40%, transparent 100%) !important;
            }
        }
    }

    .tabsSpecial {
        :global(.dark) & {
            :global {
                .pi-tabs-nav-wrap::after {
                    background: linear-gradient(to left, rgb(40, 44, 51) 40%, transparent 100%) !important;
                }

                .pi-tabs-nav-wrap::before {
                    background: linear-gradient(to right, rgb(40, 44, 51) 40%, transparent 100%) !important;
                }
            }
        }

        :global(.normal) & {
            :global {
                .pi-tabs-nav-wrap::after {
                    background: linear-gradient(to left, rgb(255, 255, 255) 40%, transparent 100%) !important;
                }

                .pi-tabs-nav-wrap::before {
                    background: linear-gradient(to right, rgb(255, 255, 255) 40%, transparent 100%) !important;
                }
            }
        }
    }

    .hiddenx {
        :global {
            .ant-tabs-nav-operations {
                position: absolute;
                visibility: hidden;
                pointer-events: none;
            }
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;