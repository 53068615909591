@import "~less/colors.less";
@import "~less/v2/vars.less";

.alert_content {
    @apply flex justify-between items-center flex-wrap text-sm;
}

.btn_warning {
    @apply !text-white !border-none;

    font-size: 12px !important;
    background: #db8b00 !important;
}

.btn_warning_hollow {
    color: #db8b00 !important;
    font-size: 12px !important;
    border: 1px solid #db8b00 !important;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;