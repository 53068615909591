@import "~less/v2/vars.less";
@import "~less/colors.less";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60px;
    height: 100%;
    border-right: solid 1px @v2-primary-bg-color-2;
}

.width {
    width: 55px;
}

.ant-popover-inner-content {
    padding: 0;
}

.tool_tip_layout {
    &.reverseLayout {
        :global {
            .ant-tooltip-arrow {
                right: -125px;
            }
        }
    }

    :global {
        .ant-tooltip-inner {
            width: 360px;
            padding: 0 !important;
            overflow: hidden;
            background-color: transparent !important;
            border-radius: 12px;
        }

        .ant-tooltip-arrow {
            bottom: 40px;
            width: 19px;
            height: 19px;

            .ant-tooltip-arrow-content {
                @apply bg-drop-down;

                width: 15px;
                height: 15px;
            }
        }
    }
}

.tool_tip {
    .tag_box {
        height: 26px;
        font-weight: 500;
        font-size: 12px;
        font-family: Roboto;
        line-height: 26px;
        .v2Theme-color(#292c33,#F2F3F5);
    }

    :global {
        .ant-tooltip-inner {
            min-width: 70px;
            height: auto;
            background: @main_h1_p_text_light !important;
            box-shadow: 0 0 20px 0 rgba(14, 27, 53, 0.12);
        }

        .ant-tooltip-arrow-content {
            background: @main_h1_p_text_light;
        }

        .ant-tooltip-arrow::before {
            background-color: @main_h1_p_text_light !important;
        }
    }

    :global(html.theme-dark) & {
        :global {
            .ant-tooltip-inner {
                background: @helper_alert_hover_tab_dark !important;
            }

            .ant-tooltip-arrow-content {
                background: @helper_alert_hover_tab_dark;
            }

            .ant-tooltip-arrow::before {
                background-color: @helper_alert_hover_tab_dark !important;
            }
        }
    }
}

.tabIcon {
    @apply leading-none text-xl;

    .v2MediaTablet({
    width: 18px;
    height: 18px;
    vertical-align: -4px;
    margin-right: 8px;
  });
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;