.banner_wrapper {
    @apply w-full rounded-16px flex items-center mb-12px dark:bg-none dark:bg-bg-100;
    height: 109px;
    background-image: url("./assets/gradient_bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    .banner_wrapper_img {
        width: 138.5px;
        height: 109px;
        margin-left: 90px;
    }

    .banner_wrapper_desc {
        @apply flex flex-col gap-6px ml-10px;
        margin-right: 60px;
    }

    .banner_wrapper_desc_h1 {
        @apply font-b text-text-100;
        font-size: 24px;
        line-height: 30px;
    }

    .banner_wrapper_desc_h2 {
        @apply text-base text-text-300;
    }
}

.step_wrapper {
    @apply flex items-center;

    .step_item {
        @apply flex flex-col;
    }

    .step_item_content {
        @apply rounded-full;
        width: 48px;
        height: 48px;
    }

    .step_item_div {
        @apply w-full h-full flex items-center justify-center rounded-full bg-[#e9ecf3] dark:bg-bg-400;
    }
    .step_item_icon {
        opacity: 0.6;
    }

    .step_item_text {
        @apply flex items-center justify-center text-center text-sm font-semibold text-text-100;
        height: 18px;
    }

    .step_item_active {
        .step_item_content {
            @apply border-2 border-primary border-solid p-2px opacity-100;
        }
        .step_item_icon {
            opacity: 1;
        }
    }

    .step_line {
        @apply flex-1 mb-18px border-0 border-t border-dashed border-text-400;
        min-width: 60px;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;