@import "~less/v2/vars.less";

.tips {
    max-width: 300px;
}

.itemTips {
    width: 14px;
    height: 14px;
    margin-left: 6px;
}

.operation {
    width: fit-content;
    padding: 4px 10px;
    border: 1px solid @v2-grey-21;
    border-radius: 6px;
    cursor: pointer;
    .v2Theme-color(@v2-grey-16,@v2-dark-10);

    .v2DarkTheme({
        border-color: @v2-dark-15;
    });
}

.optWrapper {
    display: flex;
    justify-content: flex-end;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;