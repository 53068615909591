.agreement {
    :global(.pi-modal-body) {
        padding: unset;
    }
}

.agreementDes2 {
    margin-top: 16px;
    margin-bottom: 32px;
}

.agreementDes3 {
    margin-top: 32px;
}

.agreementItem {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.container {
    width: 100%;
    max-width: 600px;
    min-height: 700px;
    margin: 0 auto;
    padding: 50px 20px;

    .selectTitleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }


    .submit {
        display: block;
        margin: 50px auto auto;
        padding: 0 100px;
    }
    :global {
        .ant-form-item {
            margin-bottom: 16px;
        }
    }
}
@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;