@import "~less/v2/vars.less";
.results {
    margin-top: 12px;
    .v2DarkTheme({
      :global {
        .ant-result-title {
          color: #fff;
        }
        .ant-result-subtitle {
          color: darken(#fff, 10)
        }
      }
    });
    :global {
        .ant-result-icon {
            margin-bottom: 20px;
        }
        .ant-result-subtitle {
            width: 360px;
            margin: 0 auto;
            margin-top: 10px;
            text-align: left;
        }
        .ant-result-extra {
            margin-top: 26px;
        }
    }
}
.tips {
    color: #8a909f;
    .v2DarkTheme({
      color: darken(#fff, 10)
    });
}
.btns {
    width: 200px;
    margin: 0 auto;
    padding-top: 14px;
    a {
        margin-bottom: 16px;
        font-weight: 600;
        text-align: left;
        :global {
            .iconfont:first-child {
                font-size: 20px;
                vertical-align: -2px;
            }
            .iconfont:last-child {
                float: right;
                font-size: 16px;
            }
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;