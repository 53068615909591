.Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.WrapperSpaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.WrapperColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.H1 {
    font-size: 2.5rem;
    color: #00b070;
    font-weight: 500;
    text-align: center;
}

.H2 {
    font-size: 1.24;
    color: #292c33;
    font-weight: 500;
    text-align: center;
}
.H3 {
    font-size: 0.83rem;
    color: #292c33;
    font-weight: 500;
    text-align: center;
    display: block;
}

.H4 {
    font-size: 0.83rem;
    color: #292c33;
    text-align: center;
}

.H5 {
    font-size: 0.747rem;
    color: #aeb2bd;
}

.H6 {
    font-size: 0.65rem;
    color: #aeb2bd;
    text-align: center;
}

.RadiusWrapper {
    border-radius: 20px;
    background-color: #ffffff;
    margin-left: 12px;
    margin-right: 12px;
}

.StickyFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.Avatar {
    width: 7.2rem;
    height: 7.2rem;
    border-radius: 50%;
    background: no-repeat;
    background-position: center;
    background-size: contain;
}

.LineProgress {
    background: #f2f4f7;
    box-shadow: 0 4px 20px rgba(66, 69, 81, 0.03);
    border-radius: 14px;
    width: 300px;
    height: 8px;
    margin: 0 24px;
}

.LineProgressContent {
    background: #ff7028;
    box-shadow: 0 4px 20px rgba(66, 69, 81, 0.03);
    border-radius: 14px;
    width: 100px;
    height: 8px;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;