@import "../../../../less/variables.less";
@import "../../../../less/colors.less";

.cellInput {
    height: 30px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.modalContainer {
    :global {
        .ant-modal-content {
            .ant-modal-header {
                border-bottom: none;
            }

            .ant-modal-body {
                padding: 0 24px 24px;
            }
        }
    }
}

.commonButton {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 81px;
    height: 33px;
    line-height: 32px;
    border: 1px solid @transfomModetext;
    border-radius: 4px;
    cursor: pointer;

    :global(html.theme-dark) & {
        border: 1px solid @transfomModetextDark;
    }
}

.modalConfirm {
    display: flex;
    color: #fff;
    background: @leaderindexBordercolor;
    border: 1px solid @leaderindexBordercolor;

    :global(html.theme-dark) & {
        border: 1px solid @leaderindexBordercolor;
    }
}

.disableButton {
    color: #fff;
    background: #9a9a9a;
    border: none;
    cursor: not-allowed;

    :global(html.theme-dark) & {
        background: #606374;
        border: none;
    }
}

.modalFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}

.cellWrapper {
    position: relative;
    margin-top: 16px;

    .rightCell {
        position: absolute;
        top: 11px;
        right: 8px;
        bottom: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 26px;
        font-size: 13px;
        background-color: @button_background;
        border-radius: 15px;
        cursor: pointer;

        :global(html.theme-dark) & {
            background-color: @button_background_dark;
        }

        .allSelect {
            color: @colorTextAccent;

            :global(html.theme-dark) & {
                color: @colorTextAccentDark;
            }
        }
    }
}

.marginCellInput {
    :global {
        .ant-input {
            height: 44px;
            padding: 0 8px;
        }

        .ant-input-number {
            height: 44px;

            .ant-input-number-input-wrap {
                .ant-input-number-input {
                    height: 44px;
                }
            }
        }

        .shadow-placeholder {
            .placeholder-text {
                :global(html.theme-dark) & {
                    background-color: #272b38 !important;
                }
            }
        }
    }
}

.marginContentView {
    font-size: 14px;
    line-height: 20px;
}

.marginRadioView {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.marginRadioItem {
    display: flex;
    margin-top: 5px;

    :global {
        .ant-radio-checked {
            .ant-radio-inner {
                border-color: @colorFavor;

                :global(html.theme-dark) & {
                    border-color: @colorFavorDark;
                }
            }

            .ant-radio-inner::after {
                background-color: @colorFavor;

                :global(html.theme-dark) & {
                    background-color: @colorFavorDark;
                }
            }
        }
    }
}

.marginTextRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
}

.advancedSettingContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .advancedText {
        font-weight: 500;
        font-size: 14px;
    }

    .advanceSetting {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 16px;
        padding-top: 2px;
        cursor: pointer;

        .advancedText {
            margin-right: 3px;
            color: @sliderblue;
            font-weight: 500;
            font-size: 13px;
        }
    }
}

.cellSlider {
    margin-bottom: 10px;
}

.fsaPriceGapContainer {
    margin-top: 30px;
}

.modalSpinView {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.fsaPriceGapInput {
    :global(html.theme-dark) & {
        box-shadow: inset 0 0 0 44px #272b38 !important;
    }
}

.fsaPriceGapInputLeft {
    :global(html.theme-dark) & {
        background-color: #272b38 !important;
    }
}

.SkeletonView {
    background-color: #fff;

    :global(html.theme-dark) & {
        background-color: #272b38 !important;
    }
}

.gapRightContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.gapRightView {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 4px 11px;
    border-radius: 38px;
    cursor: pointer;
}

.commonIcon {
    position: relative;
    display: inline-block;
    width: 27px;
    height: 27px;
    margin-right: 5px;
    margin-left: 20px;
    vertical-align: middle;
    background-image: url("../../../../images/order_close_top_speed_white.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;

    :global(html.theme-dark) & {
        background-image: url("../../../../images/order_close_top_speed_black.png");
    }
}

.cancelIcon {
    background-image: url("../../../../images/grid_pro_status_close.png");

    :global(html.theme-dark) & {
        background-image: url("../../../../images/grid_pro_status_close_black.png");
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;