@import "~less/v2/vars.less";

.modal {
    :global {
        .ant-modal-body {
            padding-right: 80px;
            padding-left: 80px;
        }

        .t-spin {
            .v2DarkTheme({
                background-color: rgba(255, 255, 255, 0.8) !important;
            });
        }
    }
}

.title {
    .v2Theme-color(@v2-grey-16,@v2-dark-10);
    margin-bottom: 42px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
}

.subTitle {
    font-weight: 500;
    font-size: @v2-lg-font-size;
    .v2Theme-color(black,white);
}

.billingTitle {
    .subTitle();
    margin-bottom: 12px;
}

.form {
    :global(.ant-form-item) {
        margin-bottom: 16px;
    }

    .step1Btn {
        display: block;
        min-width: 312px;
        margin: auto;
    }

    .step2Btns {
        display: flex;
        margin-top: 50px;
        margin-bottom: 18px;
        .step2Btn {
            flex: 1;
            &:not(:first-child) {
                margin-left: 24px;
            }
        }
    }
}

.tips {
    font-size: @v2-sm-font-size;
    .v2Theme-color(@v2-grey-20, @v2-grey-13);
}

.steps {
    width: 120px;
    margin: auto auto 20px;
}

.cardInfoTips {
    .tips();
    margin: 6px 0 16px;
}

.cardCVVContainer {
    display: flex;
    & > * {
        flex: 1;

        margin: 0 0 0 10px;
        &:first-child {
            margin: 0 10px 0 0;
        }
    }

    :global {
        .ant-picker {
            width: 100%;
        }
    }
}

.cardSample {
    margin: 16px 0 120px;
}

.cardImg {
    width: 100%;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;