@import "~less/v2/vars.less";
.datepicker {
    border-radius: 16px 16px 0px 0px;
    .datepicker-header {
        height: 66px;
        color: #ff7028 !important;
        font-weight: 500;
        font-size: 20px !important ;
        line-height: 66px !important;
        border-bottom: 1px solid #e4e7ed;
    }
    .datepicker-scroll {
        & > li {
            // height: 52px;
        }
    }
    .datepicker-wheel {
        border-top: 1px solid #ff7028 !important;
        border-bottom: 1px solid #ff7028 !important;
    }
    .datepicker-navbar {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        border-top: 1px solid #ccc !important;
        .datepicker-navbar-btn {
            width: 50%;
            text-align: center;
        }

        .datepicker-navbar-btn:nth-child(2) {
            border-right: 1px solid #ccc !important;
        }
    }
}

.ant-form-item-has-error {
    .pickCon {
        & > div {
            input {
                &::-webkit-input-placeholder {
                    color: red !important;
                }
            }
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;