@import "~less/v2/vars.less";
.cardUpload {
    &Normal {
        box-sizing: border-box;
        height: 128px;
        padding: 30px 0 35px;
        color: @v2-primary-color;
        text-align: center;
        background-color: @v2-upload-bg;
        border: 1px dashed @v2-primary-color;
        border-radius: 16px;
        cursor: pointer;
        img {
            width: 40px;
        }
        &Name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    &Error {
        padding: 30px 0 35px;
        color: @v2-danger-color;
        text-align: center;
        background-color: @v2-upload-danger-bg;
        border: 1px dashed @v2-danger-color;
        border-radius: 16px;
        cursor: pointer;
        :global {
            .t-button.ant-btn-primary {
                background-color: @v2-danger-color !important;
                border-color: @v2-danger-color !important;
            }
        }
    }
    &Img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 128px;
        background-size: 100% 100%;
        border-radius: 16px;
        cursor: pointer;
        &Mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: @v2-upload-img-bg !important;
            border-radius: 16px;
        }
        :global {
            .ant-btn.t-button {
                &,
                &:hover {
                    color: #fff !important;
                    .v2NormalTheme({
                        border-color: #fff !important;
                    });
                    .v2DarkTheme({
                        border-color: #fff !important;
                        background-color: transparent !important;
                    });
                }
            }
        }
    }
    &Loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;