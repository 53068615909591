@import "~less/v2/vars.less";
@import "~less/colors.less";

:global {
    .iconfont {
        font-size: 1.2em;
    }
}

.tReportLink,
:global(.t-report-link) {
    color: @v2-grey-1;
    font-size: @v2-content-font-size;
    .transitionAll();
    &:hover {
        color: @v2-primary-color;
    }
    .v2NormalTheme({
        color: @v2-grey-1;
        &:hover {
            color: @v2-primary-color;
        }
        &.primary {
            color: @v2-primary-color;
            &:hover {
                color: @v2-primary-color-hover;
            }
        }
    });
    .v2DarkTheme({
        color: #fff ;
        &:hover {
            color: @v2-primary-color;
        }
        &.primary {
            color: @v2-primary-color;
            &:hover {
                color: @v2-primary-color-hover;
            }
        }
    });
    &.tHasRep {
        position: relative;
        &::after {
            position: absolute;
            top: 2px;
            right: 2px;
            width: 4px;
            height: 4px;
            background-color: red;
            border-radius: 2px;
            content: "";
        }
    }
}

.tRepLinkBtn {
    display: inline-block;
}

.tModal,
:global(.t-modal) {
    :global {
        .ant-modal-content {
            overflow: hidden;
            border-radius: @v2-radius-box-size;
        }
        .ant-modal-header {
            padding: 0;
            font-weight: 600;
            text-align: center;
            border-bottom: none;
        }
        .ant-modal-body {
            padding: 12px 24px 28px;
        }
        .ant-modal-body .ant-modal-confirm-title {
            margin-left: 0;
            padding-top: 8px;
            padding-bottom: 16px;
        }
        .ant-modal-confirm-body .ant-modal-confirm-content {
            min-height: 50px;
            margin-top: 0;
        }
        .ant-modal-title {
            padding: 24px 24px 12px;
            text-align: left;
        }
        .ant-btn {
            min-width: 110px;
        }
        // .ant-modal-footer {
        //     padding: 12px 24px 24px;
        //     border-top: unset;
        //     button + button {
        //         margin-left: 12px;
        //     }
        //     .ant-btn {
        //         width: 110px;
        //         height: 38px;
        //         color: @v2-primary-color;
        //         border-color: @v2-primary-color;
        //         border-radius: 46px;
        //         &:hover,
        //         &:focus {
        //             color: @v2-primary-color !important;
        //             border-color: @v2-primary-color !important;
        //         }
        //         &:disabled {
        //             color: #ccc !important;
        //             background: #9a9a9a !important;
        //             border: #9a9a9a !important;
        //         }
        //     }
        //     .ant-btn-primary:not(:disabled)  {
        //         &,
        //         &:hover,
        //         &:focus {
        //             color: #fff !important;
        //             background: @v2-primary-color;
        //         }
        //     }
        // }
        .ant-modal-confirm-btns {
            button + button {
                margin-left: 12px;
            }
            .ant-btn {
                min-width: 110px;
                height: 38px;
                color: @v2-primary-color;
                border-color: @v2-primary-color;
                border-radius: 46px;
                &:hover,
                &:focus {
                    color: @v2-primary-color !important;
                    border-color: @v2-primary-color !important;
                }
                &:disabled {
                    color: #cccccc !important;
                    background: #9a9a9a !important;
                    border: #9a9a9a !important;
                }
            }
            .ant-btn-primary:not(:disabled) {
                &,
                &:hover,
                &:focus {
                    color: #ffffff !important;
                    background: @v2-primary-color;
                }
            }
        }
    }
}
:global {
    .t-modal.ant-modal-confirm {
        .ant-modal-body {
            padding: 16px 24px 24px;
        }
    }
}

.tModalClose {
    color: @v2-grey-6;
    font-size: 20px;
    line-height: 67px;
    .transitionAll();
    &:hover {
        color: @v2-grey-3;
    }
}
:global(.ant-modal-close-x):hover {
    .tModalClose {
        color: @v2-grey-3;
    }
}

:global {
    a.t-button,
    .t-button {
        padding: 0 24px;
        color: @v2-primary-color !important;
        font-size: @v2-content-font-size;

        &:not(.ant-btn-link) {
            background-color: @v2-grey-9 !important;
            border-color: @v2-grey-9 !important;
            .v2DarkTheme({
                border-color: @v2-dark-3 !important;
                background-color: unset !important;
            });
        }
        &:not(:disabled):hover {
            color: @v2-primary-color-hover !important;
            background-color: @v2-grey-8 !important;
            border-color: @v2-grey-8 !important;
            .v2DarkTheme({
                border-color: @v2-dark-4 !important;
                background-color: @v2-dark-4 !important;
            });
        }
        &:disabled {
            color: @v2-grey-7 !important;
        }
        &,
        &.ant-btn-md {
            height: @v2-md-height;
            line-height: @v2-md-height;
            border-radius: @v2-md-height / 2;
            &.t-btn-squared {
                border-radius: @v2-radius-item-size;
            }
        }
        &.ant-btn-sm {
            height: @v2-sm-height;
            padding: 0 12px;
            line-height: @v2-sm-height;
            border-radius: @v2-sm-height / 2;
            &.t-btn-squared {
                border-radius: 6px;
            }
        }
        &.ant-btn-lg {
            height: @v2-lg-height;
            line-height: @v2-lg-height;
            border-radius: @v2-lg-height / 2;
            &.t-btn-squared {
                border-radius: @v2-radius-box-size;
            }
        }
        &.ant-btn-primary {
            color: #ffffff !important;
            font-size: @v2-content-font-size;
            background: @v2-primary-color !important;
            border: unset !important;

            .v2DarkTheme({
                &:not(:disabled){
                    background: @v2-primary-color !important;
                    border-color: @v2-primary-color !important;
                }
            });
            &:not(:disabled) {
                color: #ffffff !important;
                background: @v2-primary-color !important;
                border-color: @v2-primary-color !important;
            }
            &:disabled {
                .v2NormalTheme({
                    color: #ffffff !important;
                    background: @v2-primary-disabled !important;
                });
                .v2DarkTheme({
                    color: rgba(255, 255, 255, 0.3) !important;
                    background-color:rgba(255, 112, 40, 0.3) !important;
                });
            }
        }
        &.t-button-bordered {
            color: @v2-primary-color !important;
            background: transparent !important;
            .v2NormalTheme({
                border-color: @v2-primary-color !important;
            });
            .v2DarkTheme({
                border-color: @v2-primary-color !important;
            });
            &:not(:disabled):hover {
                color: @v2-primary-color-hover !important;
                background: transparent !important;
                border-color: @v2-primary-color-hover !important;
                .v2NormalTheme({
                    border-color: @v2-primary-color !important;
                });
                .v2DarkTheme({
                    border-color: @v2-primary-color !important;
                });
            }

            &:disabled {
                .v2NormalTheme({
                    color: @v2-primary-disabled !important;
                    border-color: @v2-primary-disabled !important;
                });
                .v2DarkTheme({
                    color: @v2-primary-disabled-dark !important;
                    border-color: @v2-primary-disabled-dark !important;
                });
            }
        }
        &.t-button-blue {
            @blue: #1977f3;
            color: #ffffff !important;
            background: @blue !important;
            border-color: @blue !important;
            &:not(:disabled):hover {
                background: lighten(@blue, 10) !important;
                border-color: lighten(@blue, 10) !important;
            }
        }

        &:global(.ant-btn-link) {
            height: auto;
            font-size: 14px;
            background: transparent !important;
            border-color: transparent !important;
            box-shadow: none !important;
        }
    }
}

.tDropBlockOverlay,
.tSelectDrop {
    overflow: hidden;
    border-radius: @v2-radius-box-size;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
    // background-color: #fff;
    :global {
        .ant-dropdown-menu {
            min-width: 200px;
            padding: 10px 10px !important;
        }
    }
}

.inputFont {
    font-size: @v2-content-font-size;
}

:global {
    .t-select {
        .inputFont;
        @mdH: @v2-md-height - 2px;
        @lgH: @v2-lg-height - 2px;
        @smH: @v2-sm-height - 2px;
        .v2FormCompSize({
            .ant-select-selector {
                .v2FormCompSize-md(true);
                &::after,
                .ant-select-selection-placeholder,.ant-select-selection-search-input {
                    line-height: @mdH !important;
                    height: @mdH !important;
                }
            }
        },{
            .ant-select-selector {
                .v2FormCompSize-lg(true);
                &::after,
                .ant-select-selection-placeholder,.ant-select-selection-search-input {
                    line-height: @lgH !important;
                    height: @lgH !important;
                }
            }
        },{
            .ant-select-selector {
                .v2Height-sm(true);
                &::after,
                .ant-select-selection-placeholder,.ant-select-selection-search-input {
                    line-height: @smH !important;
                    height: @smH !important;
                }
            }
        });

        &.ant-select-focused,
        &.ant-select-open {
            .ant-select-selector {
                border-color: @v2-primary-color !important;
                .v2FormCompFocusBoxShadow(true);
            }
        }

        &.ant-select-single:not(.ant-select-customize-input) {
            .v2FormCompSize({
                .ant-select-selector {
                    .ant-select-selection-item,
                    .ant-select-selection-placeholder {
                        line-height: @mdH !important;
                    }
                }
            },{
                .ant-select-selector {
                    .ant-select-selection-item,
                    .ant-select-selection-placeholder {
                        line-height: @lgH !important;
                    }
                }
            },{
                .ant-select-selector {
                    .ant-select-selection-item,
                    .ant-select-selection-placeholder {
                        line-height: @smH !important;
                    }
                }
            });
        }
        &:not(.ant-select-disabled):hover .ant-select-selector {
            .v2NormalTheme({
                border-color: @v2-border-color;
            });
            .v2DarkTheme({
                border-color: #393e47;
            });
        }
        .ant-select-selector {
            background-color: unset;
            border-radius: @v2-radius-item-size !important;
            .v2NormalTheme({
                border: 1px solid @v2-border-color;
            });
            .v2DarkTheme({
                border-color: #393e47;
            });
            .ant-select-selection-item,
            .ant-select-selection-placeholder {
                .iconfont {
                    vertical-align: middle;
                }
            }
        }
        &.ant-select-show-search.ant-select-open {
            .ant-select-selection-item {
                display: none;
            }
        }
        .ant-select-arrow {
            top: 40%;
            right: 18px;
            font-size: 20px;
            .v2Theme-color(@v2-grey-1, @v2-grey-9);
        }
    }
    .t-select-drop {
        min-width: 160px !important;
        padding: 10px !important;
        border-radius: @v2-radius-box-size;
        .ant-select-item {
            .inputFont;
            border-radius: @v2-radius-item-size;
            .iconfont {
                margin-right: 5px;
                font-size: 1.2em;
            }
            &:hover {
                color: @v2-primary-color-hover;
            }
            &:hover,
            &.ant-select-item-option-active {
                .v2Theme-background-color(@v2-grey-8, @v2-dark-3);
            }
            &.ant-select-item-option-selected {
                position: relative;
                color: @v2-primary-color-hover !important;
                font-weight: normal;
                background-color: transparent;
                &:hover {
                    .v2Theme-background-color(@v2-grey-8, @v2-dark-3);
                }
                .iconfont {
                    color: @v2-primary-color-hover;
                }
            }
        }
        .v2FormCompSize({
            .v2FontSize-md;
            padding: 8px 10px;
        },{
            .ant-select-item{
                .v2FontSize-lg;
                padding: 12px;
            }
        },{
            .ant-select-item{
                .v2FontSize-sm;
                padding: 5px 10px;
            }
        });
    }
}

.tBlock {
    position: relative;
    display: block;
    padding: 16px;
    font-size: @v2-content-font-size;
    border-radius: @v2-radius-item-size;
    .v2Theme-background-color(@v2-grey-9, @v2-dark-3);
    .v2DarkTheme({
        color: #fff;
    });
    &:global(:not(.t-block-no-hover)) {
        &:hover {
            .v2Theme-background-color(@v2-grey-8, @v2-dark-4);
        }
    }
}

.tMenuBlock {
    .tMenuBlockInner {
        display: flex;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        :global(.iconfont) {
            font-size: 1.3em;
            &:first-child {
                padding-right: 10px;
            }
        }
        > div {
            flex: 1;
        }
    }
    .tMenuBlockMore {
        display: flex;
        align-items: center;
        font-size: @v2-content-font-size;
    }
}

.tMenuBlockGroup {
    overflow: hidden;
    border-radius: @v2-radius-item-size;
    .tMenuBlock {
        border-radius: 0;
    }
    &.tMBGGrid {
        .tMenuBlock {
            border-radius: @v2-radius-item-size;
        }
    }
}

.tMenu {
    .v2Theme-background-color(#fff, @v2-dark-2);
    &.isolated:global(.ant-menu-vertical) {
        border-right: 0;
    }
    &.isolated :global {
        .t-report-link {
            padding: 14px 14px;
            color: @v2-grey-4;
            font-weight: 500;
            font-size: 14px;
        }
    }
    & :global(.ant-dropdown-menu-item-disabled) {
        color: @v2-dropdown-color-disabled !important;
        background-color: @v2-dropdown-bg-disabled;
    }
}

._menuIcon {
    margin-right: 8px;
    vertical-align: -2px;
}

.subTitleEff() {
    .v2Theme-color(@v2-primary-color-hover, @v2-primary-color-hover);
    :global(.ant-menu-submenu-arrow) {
        &::before,
        &::after {
            background: @v2-primary-color-hover;
        }
    }
}

.tSubMenu {
    background-color: transparent;
    :global {
        .iconfont {
            ._menuIcon;
        }
        .ant-menu-sub {
            background-color: transparent;
        }
        .ant-menu-submenu-active,
        .ant-menu-submenu-title:hover {
            .subTitleEff;
        }
        .ant-menu-submenu-title {
            .v2Theme-color(#9fa7b8, #828894);
            .ant-menu-submenu-arrow::before,
            .ant-menu-submenu-arrow::after {
                background: #9fa7b8;
                .v2DarkTheme({
                    background: #828894;
                });
            }
        }
    }
}
// 非导航内颜色走
:global(:not(.t-navigator) .t-menu) .tSubMenu {
    &:global(.ant-menu-submenu-open .ant-menu-submenu-title) {
        .subTitleEff;
    }

    &:global(.t-navigator-selected-sub .ant-menu-submenu-title) {
        a {
            &:global(.t-report-link) {
                .v2Theme-color(@v2-primary-color-hover, @v2-primary-color-hover);
            }
        }
    }
}

.tMenuItem {
    padding: 12px;
    border-radius: @v2-radius-item-size;
    a {
        margin: -12px;
        padding: 12px;
    }
    :global {
        .iconfont {
            ._menuIcon;
        }
    }
    &:global(.ant-menu-item-selected) {
        .t-report-link {
            .v2Theme-color(#fff, #fff);
        }
        &::after {
            content: unset;
        }
        background-color: @v2-primary-color !important;
    }
    &:hover,
    &:active,
    &:global(.ant-menu-item-active),
    &:global(.ant-dropdown-menu-item-selected),
    &:global(:not(.ant-menu-inline) .ant-menu-submenu-open) {
        color: @v2-primary-color-hover;
        .v2Theme-background-color(@v2-dark-9, @v2-dark-3);
    }
    &:global(.ant-dropdown-menu-item-selected) {
        .v2DarkTheme({
            color: @v2-primary-color-hover;
        });
    }
}
:global(.ant-menu-sub) {
    .tMenuItem {
        a {
            margin: -6px;
            padding: 12px;
        }
    }
}

.tSwitch {
    background-color: #c9ccd1;
    &:global(.ant-switch-checked) {
        background-color: @v2-primary-color;
    }
}

.tBlockLink {
    position: relative;
    display: inline-block;
    height: @v2-sm-height;
    padding: 0 24px;
    font-size: @v2-content-font-size;
    line-height: @v2-sm-height;
    text-align: center;
    border-radius: @v2-sm-height / 2;
    .transitionAll();
    .v2Theme-background-color(@v2-grey-9, @v2-dark-4);
    &:hover {
        .v2Theme-background-color(@v2-grey-8, @v2-dark-3);
    }
    &.active {
        color: #ffffff !important;
        background-color: @v2-primary-color !important;
    }
    &.blocked {
        display: block;
    }
    &.type_link {
        .v2Theme-background-color(transparent, transparent);
        &:hover {
            .v2Theme-background-color(@v2-grey-8, @v2-dark-3);
        }
    }
    &.type_lined {
        color: @v2-primary-color !important;
        background-color: transparent !important;
        border: 1px solid @v2-primary-color;
        &:hover {
            color: #ffffff !important;
            background-color: @v2-primary-color-hover !important;
            border-color: @v2-primary-color-hover !important;
        }
        .v2DarkTheme({
            color: @v2-primary-color !important;
            &:hover {
                background-color: @v2-primary-color-hover !important;
                color: #fff !important;
            }
        });
    }
    &.type_primary {
        color: #ffffff !important;
        background-color: @v2-primary-color !important;
        &:hover {
            color: #ffffff !important;
            background-color: @v2-primary-color-hover !important;
        }
        .v2DarkTheme({
            color: #fff !important;
        });
    }
    &.type_blue {
        @blue: #1977f3;
        color: #ffffff !important;
        background-color: @blue !important;
        border-color: @blue !important;
        &:hover {
            background: lighten(@blue, 10) !important;
            border-color: lighten(@blue, 10) !important;
        }
    }
}

.tCarousel {
    .tCarouselDots {
        display: inline-block !important;
    }
}
.tCarouselDots {
    right: 5px !important;
    bottom: 5px !important;
    left: unset !important;
    margin: auto !important;
    padding: 10px;
    :global {
        height: auto;
        li {
            width: auto !important;
            height: auto !important;
            margin: 0 !important;
            button {
                width: 30px !important;
                height: 30px !important;
                padding: 10px !important;
                background-color: transparent !important;
                &::after {
                    display: block;
                    width: 10px;
                    height: 10px;
                    border: 1px solid #292c33;
                    border-radius: 50%;
                    content: "";
                }
            }
            &.slick-active {
                width: auto !important;
                button::after {
                    background-color: #292c33;
                }
            }
        }
    }
    &.dot_dark {
        :global {
            li {
                button {
                    &::after {
                        border-color: #292c33;
                    }
                }
                &.slick-active {
                    button::after {
                        background-color: #292c33;
                    }
                }
            }
        }
    }
    &.dot_white {
        right: 0 !important;
        bottom: -60px !important;
        left: 0 !important;
        text-align: center;
        :global {
            li {
                button {
                    &::after {
                        border-color: #ffffff;
                    }
                }
                &.slick-active {
                    button::after {
                        background-color: #ffffff;
                    }
                }
            }
        }
        .v2DarkTheme({
            color: @v2-primary-color !important;
            &:hover {
                color: #fff !important;
            }
        });
    }
    &.type_primary {
        color: #ffffff !important;
        background-color: @v2-primary-color !important;
        &:hover {
            color: #ffffff !important;
            background-color: @v2-primary-color-hover !important;
        }
        .v2DarkTheme({
            color: #fff !important;
        });
    }
}

:global {
    .t-checkbox {
        border-color: @v2-grey-7;
        &:hover {
            border-color: @v2-primary-color;
            .v2Theme-color(#333, @v2-grey-5);
        }
        .ant-checkbox-inner {
            background-color: transparent;
            .v2DarkTheme({
                border-color: @v2-border-dark-color;
            });
        }
        .ant-checkbox.ant-checkbox-checked {
            &::after {
                border-color: @v2-primary-color;
            }
            .ant-checkbox-inner {
                background-color: @v2-primary-color;
                border-color: @v2-primary-color;
            }
        }
        &:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: @v2-primary-color;
        }
        .v2DarkTheme({
            .ant-checkbox:not(.ant-checkbox-checked, .ant-checkbox-disabled) .ant-checkbox-inner {
                border-color: #9c9c9c !important;
            }
            .ant-checkbox-disabled {
                .ant-checkbox-inner {
                    border-color: #4a4a4a !important;
                }
                & + span {
                    color: rgba(255, 255, 255, 0.3) !important;
                }
            }
        });
    }

    .t-tabs {
        &.fullHeight {
            display: flex;
            height: 100%;
            .ant-tabs-nav {
                margin-bottom: 0;
                padding: 0 12px;
            }
            .ant-tabs-content-holder {
                position: relative;
                flex: 1;
                .ant-tabs-content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    .ant-tabs-tabpane {
                        width: 100%;
                        height: 100%;
                        overflow-y: auto;
                    }
                }
            }
        }
        .ant-tabs-tab {
            height: 34px;
            margin-right: 20px !important;
            color: @v2-grey-4;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }
        .ant-tabs-ink-bar {
            display: none;
        }
        .ant-tabs-tab-active {
            color: @v2-primary-color-2 !important;
            font-weight: 700;
            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 10px;
                height: 3px;
                margin-left: -5px;
                background-color: @v2-primary-color-2;
                border-radius: 20px;
                content: "";
            }
        }
    }
    .ant-form-item-has-error {
        .t-input-wrapper {
            .ant-input {
                &,
                &:hover,
                &:focus {
                    border-color: @v2-danger-color;
                    .v2DarkTheme({
                        background-color: transparent;
                    });
                }
            }
            &-placeholder {
                color: @v2-danger-color;
            }
        }
        .ant-select:not(.ant-select-borderless) .ant-select-selector {
            border-color: @v2-danger-color !important;
        }

        .ant-picker-input {
            input {
                &::placeholder {
                    color: @v2-danger-color !important;
                    font-weight: bold;
                }
            }
        }
    }
    .ant-form-item {
        .ant-form-item-explain {
            display: none;
        }
    }
    .t-input {
        &:focus,
        &:hover,
        &-focused,
        &:-webkit-autofill:focus {
            border-color: @v2-primary-color !important;
            box-shadow: unset;
            .v2DarkTheme({
                border-color: @v2-primary-color !important;
            });
        }
        &:-webkit-autofill,
        &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 100px #fff inset;
            -webkit-text-fill-color: #333;
            .v2DarkTheme({
                -webkit-box-shadow: 0 0 0 100px @v2-grey-15 inset;
                -webkit-text-fill-color: #fff;
            });
        }
    }
    .t-input-wrapper {
        position: relative;
        .ant-input {
            padding: (@v2-md-height - @line_nor - 2px)/2;
            line-height: @line_nor;
            border-radius: 10px;
            .v2DarkTheme({
                border-color: @v2-border-dark-color;
            });
            @line_nor: 20px;
            &.ant-input-lg {
                @line_lg: 25px;
                padding: (@v2-lg-height - @line_lg - 2px)/2;
                line-height: @line_lg;
            }
            &.ant-input-sm {
                @line_sm: 18px;
                padding: (@v2-sm-height - @line_sm - 2px)/2;
                line-height: @line_sm;
            }
        }

        &-placeholder {
            position: absolute;
            top: 0;
            left: 12px;
            z-index: 1;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 4px;
            font-size: 14px;
            transition: top linear 150ms, font-size linear 150ms;
            pointer-events: none;
            .v2Theme-color(@v2-grey-14,#858ca3);

            &-up {
                top: -50%;
                font-size: 12px;

                &-end {
                    right: 12px;
                    left: unset;
                }
            }
        }
        .ant-input:not(:disabled) + .t-input-wrapper-placeholder {
            > span {
                .v2Theme-background-color(#fff,@v2-grey-15);
            }
        }
        &-has-error {
            .t-input-wrapper-placeholder {
                font-weight: bold;
                .v2NormalTheme({
                    color: @v2-danger-color;
                });
                .v2DarkTheme({
                    color: @v2-danger-color;
                });
            }
            .ant-input {
                // & {
                //     box-shadow: 0 0 5px @v2-danger-color;
                // }
                // &:hover,
                // &:focus {
                //     box-shadow: 0 0 8px @v2-danger-color;
                // }
                &,
                &:hover,
                &:focus {
                    .v2NormalTheme({
                        border-color: @v2-danger-color;
                    });
                    .v2DarkTheme({
                        border-color: @v2-danger-color;
                        background-color: transparent;
                    });
                }
            }
        }
    }
}
.tTable {
    overflow: hidden;
    border-radius: @table-radius;
    .v2Theme-background-color(#fff,#212429);
    :global {
        .ant-table-content {
            // padding-bottom: 20px;
        }
        .ant-table {
            overflow: hidden;
            border-radius: @table-radius;
            &:not(.ant-table-empty) .ant-table-content {
                // padding-bottom: 20px;
            }
            // 默认表格行高为58px：20*2+18
            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td {
                padding: 20px;
            }
            // 小的为46px：14*2+18
            &.ant-table-small {
                .ant-table-thead > tr > th,
                .ant-table-tbody > tr > td {
                    padding: 14px;
                }
            }
        }

        .ant-table-thead > tr > th {
            font-weight: normal;
            font-size: 12px;
            border-bottom: 0;
            .v2Theme-color(@v2-grey-12, @v2-grey-13);
            .v2Theme-background-color(#fff,#212429);
        }
        .ant-table-tbody > tr {
            .v2Theme-background-color(#FBFBFC, @v2-grey-15);
            &:nth-child(even) {
                .v2Theme-background-color(#fff,#212429);
            }
            &:hover {
                .v2Theme-background-color(#F5F5F7, #2d3036);
            }
        }
        .ant-table-tbody > tr > td {
            color: @v2-grey-11;
            border-bottom: 0;
        }
        .ant-pagination {
            .v2Theme-background-color(#fff,#212429);
        }
        .ant-pagination-item {
            border: none;
            border-radius: 8px;
            a:hover {
                .v2Theme-color(@v2-primary-color-2, @v2-grey-6);
            }
            a {
                .v2Theme-color(@v2-grey-17, @v2-dark-12);
            }
        }
        .ant-pagination-item-active {
            .v2Theme-color(@v2-primary-color-2, @v2-primary-color-2);
            .v2Theme-background-color(rgba(255, 112, 40, 0.08), rgba(255, 112, 40, 0.16));
            & > a {
                .v2Theme-color(@v2-primary-color-2, @v2-primary-color-2);
            }
        }
        .ant-pagination-prev,
        .ant-pagination-next {
            .ant-pagination-item-link {
                font-weight: bold;
                border: 0;
                .v2Theme-color(@v2-grey-17, @v2-dark-12);
                .v2Theme-background-color(transparent,transparent);
            }
        }
        .ant-table-placeholder {
            .v2Theme-background-color(#fff, transparent);
            tr:hover td {
                .v2Theme-background-color(#fff, transparent);
            }
        }
        .ant-table-cell-fix-left,
        .ant-table-cell-fix-right {
            .v2Theme-background-color(inherit, inherit);
        }
        .ant-spin-nested-loading > div > .ant-spin {
            .v2Theme-background-color(@v2-primary-bg-color-2, unset);
        }
        .ant-table-column-sorter-full {
            margin-top: -0.5em;
        }
        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
            font-size: 9px;
            &.active {
                color: @v2-primary-color;
            }
        }
        td.ant-table-column-sort {
            background-color: transparent;
        }
    }
}
.tRadio {
    :global {
        .ant-radio-checked {
            &::after {
                border-color: @v2-primary-color;
            }
            .ant-radio-inner {
                border-color: @v2-primary-color;
                &::after {
                    background-color: @v2-primary-color;
                }
            }
        }

        .ant-radio-disabled + span {
            .v2Theme-color(@v2-grey-21,@v2-dark-15);
        }

        .ant-radio-disabled {
            .ant-radio-inner {
                .v2NormalTheme({
                    background-color: @v2-grey-18;
                    border-color: @v2-grey-21 !important;
                });

                .v2DarkTheme({
                    background-color: @v2-dark-13;
                    border-color: @v2-dark-15 !important;
                });
            }
        }
        //.ant-radio-inner {
        //    background-color: transparent;
        //}
    }
}
.tRadioGroup {
    &_collective {
        :global {
            .ant-radio-button-wrapper {
                color: @v2-grey-1;
                //border-left-width: 1px;
                .v2DarkTheme({
                    color: @v2-grey-14;
                    border-color: @v2-grey-14;
                    //border-color: @v2-border-dark-color;
                });
            }
            .ant-radio-button-wrapper-checked {
                .v2Theme-color(@v2-primary-color-2,@v2-primary-color-2);
                //background: rgba(255, 112, 40, 0.08);
                border-color: @v2-primary-color-2;
            }
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
                .v2Theme-color(@v2-primary-color-2,@v2-primary-color-2);
                border-color: @v2-primary-color-2;
            }
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                .v2Theme-background-color(@v2-primary-color-2, @v2-primary-color-2);
            }
        }
    }

    &_separate {
        :global {
            .ant-radio-button-wrapper {
                width: 84px;
                margin-right: 10px;
                color: @v2-grey-1;
                font-size: 14px;
                line-height: 32px;
                text-align: center;
                border-left-width: 1px;
                border-radius: @v2-radius-item-size;
                .v2DarkTheme({
                    border-color: @v2-border-dark-color;
                });
            }
            .ant-radio-button-wrapper-checked {
                color: @v2-primary-color-2;
                background: rgba(255, 112, 40, 0.08);
                border-color: @v2-primary-color-2;
            }
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
                color: #ffffff;
                border-color: @v2-primary-color-2;
            }
        }
    }
    &:global(.ant-radio-group-large .ant-radio-button-wrapper) {
        line-height: 40px;
    }
    &:global(.ant-radio-group-small .ant-radio-button-wrapper) {
        line-height: 24px;
    }
}
.tLoading {
    display: inline-block;
    vertical-align: middle;
    background-image: url("../../../images/loading.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    &,
    &.medium {
        width: 40px;
        height: 40px;
    }
    &:global(.small) {
        width: 30px;
        height: 30px;
    }
    &:global(.large) {
        width: 68px;
        height: 68px;
    }
    // color: @v2-primary-color-2;
    // font-size: 24px;
}
.tTooltip,
:global(.t-tooltip) {
    :global {
        .ant-tooltip-inner {
            padding: 12px !important;
            color: @indexTextColor;
            font-size: @v2-md-font-size;
            background-color: @main_h1_p_text_light !important;
            box-shadow: 0 0 20px 0 rgba(14, 27, 53, 0.12);

            .v2DarkTheme({
                color: @colorTextDark3;
                background-color: @colorBackgroundNewDark !important;
            });
        }
        .ant-tooltip-arrow-content {
            background-color: @main_h1_p_text_light;
            .v2DarkTheme({
                background-color: @colorBackgroundNewDark !important;
            });
        }
        .ant-tooltip-arrow::before {
            background-color: @main_h1_p_text_light !important;
            .v2DarkTheme({
                background-color: @colorBackgroundNewDark !important;
            });
        }
    }
}

.tProgress,
:global(.t-progress) {
    :global {
        .ant-progress-success-bg,
        .ant-progress-bg {
            background-color: @v2-primary-color;
        }

        .ant-progress-inner {
            .v2Theme-background-color(#e1e4eb,#e1e4eb);
        }
    }
}

.tSteps,
:global(.t-steps) {
    &:global(.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item) {
        &:not(:last-child) {
            margin-right: 4px;
        }

        &:last-child {
            :global {
                .ant-steps-item-icon {
                    margin-right: 0;
                }
            }
        }
    }

    :global {
        .ant-steps-item-process .ant-steps-item-icon {
            background-color: @v2-primary-color;
            border-color: @v2-primary-color;
        }
        .ant-steps-item-finish .ant-steps-item-icon {
            background-color: transparent;
            border-color: @v2-primary-color;
        }
        .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
            color: @v2-primary-color;
        }
        .ant-steps-item-icon {
            margin: 0 4px 0 0;
            font-weight: 500;
            background-color: transparent;
        }
        .ant-steps-item-wait {
            .ant-steps-item-icon {
                .v2DarkTheme({
                    border-color: @v2-grey-14;
                });

                & > .ant-steps-icon {
                    .v2DarkTheme({
                        color: @v2-grey-14;
                    });
                }
            }
        }
        .ant-steps-item-title::after {
            left: 0;
            background-color: unset !important;
            border-top: 1px dashed @v2-dropdown-color-disabled;
        }
        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
            border-color: @v2-primary-color;
        }
    }
}

:global {
    .t-mask-loading {
        position: relative;
        &-mask {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8) !important;
            visibility: hidden;
            .v2DarkTheme({
                background-color: rgba(39, 41, 46, 0.8) !important;
            });
            &.spinning {
                visibility: visible;
            }
        }
    }
    .t-spin {
        display: block;
        max-height: 100% !important;
        background-color: rgba(255, 255, 255, 0.8) ;
        .v2DarkTheme({
            max-height: unset;
            background-color: rgba(39, 41, 46, 0.8) ;
        });
        .ant-spin-text {
            margin-top: 25px;
            color: @v2-grey-16;
            .v2DarkTheme({
                color: #d5e2ff
            });
        }
    }
    .t-spin-wrapper {
        .ant-spin-container {
            opacity: 1 !important;
            &.ant-spin-blur {
                user-select: auto;
                pointer-events: all;
            }
            &::after {
                display: none !important;
            }
        }
        &.transparent {
            .t-spin {
                .v2NormalTheme({
                    background-color: transparent !important;
                });
                .v2DarkTheme({
                    background-color: transparent !important;
                });
            }
        }
    }
    .t-page-title {
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        .v2Theme-color(@v2-grey-16, @v2-dark-10);
    }
    .t-navigator {
        .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left {
            border: none;
        }
        .t-menu {
            .v2Theme-background-color(transparent, transparent);
            font-size: 15px;

            .t-menu-item {
                font-weight: 500;
                &,
                .t-report-link {
                    font-weight: 500;
                    .v2Theme-color(#9fa7b8, #828894);
                }
                .v2Theme-color(@v2-grey-14, @v2-grey-13);
                &:hover,
                &:active,
                &.ant-menu-item-selected {
                    .v2Theme-color(@v2-primary-color, @v2-primary-color);
                    .v2Theme-background-color(@v2-primary-bg-color, @v2-grey-1) !important;
                    .t-report-link {
                        .v2Theme-color(@v2-primary-color, @v2-primary-color);
                    }
                }
            }
            .ant-menu-submenu-title {
                font-weight: 500;
                &:hover {
                    color: @v2-primary-color-hover;
                    .ant-menu-submenu-arrow {
                        &::before,
                        &::after {
                            background: @v2-primary-color-hover;
                        }
                    }
                }
            }
            .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
                .v2Theme-color(@v2-grey-14, @v2-grey-13);
                a {
                    .v2Theme-color(@v2-grey-14, @v2-grey-13);
                }
                .ant-menu-submenu-arrow {
                    &::before,
                    &::after {
                        background: @v2-grey-14;
                        .v2DarkTheme({
                            color: @v2-grey-13;
                        });
                    }
                }
                &:hover {
                    color: @v2-primary-color-hover;
                    .ant-menu-submenu-arrow {
                        &::before,
                        &::after {
                            background: @v2-primary-color-hover;
                        }
                    }
                }
            }
            .t-navigator-selected-sub.ant-menu-submenu {
                .ant-menu-submenu-title {
                    .v2Theme-color(@v2-primary-color-hover, @v2-primary-color-hover);
                    .ant-menu-submenu-arrow {
                        &::before,
                        &::after {
                            background: @v2-primary-color-hover;
                        }
                    }
                }
            }
        }
    }
    .t-search-input {
        width: 230px;
        height: 38px;
        border-color: @v2-grey-17;
        border-radius: 12px;
        .v2DarkTheme({
            color: @v2-dark-6;
            border-color: @v2-dark-11;
        });
        &:global(.ant-input-affix-wrapper) {
            .v2Theme-background-color(#fff, @v2-grey-15);
            .v2DarkTheme({
                border-color: @v2-dark-11;
            });
        }
        :global(.ant-input-prefix) {
            color: @v2-grey-6;
            .iconfont {
                font-size: 24px;
            }
            .v2DarkTheme({
                border-color: @v2-dark-11;
            });
        }
        :global(.ant-input-suffix) {
            svg path {
                fill: @v2-dark-6;
                .v2DarkTheme({
                    fill:  @v2-dark-11;
                });
            }
        }
        &:hover,
        &:global(.ant-input-affix-wrapper-focused) {
            border-color: @v2-primary-color;
            box-shadow: none;
            .v2DarkTheme({
                border-color: @v2-primary-color;
            });
        }
        :global(.ant-input) {
            border: none !important;
            &::-webkit-input-placeholder {
                font-size: 12px;
                .v2Theme-color(@v2-grey-6, @v2-dark-11);
            }
        }
    }

    .t-layout,
    .t-sider {
        &:global(.transparent) {
            background: transparent;
        }
    }
    .t-date-range-picker {
        height: 46px;
        border-radius: 12px;
        box-shadow: none;
        &.ant-picker-small {
            height: 38px;
        }
        .v2NormalTheme({
            border-color: @v2-border-color;
        });
        .v2DarkTheme({
            border-color: @v2-border-dark-color;
            background-color: @v2-grey-1;
        });
        &.ant-picker-focused,
        &:hover,
        .ant-picker-focused {
            .v2NormalTheme({
                border-color: @v2-primary-color;
            });
            .v2DarkTheme({
                border-color: @v2-primary-color;
            });
        }
        .ant-picker-active-bar {
            background: @v2-primary-color;
        }
        .ant-picker-cell-in-view .ant-picker-cell-inner::before {
            border-color: @v2-primary-color;
        }
        .ant-picker-input > input {
            font-size: 16px;
        }
        .ant-picker-suffix {
            color: @v2-primary-color;
        }
    }
    .t-date-range-picker-dropdown {
        .ant-picker-header-view button:hover {
            color: @v2-primary-color;
        }
        .ant-picker-panel-container {
            border-radius: 16px;
        }
        .ant-picker-cell-in-view {
            &.ant-picker-cell-selected,
            &.ant-picker-cell-range-start,
            &.ant-picker-cell-range-end {
                .ant-picker-cell-inner {
                    background: @v2-primary-color;
                }
            }
            .ant-picker-cell-today {
                border-color: @v2-primary-color;
            }
            .ant-picker-cell-inner::before {
                border-color: @v2-primary-color;
            }
            &.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
            &.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
            &.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
            &.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
            &.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
                border-color: @v2-primary-color;
            }
            &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
            &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
            &.ant-picker-cell-in-range::before {
                background: @v2-primary-bg-color;
            }
            &.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
            &.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
                background: @v2-primary-bg-color;
            }
            &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
                border-radius: 6px 0 0 6px;
            }
            &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
                border-radius: 0 6px 6px 0;
            }
            &.ant-picker-cell-today .ant-picker-cell-inner::before {
                border-radius: 6px;
            }
        }
    }

    .t-popover-overlay {
        .ant-popover-arrow {
            .v2NormalTheme({
                background-color: #fff;
                border-color: #fff;
            });
            .v2DarkTheme({
                background-color: @v2-grey-1;
                border-color: @v2-grey-1;
            });
        }
        .ant-popover-inner {
            border-radius: @v2-radius-box-size;
            .v2Theme-background-color();
            .ant-popover-title {
                padding: 16px 16px 0;
                border-bottom: none;
                .v2Theme-color(@v2-grey-16, @v2-dark-10);
            }
            .ant-popover-inner-content {
                .v2Theme-color(@v2-grey-16, @v2-dark-10);
            }
        }
    }

    .t-alert {
        &,
        &.ant-alert-no-icon {
            padding: 12px;
        }
        border: none;
        border-radius: @v2-radius-box-size;
        &.ant-alert {
            &-warning {
                .v2Theme-background-color(@v2-warning-bg-color, @v2-warning-bg-color-dark);
                .ant-alert-message {
                    color: @v2-warning-text-color;
                }
            }
        }
    }

    .t-breadcrumb {
        & > span:not(:last-child) .ant-breadcrumb-link,
        & > span:first-child .ant-breadcrumb-link {
            &,
            & + .ant-breadcrumb-separator,
            & > .t-report-link:not(:hover) {
                color: @v2-grey-14;
            }
        }
        & > span:last-child {
            font-weight: 600;
            .v2NormalTheme({
                color: @v2-grey-16;
            });
            .v2DarkTheme({
                color: @v2-dark-10;
            });
        }
    }
    .t-form {
        :global {
            .ant-input {
                border-color: @v2-grey-18;
                .v2DarkTheme({
                    border-color: @v2-dark-13;
                });
            }
            .t-input-has-error {
                .ant-input {
                    border-color: @v2-danger-color;
                    .v2DarkTheme({
                        border-color: @v2-danger-color;
                    });
                }
            }
            .ant-form-item-has-error {
                .ant-select-selection-placeholder {
                    color: @v2-danger-color;
                    font-weight: bold;
                    opacity: 1;
                }
            }
            .ant-form-item-with-help {
                margin-bottom: 24px;
            }
            .t-select.size-large .ant-select-selector {
                font-size: 14px !important;
            }
            .ant-form-item-label > label {
                .v2Theme-color(@v2-grey-16, @v2-dark-10);
            }
        }
    }
    .t-form-item {
        &.t-form-item-label-hidden {
            .ant-form-item-label {
                display: none;
            }
        }
        .ant-form-item-with-help {
            margin-bottom: 24px;
        }
    }
    .t-typography-text {
        &.ant-typography-secondary {
            .v2Theme-color(@v2-grey-14, @v2-dark-12);
        }
    }
}

.tPassword,
:global(.t-password) {
    position: relative;

    .showPwd {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 16px;

        :global(.iconfont) {
            cursor: pointer;
        }
    }
}

.tDatePicker,
:global(.t-date-picker) {
    border-color: @v2-grey-18;
    border-radius: 10px;

    &,
    :global(.ant-picker-large) {
        padding: 12px 11px 12px;

        :global {
            .ant-picker-input > input {
                font-size: @v2-md-font-size;
            }
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;