@import "~less/colors.less";
@import "~less/v2/vars.less";

.content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    margin: 0;
}

.searchBar {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 36px;
    // margin: 10px 10px 4px 10px;
    margin: 5px 10px 4px 10px;
    padding: 0 12px;
    background: @windowBackground;
    border: @windowBackground solid 1px;
    border-radius: 20px;

    :global(html.theme-dark) & {
        background: @windowBackgroundDark;
        border: @windowBackgroundDark solid 1px;
    }
}

.searchBar:focus-within {
    border: @windowBackground solid 1px;

    :global(html.theme-dark) & {
        border: @windowBackgroundDark solid 1px;
    }
}

textarea:focus,
input:focus {
    outline: none;
}

.search {
    height: 36px;
    //color: @primaryColor;
    margin-right: 20px;
    font-size: 13px;
    background-color: transparent;
    border: 0;

    :global {
        .ant-input {
            border-radius: 20px;
        }

        .ant-input-clear-icon {
            color: @colorText;
        }
    }

    :global(html.theme-light) & {
        :global {
            .ant-input {
                background-color: @windowBackground;
                border-color: @windowBackground;
            }
        }
    }

    :global(html.theme-dark) & {
        :global {
            .ant-input {
                background-color: @windowBackgroundDark;
                border-color: @windowBackgroundDark;
                border-radius: 20px;
            }

            .ant-input-clear-icon {
                color: @colorText;
            }
        }
    }
}

.symbolLayout {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    font-family: "Roboto";
    background-color: @colorBackground;
    @apply bg-list-row;

    :global {
        .ant-input-affix-wrapper {
            padding: 6px 11px;
            border-radius: 8px;
            @apply border-divider;
        }

        .ReactVirtualized__List:focus {
            outline: none !important;
        }

        .ant-input-affix-wrapper-focused {
            @apply border-primary-300;
        }
    }
}

.search::placeholder {
    color: @colorText;
}

.tab {
    display: flex;
    flex-flow: row;
    height: 36px;
}

.splitLine {
    height: 2px;
    background: @windowBackground;

    :global(html.theme-dark) & {
        background: @windowBackgroundDark;
    }
}

.tabItem {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 4px;
    // :global(html.theme-dark) & {
    //   color: @publicTextColor
    // }
    color: @transfomModetext;
    font-size: 13px;
    // color: @colorText;
    //background: @bgcolormenu;
    // font-weight: bold;
    border: 0;
    cursor: pointer;

    :global(html.theme-dark) & {
        color: @colorText;
    }
}

.tabItem:hover {
    color: @colorAccent;
}

.select {
    color: @colorAccent;
    //background: @bgcolormenu;
}

// .shoucang_select {
//   color: @shoucangColor;
// }

// .shoucang_select:hover {
//   color: @shoucangColor  !important;
// }

// .shoucang_hover:hover {
//   color: @shoucangColor  !important;
// }

.icon {
    width: 14px;
    height: 14px;
    color: @colorText;
    cursor: pointer;
}

.selectCurrency {
    color: #ffffff;
}

.gutterExample .ant-row > div {
    background: transparent;
    border: 0;
}

.gutterRow {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.antRow {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.gutterBox {
    padding: 5px 0;
    color: #858ca3;
    font-size: 12px;
}

.list {
    width: 280px;
    margin: 0;
    padding: 0;
    overflow: auto;
    cursor: pointer;
}

.listContianer {
    position: relative;
    flex: 1;
    margin-top: 4px;
    margin-bottom: 12px;
}

.selebotmotext {
    margin-left: 36px;
    padding-top: 10px;
    color: #ffffff;
    font-size: 12px;
}

.isBusymbolLayout {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 10px;

    :global {
        .ant-input-affix-wrapper {
            margin-bottom: 5px;
        }

        .ant-menu {
            .ant-menu-item-selected {
                color: @colorAccent !important;
                border-bottom: 2px solid transparent !important;
            }

            .ant-menu-item:hover {
                color: @colorAccent !important;
            }
        }
    }
}

.symbolPickerBottom {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: @colorBackground;
    border-top: @colorBorder2;
    border-top-width: 1px;
    cursor: pointer;
    //box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.14);

    :global(html.theme-dark) & {
        background: @colorBackgroundDark;
    }
}

@media screen and (max-width: 767px) {
    .symbolLayout {
        width: 100%;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;