@import "~less/v2/vars.less";

.circleDw {
    :global {
        .t-navigator {
            .t-page-title {
                margin-bottom: 18px;
                padding-left: 0;
                font-size: 20px;
            }

            :global {
                .t-menu {
                    .iconfont {
                        font-size: 24px;
                    }
                }
            }

            .t-report-link {
                color: unset;
            }
        }
    }

    .layout {
        margin-top: 32px;
    }

    .wrapper {
        margin-bottom: 200px;
        padding: 1px 0;
        border-radius: 16px;
        .v2Theme-background-color(#fff, @v2-grey-15);
    }
}

.tips {
    width: 460px;
    margin: 0 auto;
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    border-radius: 12px;
    .v2Theme-color(@v2-warn-color, @v2-warn-color-dark);
    .v2Theme-background-color(@v2-warn-bg, @v2-warn-bg-dark);
}

.itemTips {
    width: 14px;
    height: 14px;
    margin-left: 6px;
}

.operation {
    width: fit-content;
    padding: 4px 10px;
    border: 1px solid @v2-grey-21;
    border-radius: 6px;
    cursor: pointer;
    .v2Theme-color(@v2-grey-16,@v2-dark-10);

    .v2DarkTheme({
        border-color: @v2-dark-15;
    });
}

.optWrapper {
    display: flex;
    justify-content: flex-end;
    min-width: 100px;
}
.table {
    :global(.ant-table-placeholder) {
        background-color: unset !important;
    }
}
.upload {
    :global {
        .ant-upload {
            @apply w-full mb-4;
        }
    }
}
.achHistoryBtn {
    font-weight: 400;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    padding: 3px 4px 3px 4px;
    border-color: #ff7028;
    color: #ff7028;
    background-color: unset;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;