@import "../../../../less/colors";
@import "~less/v2/vars.less";

.input {
    background-color: transparent;
    @apply px-0;
    &:global {
        & > input.ant-input {
            border: none;
        }
    }
    &::-webkit-input-placeholder {
        .v2DarkTheme({
            color: #626773 !important
        });
        .v2NormalTheme({
           color: #9ea6b8 !important;
  });
    }
}

.symbolSelect {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    .v2Theme-background-color(@colorBackground,@colorBackgroundDark);
    &:hover {
        cursor: pointer;
    }

    .text {
        margin-right: 6px;
        margin-left: 7px;
        font-weight: bold;
        font-size: 18px;
    }
    .v2MediaTablet({
        flex: 1;
        padding: 10px;
    });
}

.active {
    .v2Theme-background-color(#F2F3F5,#353942);
}

.inputWrapper {
    padding: 16px 16px 12px 14px;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;