@import "~less/variables.less";
@import "../../../../less/colors";
@import "~less/v2/vars.less";

body {
    margin: 0;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 4px 13px;
    overflow-y: hidden;
    //background: @colorBackground3;
    //border-bottom: 1px solid @colorDivider2;
    border-left: 3px solid transparent;

    //:global(html.theme-dark) & {
    //    background: @colorBackground3Dark;
    //    border-bottom: 1px solid @colorDivider2Dark;
    //}

    .symbolLayout {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: start;
        min-width: 0;
    }
}

.star {
    display: inline-block;
    align-self: flex-start;
    color: @colorText !important;
    font-size: 14px;

    //transform: scale(0.8);
    cursor: pointer;
}

.starhema {
    display: inline-block;
    align-self: flex-start;
    color: @shoucangColor !important;
    font-size: 14px;
    //transform: scale(0.8);
    cursor: pointer;

    svg {
        //width: 16px;
        //height: 16px;
    }
}

.topnamecode {
    padding-left: 5px;
    font-weight: bold;
}

.content {
    flex-flow: column;
    justify-content: center;
    margin-right: 6px;
    // display: flex;
    // flex: 1;
    margin-left: 6px;
}

.top {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 2px;
}

.name {
    //color: @textColor;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 1.5;
    // flex: 0 0 33.33%;
}

.name_sybol {
    //color: @textColor;
    width: 98px;
    margin-left: 5px;
    font-size: 12px;
}

.bottom {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    // flex: 0 0 33.33%;
}

.code {
    width: 98px;
    height: 14px;
    margin-left: 5px;
    line-height: 14px;
    text-align: left;
    border-radius: 1px;
}

.numbercode {
    height: 14px;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    border-radius: 1px;
}

.border {
    background-color: @bgDialog;
    border-radius: 2px;
}

.priceRaise {
    color: @textRaiseColor;
    font-size: 16px;
}

.priceFail {
    color: @textFailColor;
    font-size: 16px;
}

.bizimgs {
    width: 14px;
    // height: 14px;
    margin-left: 9px;

    img {
        width: 16px !important;
        height: 16px !important;
    }
}

.isnumpaddingr {
    padding-right: 10px;
}

.grader_container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.grader_item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3rem;
    padding: 0.75rem;
    color: #f0f0f3;
    font-size: 0.8em;
    background-color: #e91e63;
    border: 1px solid #fff;
    border-radius: 0.2rem;
}

.grader_item1 {
    grid-row-start: 4;
    grid-row-end: 1;
}

.exchangeIcon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: top;
}

.sybol_quote {
    display: inline-block;
    color: @singTextBackgroundDark;
    font-weight: 400;
    font-size: 10px;
    transform: scale(0.8);

    :global(html.theme-dark) & {
        color: @singTextBackground;
    }
}

.sybol_base {
    color: @singTextBackgroundDark;
    font-weight: bold;
    font-size: 14px;

    :global(html.theme-dark) & {
        color: @singTextBackground;
    }
}

.marketTable {
    th {
        color: @buTextColor !important;
        background-color: @buTableHaderBg !important;
        border-bottom: none !important;
    }

    td {
        color: @buTextColor !important;
        background-color: @buTableRowBg !important;
        border-bottom-color: @buTableRowBorderColor !important;
    }

    :global {
        .ant-table-placeholder {
            background-color: @buTableRowBg;
            border: none;
        }
    }
}

@keyframes breath {
    0% {
        opacity: 0.1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.1;
    }
}

@keyframes breath {
    0% {
        opacity: 0.1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.1;
    }
}

.tickerShow {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-left: 16px;
}

.center_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3px;
}

@animated: {
    /* IE10、Firefox and Opera，IE9以及更早的版本不支持 */
    animation-name: breath;

    /* Safari and Chrome */
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
};

.animatedContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    padding: 4px 0;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    border-radius: 6px;

    //@animated();
    //animation-play-state: paused;
}

.increaseText {
    background-color: @textRaiseColor;
}

.decreaseText {
    background-color: @textFailColor;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;