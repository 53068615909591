@import "~less/v2/vars.less";

.container {
    height: 60px;

    color: white;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    background: linear-gradient(90deg, #ff9938 0%, #ff7028 100%);

    @apply flex flex-row justify-center items-center;

    @apply px-12px;

    .button {
        @apply cursor-pointer;
        height: 36px;
        margin-left: 26px;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        border: 1px solid white;

        border-radius: 12px;
        @apply flex flex-row justify-center items-center flex-shrink-0;
        @apply px-12px;
    }

    @media screen and (max-width: @screen-md) {
        .button {
            margin-left: 12px;
        }
    }
}

.containerLrc {
    background: #4863e5;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;