@import "~less/v2/vars.less";

.container {
    width: 100%;
    max-width: 600px;
    min-height: 700px;
    margin: 0 auto;
    padding: 50px 20px;

    .title {
        margin: 0 auto 40px auto;
        font-weight: 600;
        font-size: @v2-h1-font-size;
        text-align: center;
        .v2Theme-color(@v2-grey-16,white);
    }

    .selectTitleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .selectTitle {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: @v2-md-font-size;
        .v2Theme-color(@v2-grey-16,@v2-dark-10);
    }

    .addText {
        color: @v2-primary-color;
        font-size: @v2-md-font-size;
        cursor: pointer;

        :global {
            .iconfont {
                margin-right: 2px;
            }
        }
    }
    .selectDes {
        margin-bottom: 16px;
        font-size: @v2-sm-font-size;
        .v2Theme-color(@v2-grey-14,@v2-dark-12);
    }

    .tips {
        font-size: 13px;
        .v2Theme-color(@v2-grey-14,@v2-dark-12);

        strong {
            .v2Theme-color(@v2-grey-16,white);

            &.remain {
                text-decoration: underline;
            }
        }
    }

    .addBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        margin-bottom: 16px;
        color: @v2-primary-color;
        font-size: 13px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        .v2Theme-background-color(@v2-table-odd-bg-color,@v2-dark-13);

        :global {
            .iconfont {
                margin-right: 2px;
            }
        }
    }

    .submit {
        display: block;
        margin: 50px auto auto;
        padding: 0 100px;
    }
}

.selectCardItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectCard {
    width: 100%;
    .deleteCard {
        display: none;
    }
}

.selectOptionText {
    display: flex;
    align-items: center;
}
.selectOptionSecurityIcon {
    width: 25px;
    height: 25px;
    margin-left: 5px;
}

.selectCardDrop {
    background-color: red;

    :global {
        .icon-icon_checked {
            display: none;
        }
    }
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Confirm {
        margin-top: 50px;
        padding: 0 100px;
        min-width: 120px;
    }
}

.cover {
    :global(.ant-modal-body) {
        padding: 24px 24px !important;
    }

    .loadingItem {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        :global(.iconfont) {
            margin-right: 6px;
        }
    }
    .successIcon {
        color: @v2-success-color;
    }
}

.tdsModal {
    :global(.ant-modal-body) {
        height: 600px;
        padding: 0 !important;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.callbackRes {
    margin: 16px;
    &Content {
        margin: auto;
    }

    &History {
        display: block;
        margin: 16px auto;
    }
}

.agree {
    margin-top: 16px;
}

.result3DSDesc {
    margin-top: 30px;
}

.tipsCoverItem {
    :global {
        .ant-col {
            position: unset;
        }
        .pi-form-item-control {
            position: unset;

            .pi-form-item-control-input {
                position: unset;
            }
        }
    }
}

.tipsCover {
    @apply absolute w-full h-full top-0 z-10 text-accent-sub;
    .v2Theme-background-color(rgba(255, 255, 255, 0.8),rgba(41, 43, 48, 0.77));
    display: flex;
    flex-direction: column;

    padding: 0 60px;
    backdrop-filter: blur(4px);

    .tipsTitle {
        margin-bottom: 24px;
    }

    button {
        display: block;
        margin: 30px auto 0 auto;
        padding: 0 60px;
    }
}
.tdsWaitModal {
    :global {
        .pi-modal-header,
        .pi-modal-body,
        .pi-modal-footer {
            @apply px-24px;
        }

        .pi-modal-footer {
            padding-bottom: 24px;
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;