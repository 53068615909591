@import "../colors";
@import "~antd/lib/style/color/colorPalette.less";

html.theme-dark {
    body {
        color: @colorTextAccentDark;
        background-color: @windowBackgroundDark;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: @colorTextAccentDark;
    }

    a {
        //color: @colorAccent;
        text-decoration: none;

        //&:hover {
        //    color: ~`colorPalette("@{colorAccent}", 4) `;
        //}
        //
        //&:active {
        //    color: ~`colorPalette("@{colorAccent}", 7) `;
        //}
    }

    ::-webkit-scrollbar-track {
        width: 8px;
        background: @colorBackgroundDark;
    }

    .pionex-color-text-accent {
        color: @colorTextAccentDark;
    }

    .pionex-color-text {
        color: @colorText;
    }

    .pionex-text-accent {
        color: @singTextBackground;
        font-weight: 500;
        font-size: 14px;
    }

    .pionex-text {
        color: @colorText;
        font-size: 12px;
    }

    .pionex-divider {
        background-color: @colorDivider2Dark;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-text-fill-color: @colorTextAccent;
    }
    .datepicker-navbar-btn {
        color: #f2f3f5 !important;
    }
}
