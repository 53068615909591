@import "~less/v2/vars.less";

.manualServiceWrap {
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 9999;
    height: 50px;
    overflow: hidden;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    background-color: rgba(36, 45, 71, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 44px;
    box-shadow: 0 4px 18px rgba(36, 45, 71, 0.466);
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background: rgba(60, 73, 107, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.1);
    }
    > div {
        display: inline-block;
        padding: 0 24px;
        white-space: nowrap;
    }
    .closeIcon {
        font-size: 24px;
        line-height: 45px;
    }
    .titleIcon {
        width: 20px;
        height: 20px;
        margin-right: 7px;
        vertical-align: middle;
        font-size: 22px;
    }
    .title {
        color: white;
        font-weight: 500;
        font-size: 16px;
        font-family: "PingFang SC";
    }
    .v2MediaDesktopMini({
        height: 35px;
        line-height: 31px;
        border-radius: 35px;
        > div {
            padding: 0 12px;
        }
        .closeIcon {
            line-height: 31px;
        }
        .titleIcon {
            margin-right: 5px;
            font-size: 18px;
        }
        .title {
            font-size: 14px;
        }
    });
    .v2MediaTablet({
        height: 44px;
        line-height: 42px;
        .titleIcon {
            margin-right: 0;
            vertical-align: middle;
        }
        .title {
            display: none;
        }
    });
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;