@import "../../less/colors";

.dropDownStyle {
    width: 100%;
    height: 100%;

    .overlay {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100%;
        // padding: 0 10px;
        font-size: 12px;
        white-space: nowrap;
        cursor: pointer;

        i {
            margin-left: 6px;
        }

        .text {
            margin-right: 5px;
            margin-left: 7px;
            font-weight: bold;
            font-size: 18px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 25px;
        margin-right: 10px;
        padding: 0 20px !important;
        color: #2c75ff !important;
        font-weight: 600;
        font-size: 14px;
        background: @helper_alert_hover_tab_light !important;
        border: none;
        border-radius: 15px;

        :global(html.theme-dark) & {
            color: #6d9fff !important;
            background: @helper_alert_hover_tab_dark !important;
        }
    }
}

.wrap {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.overlayContainer {
    top: 0 !important;
    bottom: 0;
    height: 100% !important;
}

.dropDownContainer {
    bottom: 0;
    height: 100% !important;
}

.arrow-down {
    transform: rotate(0deg);
    transition: transform 300ms linear;
}

.arrow-down.open {
    transform: rotate(180deg);
    transition: transform 300ms linear;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;