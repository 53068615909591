@v2-primary-color: #ff7028;
@v2-primary-color-2: #f6641b;
@v2-primary-color-hover: #ff884d;
@v2-primary-bg-color: #ffede3;
@v2-primary-bg-color-2: #f2f3f5;
@v2-dark-bg-color-2: #121519;
@v2-table-bg-color: #fcfcfc;
@v2-table-odd-bg-color: #fafbfc;
@v2-table-odd-bg-dark-color: #2a2d33;
@v2-table-bg-hover-color: rgba(255, 112, 40, 0.05);
@v2-danger-color: #f04848;
@v2-dropdown-color-disabled: #c9ccd1;
@v2-dropdown-bg-disabled: #f7f8fa;
@v2-guid-icon: #f2a000;
@v2-font-title: #919191;
@v2-font-content: #333333;
@v2-input-border: #d9d9d9;
@v2-params-bg: #fafafa;
@v2-params-bg-dark: #343640;
@v2-line-color: #f2f2f2;
@v2-line-color-dark: #353943;
@v2-popover-dark: #858ca3;
@v2-primary-disabled: #ffdccb;
@v2-primary-disabled-dark: #573423;

@v2-warning-bg-color: #fff4df;
@v2-warning-bg-color-dark: #403c35;
@v2-warning-text-color: #efa867;

@v2-kyc-lv-bg: #a22;

// 默认白色主题的灰色阶
@v2-grey-1: #282c33;
@v2-grey-2: #393c42;
@v2-grey-3: #4d525c;
@v2-grey-4: #6c717d;
@v2-grey-5: #8a909f;
@v2-grey-6: #c7cbd5;
@v2-grey-7: #dbdee6;
@v2-grey-8: #eceef2;
@v2-grey-9: #f6f7f9;
@v2-grey-10: #969dad;
@v2-grey-11: #000000;
@v2-grey-12: #6c727d;
@v2-grey-13: #828894;
@v2-grey-14: #9fa7b8;
@v2-grey-15: #27292e;
@v2-grey-16: #292c33;
@v2-grey-17: #c9ccd1;
@v2-grey-18: #f0f0f2;
@v2-grey-19: #6b7280;
@v2-grey-20: #7c8494;
@v2-grey-21: #d7dae0;
@v2-grey-22: #a1abb8;
@v2-grey-23: #b94d00;
@v2-grey-24: #aeb2bd;

// 暗色主题的黑色阶
@v2-dark-1: #1d2025;
@v2-dark-2: #282c33;
@v2-dark-3: #31353e;
@v2-dark-4: #3a3f49;
@v2-dark-5: #4c525d;
@v2-dark-6: #7a808b;
@v2-dark-7: #aaafbb;
@v2-dark-8: #c7cbd5;
@v2-dark-9: #eceef2;
@v2-dark-10: #f4f6fa;
@v2-dark-11: #404652;
@v2-dark-12: #707580;
@v2-dark-13: #353942;
@v2-dark-14: #313339;
@v2-dark-15: #414752;
@v2-dark-18: #303542;
@v2-dark-19: #121519;

@v2-border-color: #f0f0f0;
@v2-border-dark-color: #393e47;
@v2-warn-color: #e6a500;
@v2-warn-color-dark: #ffb700;
@v2-warn-bg: #fff8e3;
@v2-warn-bg-dark: #4c442b;
@v2-upload-bg: rgba(255, 112, 40, 0.02);
@v2-upload-danger-bg: rgba(240, 72, 72, 0.15);
@v2-upload-img-bg: rgba(0, 0, 0, 0.4);
@v2-success-color: #00b070;
@v2-unsafe-color: #9ca7c5;
@v2-blue-color: #80b0f9;
@v2-green-color: #79d36b;

@v2-sm-height: 32px;
@v2-md-height: 36px;
@v2-lg-height: 48px;

@v2-radius-item-size: 10px;
@v2-radius-box-size: 12px;
@v2-box-radius-size: 12px;

@v2-form-item-space: 16px;

// 默认文案字体大小
@v2-max-content-with: 1400px;
@v2-content-font-size: 15px;
@v2-content-tip-font-size: 14px;
@v2-h1-font-size: 28px;
@v2-h2-font-size: 24px;
@v2-h3-font-size: 20px;
@v2-h4-font-size: 18px;
@v2-h5-font-size: 16px;

@v2-lg-font-size: 16px;
@v2-md-font-size: 14px;
@v2-sm-font-size: 12px;

.v2FontSize-lg(@important: false, @increase: 0, @rests: {}) {
    font-size: @v2-lg-font-size + @increase if(@important, e("!important"));
    @rests();
}
.v2FontSize-md(@important: false, @increase: 0, @rests: {}) {
    font-size: @v2-lg-font-size + @increase if(@important, e("!important"));
    @rests();
}
.v2FontSize-sm(@important: false, @increase: 0, @rests: {}) {
    font-size: @v2-lg-font-size + @increase if(@important, e("!important"));
    @rests();
}

.v2Height-lg(@important: false, @increase: 0, @rests: {}) {
    height: @v2-lg-height + @increase if(@important, e("!important"));
    @rests();
}
.v2Height-md(@important: false, @increase: 0, @rests: {}) {
    height: @v2-md-height + @increase if(@important, e("!important"));
    @rests();
}
.v2Height-sm(@important: false, @increase: 0, @rests: {}) {
    height: @v2-sm-height + @increase if(@important, e("!important"));
    @rests();
}

.v2FormCompSize-lg(@important: false, @increase: 0, @rests: {}) {
    .v2Height-lg(@important, @increase);
    .v2FontSize-lg(@important, @increase);
    @rests();
}
.v2FormCompSize-md(@important: false, @increase: 0, @rests: {}) {
    .v2Height-md(@important, @increase);
    .v2FontSize-md(@important, @increase);
    @rests();
}
.v2FormCompSize-sm(@important: false, @increase: 0, @rests: {}) {
    .v2Height-sm(@important, @increase);
    .v2FontSize-sm(@important, @increase);
    @rests();
}

.v2FormCompSize (@default, @large, @small) {
    &,
    &.size-middle {
        @default();
    }
    &.size-large {
        @large();
    }
    &.size-small {
        @small();
    }
}

.v2FormCompFocusBoxShadow(@important: false) {
    box-shadow: 0 0 0 2px @v2-table-bg-hover-color if(@important, e("!important"));
}

.transitionAll(@timing: 0.2s, @target: all, @curve: ease-in-out) {
    transition: @target @timing @curve;
}

.v2DarkTheme(@context) {
    :global(html.theme-dark) &,
    :global(html.dark) & {
        @context();
    }
}

.v2NormalTheme(@context) {
    :global(html.theme-light) &,
    :global(html.light) & {
        @context();
    }
}

.v2ConstantTheme(@context) {
    .v2NormalTheme(@context);
    .v2DarkTheme(@context);
}

.v2Theme-background-color(@default: #ffffff, @black: @v2-grey-1) {
    .v2NormalTheme({
        background-color: @default
    });
    .v2DarkTheme({
        background-color: @black;
    });
}

.v2Theme-color(@default: #fff, @black: @v2-grey-1) {
    .v2NormalTheme({
        color: @default;
    });
    .v2DarkTheme({
      color: @black;
    });
}
.v2Theme-border-color(@default: #fff, @black: @v2-grey-1) {
    .v2NormalTheme({
        border-color: @default;
    });
    .v2DarkTheme({
        border-color: @black;
    });
}
// Mobile screen
@screen-xs: 480px;
// Small screen / tablet
@screen-sm: 576px;
// Medium screen / desktop
@screen-md: 768px;
// Large screen / wide desktop
@screen-lg: 992px;
// Extra large screen / full hd
@screen-xl: 1200px;
// Extra extra large screen / large desktop
@screen-xxl: 1600px;
@screen-xs-max: (@screen-sm - 1px);
@screen-sm-max: (@screen-md - 1px);
@screen-md-max: (@screen-lg - 1px);
@screen-lg-max: (@screen-xl - 1px);
@screen-xl-max: (@screen-xxl - 1px);

// xs <-> xsMax <-> sm <-> smMax <-> md <-> mdMax <-> lg <-> lgMax <-> xl <-> xlMax <-> xxl <-> xxlMax
// ****************************************************************
//        Less函数处理方法基本与 useMedia 函数处理方法保持一致
// ****************************************************************
// Mobile
.v2MediaMinXS(@context) {
    @media screen and (min-width: @screen-xs) {
        @context();
    }
}
.v2MediaMaxXS(@context) {
    @media screen and (max-width: @screen-xs-max) {
        @context();
    }
}
.v2MediaMobile(@context) {
    .v2MediaMaxXS(@context);
}

// tablet
.v2MediaMinSM(@context) {
    @media screen and (min-width: @screen-sm) {
        @context();
    }
}
.v2MediaMaxSM(@context) {
    @media screen and (max-width: @screen-sm-max) {
        @context();
    }
}
.v2MediaTablet(@context) {
    .v2MediaMaxSM(@context);
}

// desktop mini
.v2MediaMinMD(@context) {
    @media screen and (min-width: @screen-md) {
        @context();
    }
}
.v2MediaMaxMD(@context) {
    @media screen and (max-width: @screen-md-max) {
        @context();
    }
}
.v2MediaDesktopMini(@context) {
    .v2MediaMaxMD(@context);
}

// desktop wide
.v2MediaMinLG(@context) {
    @media screen and (min-width: @screen-lg) {
        @context();
    }
}
.v2MediaMaxLG(@context) {
    @media screen and (max-width: @screen-lg-max) {
        @context();
    }
}
.v2MediaDesktopWide(@context) {
    .v2MediaMaxLG(@context);
}

// desktop HD
.v2MediaMinXL(@context) {
    @media screen and (min-width: @screen-xl) {
        @context();
    }
}
.v2MediaMaxXL(@context) {
    @media screen and (max-width: @screen-xl-max) {
        @context();
    }
}
.v2MediaDesktopHD(@context) {
    .v2MediaMaxXL(@context);
}

// large desktop
.v2MediaMinXXL(@context) {
    @media screen and (min-width: @screen-xxl) {
        @context();
    }
}
.v2MediaDesktopLarge(@context) {
    .v2MediaMinXXL(@context);
}

/**
* @deprecated 请不要继续使用该方法了
**/
.media_MD_LG_XL( @md: {}, @lg: {}, @xl: {}) {
    .v2MediaMinMD({
        @md();
    });
    .v2MediaMinLG({
        @lg();
    });
    .v2MediaMinXL({
        @xl();
    });
}

// 给v2首页用的韩文字体
@home-ko-font-family: "Jeremy", "Noto Sans KR", "sans-serif", "AppleSDGothicNeo-Regular", "Malgun Gothic", "Dotum";
// v2 table radius
@table-radius: 16px;
