@import "../../../less/colors";
@import "~less/v2/vars.less";

@contentwidth: 1180px;
@markOrange: #ff651e;
@fontNormalBold: bold;
@fontMedium: 500;

.contentwidth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 390px;
    margin-top: 70px;
    margin-right: auto;
    margin-left: auto;
    > img {
        width: 120px;
        height: 120px;
    }
    .auth {
        margin-top: 19px;
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
        @apply text-accent;
    }
    .content {
        display: flex;
        gap: 8px;
        width: 100%;
        .downloadItem {
            &:hover {
                @apply bg-divider;
            }
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            height: 66px;
            padding: 16px 20px 16px 17px;
            border-radius: 12px;
            @apply bg-bg cursor-pointer  dark:bg-drop-down;
            .left {
                display: flex;
                align-items: center;
                > img {
                    width: 22px;
                    height: 24px;
                    margin-right: 10px;
                }
                .dec {
                    width: 89px;
                    font-size: 12px;
                    line-height: 17px;
                    @apply text-accent;
                }
                .mdec {
                    margin-right: 20px;
                    font-size: 12px;
                    line-height: 17px;
                    @apply text-accent;
                }
            }
        }
    }
    .downloadTips {
        align-items: center;
        width: 100%;
        margin-top: 16px;
        padding: 16px;
        border-radius: 12px;
        @apply border border-solid border-divider flex;
        .description {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            .title {
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                @apply text-accent;
            }

            .type {
                margin-top: 5px;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                @apply text-secondary;
            }
            .tip {
                margin-top: 7px;
                font-size: 12px;
                line-height: 14px;
                @apply text-accent;
            }
        }
    }
}

@media screen and (max-width: @screen-md) {
    .contentwidth {
        width: 100%;
        padding: 0 18px;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;