@import "../../../../less/colors";

.list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.listContainer {
    position: relative;
    flex: 1;

    :global {
        .ant-list {
            position: absolute;
        }
    }
}

.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 32px;
    padding: 0 16px 0 16px;
    //background-color: @colorBackground3;
    //box-shadow: inset 0 -1px 0 0 @colorBackground3;
    //
    //:global(html.theme-dark) & {
    //    background-color: @colorBackground3Dark;
    //    box-shadow: inset 0 -1px 0 0 @colorBackground3Dark;
    //}
}

.noData {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 46px 0;
}

.noDataLabel {
    margin-top: 12px;
    color: @colorText;
    font-size: 14px;
}

.headertext {
    color: @colorText;
    font-size: 12px;
}

.textright {
    text-align: center;
}

.headerItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    cursor: pointer;

    @apply text-accent-sub;
}
.list {
    // &::-webkit-scrollbar {
    //     display: none;
    // }
}

.headerTextActive {
    color: @colorTextAccent;

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;