@import "~less/v2/vars.less";

.addressBookOverlay {
    top: 35px;
    left: -5px;
    width: 100%;
    .v2Theme-background-color;
    .addressBookMenuTitle {
        height: 44px;
        padding: 9px 6px;
        color: @v2-grey-4;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        :global {
            .t-report-link {
                margin-left: 10px;
                color: @v2-primary-color !important;
            }
            .iconfont {
                display: inline-block;
                margin-left: -3px;
                vertical-align: -2px;
            }
        }
        .openOthers {
            transform: rotate(180deg);
        }
    }
    .addressBookAddNew {
        height: 60;
        padding: 16px;
        text-align: center;
        :global {
            .t-report-link {
                color: @v2-primary-color !important;
            }
        }
    }
    .addressBookMenuEmpty {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        min-height: 92px;
        padding: 16px;
        color: @v2-grey-5;
    }
    .addressBookMenuContent {
        max-height: 470px;
        padding: 0 10px 10px;
        overflow-y: auto;
        :global {
            .ant-menu-inline,
            .ant-menu-vertical,
            .ant-menu-vertical-left {
                border: none;
            }
        }
    }
    .addressBookMenuItem {
        height: auto;
        margin: 0;
        padding: 12px 20px;
        line-height: 20px;
        .v2Theme-color(@v2-grey-1, @v2-grey-9);
        &:hover {
            .v2Theme-color(@v2-grey-1, @v2-grey-9);
            .v2Theme-background-color(@v2-grey-9, #2d3036);
        }
        .addressTag {
            box-sizing: border-box;
            margin-left: 5px;
            padding: 2px 8px;
            color: @v2-grey-5;
            font-size: 12px;
            border: 1px solid @v2-grey-5;
            border-radius: 4px;
        }
        div:first-child {
            margin-bottom: 4px;
        }
    }
}
.addressBookBtn {
    display: inline-block;
    padding: 7px 10px 6px;
    color: rgb(109 159 255) !important;
    font-size: 14px;
}
:global(html.theme-dark) {
    background-color: #000000;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;