@import "~less/variables.less";
@import "../../../../less/colors.less";

.pieView {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-left: 50px;
}

.pieLeftNoneView {
    display: flex;
    max-width: 100px;
    height: 100%;
    background-color: @headerbackground;
}

.pieListView {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-right: 40px;
    margin-left: 50px;
}

.pieListItemView {
    //min-width: 274px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    height: 21px;
    //oh 稍后会适配移动端
    padding-right: 30px;
}

.pieListItemNameView {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.pieListItemNameImgView {
    display: flex;
    width: 11px;
    height: 11px;
    margin-right: 15px;
    border-radius: 100%;
}

.pieListItemValueView {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
}

.canvasCaler {
    color: red;

    :global(html.theme-dark) & {
        color: pink;
    }
}

.loadingView {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
    .pieListView {
        margin-right: 0px;
        margin-left: 10px;
    }
    .pieView {
        margin-left: 0px;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;