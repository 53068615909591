.formContainer {
    box-shadow: 0px 8px 20px rgba(1, 0, 41, 0.1);
    border-radius: 20px;
    padding: 36px;
    @apply bg-white border-bg border-solid border dark:shadow-none dark:bg-drop-down dark:border-drop-down mobile:mb-16px mobile:p-24px;
}

.slogan {
    //background-image: url(../assets/cover.png);
    //background-size: contain;
    padding-top: 80px;
    //background-repeat: no-repeat;
}

.submitBtn {
    background: linear-gradient(270.24deg, #fb9600 14.4%, #ff6928 89.04%) !important;
}
.selectBtn {
    border-radius: 30px;
    width: 132px;
    font-size: 20px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.mobileBtn {
    border-radius: 37px;
    border-style: solid;
    border-width: 0.5px;
    padding: 6px 12px 6px 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;