@import "~less/v2/vars.less";
.ocupy-modal {
    :global {
        .pi-modal-body {
            max-height: 320px;
            overflow-y: auto;
        }

        .pi-modal-footer {
            button:first-child {
                display: none;
            }
        }
    }
    .list {
        &-item {
            position: relative;
            padding: 12px;
            font-size: 12px;
            line-height: 17px;
            border-radius: 12px;
            :global {
                .iconfont {
                    position: absolute;
                    top: 22px;
                    right: 16px;
                    font-size: 20px;
                    .v2Theme-color(#c9ccd1, #454b57);
                }
            }
            &:hover {
                cursor: pointer;
                .v2Theme-background-color(#fbfbfc, #2d3036);
            }
            &-title {
                margin-bottom: 4px;
                .v2Theme-color(@v2-grey-1, @v2-grey-9);
            }
            &-content {
                color: @v2-grey-14;
            }
            &:not(:last-child) {
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    content: "";
                    @apply bg-divider;
                }
            }
        }
    }
    .empty {
        color: @v2-grey-5;
        text-align: left;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;