@import "~less/v2/vars.less";

.addAddressModal {
    min-height: 304px;
    .memo {
        display: flex;
        :global {
            .ant-form-item:first-child {
                flex: 1 1 0%;
            }
            .ant-form-item:last-child {
                margin-left: 15px;
            }
        }
    }
    .addAddressChainText {
        margin-bottom: 8px;
        .v2Theme-color(#292c33, #F4F6FA);
    }
    .notUseMemo {
        padding-top: 14px;
    }
    :global {
        .ant-form-item-explain {
            div {
                display: none;
            }
        }
        .ant-radio-button-wrapper {
            width: initial;
            border-radius: 45px;
            &-checked {
                color: #ffffff;
                &,
                &:hover {
                    .v2Theme-background-color(@v2-primary-color, @v2-primary-color);
                }
            }
            &:not(&-disabled):hover,
            &-checked:not(&-disabled):first-child {
                .v2DarkTheme({
                    color: #fff;
                });
            }
        }
        .ant-row {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .ant-select-selection-item {
            img {
                vertical-align: -2px !important;
            }
        }
    }
}

.coinOptionImg {
    vertical-align: -1.5px !important;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;