@import "~less/v2/vars.less";

.overview {
    width: 50%;

    .v2MediaMaxLG({
        width: 100%;
    });
    &Options {
        margin-bottom: 16px;
        :global {
            .t-button {
                margin-right: 12px;
                border-radius: 12px;
                .v2Theme-color(@v2-grey-16, @v2-dark-10) !important;
                .v2Theme-background-color(#fff, @v2-grey-15) !important;
            }
        }
    }
    &Total {
        border-radius: 16px;
        .v2Theme-background-color(#fff, @v2-grey-15);
        &Info {
            &Today {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
            }
            padding: 20px 20px 0;
            &Title {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                .v2Theme-color(@v2-grey-16, @v2-dark-10);
            }
            &Sum {
                .totalBalance {
                    margin-right: 5px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    .v2Theme-color(@v2-grey-16, @v2-dark-10);
                }
                .totalBTC {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    .v2Theme-color(@v2-grey-14, @v2-dark-5);
                }
            }
        }
    }
    &Separate {
        padding: 20px 20px 30px;
        border-radius: 16px;
        .v2Theme-background-color(#fff, @v2-grey-15);
        &Title {
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            .v2Theme-color(@v2-grey-16, @v2-dark-10);
        }
        &Detail {
            display: flex;
        }
        &Chart {
            flex-basis: 40%;
        }
        &Data {
            display: flex;
            flex-basis: 60%;
            align-items: center;
            &Item {
                flex: 1;
                height: 75px;
                padding-left: 10px;
                border-left: 4px solid #000000;
                .title {
                    font-size: 14px;
                    line-height: 20px;
                }
                .sum {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 34px;
                    .v2Theme-color(@v2-grey-16, @v2-dark-10);
                }
                .profit {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
    .periodBtn {
        padding: 14px 20px 20px;
        :global {
            .ant-radio-button-wrapper {
                width: 62px;
                padding: 0 11px;
                border-color: transparent;
                border-radius: 46px;
                .v2DarkTheme({
                    border-color: transparent;
                    background: transparent;
                });
                &-checked {
                    background: transparent;
                    border-color: @v2-primary-color;
                    .v2Theme-color(@v2-primary-color, @v2-primary-color) !important;
                    .v2DarkTheme({
                        border-color: @v2-primary-color !important;
                    });
                }
                &:hover {
                    border-color: @v2-primary-color;
                    .v2DarkTheme({
                        border-color: @v2-primary-color;
                    });
                }
            }
        }
    }
    .periodProfit {
        float: right;
        margin-right: 100px;
        font-size: 14px;
        line-height: 32px;
        .v2MediaTablet({
            margin-top: 20px;
            width: 100%;
            text-align: center;
            float: none;
        });
        span:last-child {
            margin-left: 5px;
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;