@import "../colors";

.ant-tabs-dropdown {
    z-index: 9999;
}
@media screen and (min-width: 320px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-tree-treenode-switcher-open {
        li {
            span {
                .ant-tree-node-content-wrapper:hover::before {
                    background-color: transparent;
                }
            }
        }
    }

    .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
    }

    .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        display: none;
    }

    // .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before, .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before
}

@media screen and (min-width: 360px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-tree-treenode-switcher-open {
        li {
            span {
                .ant-tree-node-content-wrapper:hover::before {
                    background-color: transparent;
                }
            }
        }
    }

    .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
    }

    .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        display: none;
    }
}

@media screen and (min-width: 375px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-tree-treenode-switcher-open {
        li {
            span {
                .ant-tree-node-content-wrapper:hover::before {
                    background-color: transparent;
                }
            }
        }
    }

    .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
    }

    .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        display: none;
    }
}

@media screen and (min-width: 400px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-tree-treenode-switcher-open {
        li {
            span {
                .ant-tree-node-content-wrapper:hover::before {
                    background-color: transparent;
                }
            }
        }
    }

    .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
    }

    .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        display: none;
    }
}

// media 575
@media screen and (min-width: 575.98px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-tree-treenode-switcher-open {
        li {
            span {
                .ant-tree-node-content-wrapper:hover::before {
                    background-color: transparent;
                }
            }
        }
    }

    .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
    }

    .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        display: none;
    }
}

// media 767
@media screen and (min-width: 767.98px) {
    .ant-modal-centered .ant-modal {
        content: "";
    }

    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-tree-treenode-switcher-open {
        li {
            span {
                .ant-tree-node-content-wrapper:hover::before {
                    background-color: transparent;
                }
            }
        }
    }

    .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
    }

    .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: transparent;
    }

    .animated {
        width: 100%;
    }

    .ant-modal-body {
        font-family: "Roboto";
    }
}

//  // media 991
@media screen and (min-width: 991.98px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 62%;
    }

    .ant-tree-treenode-switcher-open {
        li {
            span {
                .ant-tree-node-content-wrapper:hover::before {
                    background-color: transparent;
                }
            }
        }
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
    }
}

// media 1199
@media screen and (min-width: 1199.98px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }
}

@media screen and (min-width: 320px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            padding: 0;
        }
    }

    .ant-select-arrow {
        right: 2px;
    }
}

@media screen and (min-width: 360px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            padding: 0;
        }
    }

    .ant-select-arrow {
        right: 2px;
    }
}

@media screen and (min-width: 375px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            padding: 0;
        }
    }

    .ant-select-arrow {
        right: 2px;
    }
}

@media screen and (min-width: 400px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            padding: 0;
        }
    }

    .ant-select-arrow {
        right: 2px;
    }
}

// media 575
@media screen and (min-width: 575.98px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            padding: 0;
        }
    }

    .ant-select-arrow {
        right: 2px;
    }
}

// media 767
@media screen and (min-width: 767.98px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            padding: 0;
        }
    }

    .ant-select-arrow {
        right: 2px;
    }
}

//  // media 991
@media screen and (min-width: 991.98px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            padding-right: 20px;
        }
    }

    .ant-select-arrow {
        right: 10px;
    }
}

// media 1199
@media screen and (min-width: 1199.98px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: 100%;
    }

    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            padding-right: 20px;
        }
    }

    .ant-select-arrow {
        right: 10px;
    }
}

.gutter-box {
    padding: 2px 0;
}

.ant-btn-sm {
    font-size: 12px;
}

.ant-slider-track {
    height: 6px !important;
}

.ant-slider-rail {
    height: 6px !important;
}

.ant-notification-notice-message {
    margin-left: 12px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 12px;
}

// .ant-select-dropdown-menu-item-active {
//   background-color: unset !important;
// }

.gutter-example .ant-row > div {
    background: transparent;
    border: 0;
}

.gutter-box {
    padding: 5px 0;
}

.ant-tabs-nav .ant-tabs-tab {
    margin-right: 12px !important;
    padding: 6px 0;
}

// slider
.ant-tooltip-inner {
    min-height: unset !important;
    padding: 0 6px !important;
    background-color: @colorAccent2 !important;
}

.ant-slider-dot {
    display: none;
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
    border-top-color: @colorAccent2 !important;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    border-bottom-color: @colorAccent2 !important;
}

.ant-slider-handle {
    background-color: @colorAccent !important;
    border-color: @colorAccent !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
    box-shadow: unset !important;
}

.ant-slider:hover .ant-slider-rail {
    background-color: transparent;
}

.ant-pagination {
    text-align: center;
}

.ant-table-pagination.ant-pagination {
    float: unset;
}

.ant-divider-horizontal {
    margin: 0;
}

.ant-btn-primary {
    text-shadow: none;
}

.ant-tree-node-content-wrapper {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-tabs-bar {
    border-bottom: none;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    opacity: unset;
}

.ant-select-item-option-selected {
    color: unset;

    &:not(.ant-select-item-option-disabled) {
        background-color: unset;
    }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #666666;
}

.ant-modal-root {
    .ant-modal-mask {
        z-index: 1050;
    }

    .ant-modal-wrap {
        z-index: 1101;
    }
}

.ant-dropdown {
    z-index: 1102;
}

.ant-message {
    z-index: 1102;
}

.ant-btn {
    .ant-btn-loading-icon {
        .anticon-loading {
            animation: none;

            svg {
                animation: loadingCircle 1s infinite linear;
            }
        }
    }
}

.pi-spin {
    .anticon-loading.anticon-spin {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
