@import "~less/v2/vars.less";
@import "~less/colors.less";
.con {
    width: 360px;
    height: 556px;
    padding: 24px;
    font-family: "Roboto";
    border-radius: 12px;
    @apply bg-drop-down;

    .top {
        border-bottom: 1px solid;
        @apply border-divider;
        .title {
            @apply text-xl  text-accent mb-16px;
            font-weight: 600;
            line-height: 115.5%;
        }
    }
}

.layout_con {
    @apply flex  flex-wrap justify-between;

    width: 100%;
    .radio {
        position: absolute;
        left: -9999em;
    }
    .radio:checked ~ div {
        border: 1px solid #ff6500;
        @apply bg-list-row;

        svg {
            @apply block;
            top: 6px;
            right: 6px;
        }
    }
    .layout {
        width: 150px;
        height: 140px;
        border-radius: 12px;
        @apply relative block cursor-pointer;
        .v2Theme-background-color(#F2F3F5,#1e2229);
        > div {
            @apply w-full h-full p-12px;
            border: 1px solid transparent;
            border-radius: 12px;
        }
        &:nth-child(3) {
            @apply mt-12px;
        }
        &:nth-child(4) {
            @apply mt-12px;
        }
        svg {
            @apply absolute hidden;
        }
        p {
            line-height: 14px;
        }
        .auto {
            .scheme {
                > div {
                    @apply w-full bg-divider;
                    // .v2Theme-background-color(rgb(221,224,233),#C5CBD6);

                    height: 20px;
                    margin-bottom: 4px;
                    border-radius: 4px;
                    &:nth-child(1) {
                        @apply w-1/3;
                    }
                    &:nth-child(2) {
                        @apply w-2/3;
                    }
                }
            }
        }
    }
}

.dec {
    line-height: 115%;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;