@import "../../less/colors.less";
@import "~less/v2/vars.less";

.formItem {
    :global {
        .ant-form-explain {
            margin-top: 4px;
        }
    }
}

.inputBeauty {
    border-radius: 6px;
}
.inputContainer {
    // height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    // border-bottom: 1px solid @myBotContentBg;
    padding-bottom: 1px;
    color: @colorTextAccent;
    font-size: 14px;
    // border-radius: 8px;
    background-color: @windowBackground;
    border-radius: 8px;

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
        background-color: @windowBackgroundDark;
        // border-bottom: 1px solid @myBotContentBgDark;
    }

    :global {
        .ant-input,
        .ant-select {
            // padding-top: 30px;
            // padding-bottom: 30px;

            display: flex;
            flex-direction: row;
            align-items: center;
            height: 60px;
            // background-color: transparent !important;
            border: none !important;
            border-radius: initial;
        }

        .ant-select .ant-select-selection {
            width: 100%;
        }

        // .ant-input-password {
        //   span[class="ant-input-suffix"] {
        //     top: 42%;
        //   }
        // }
    }
}

.accountCodeSelectContainer {
    width: 100px;

    :global(.ant-select-selection) {
        background-color: transparent;
        border: none;
    }
}

.sendButton {
    width: 120px;
    margin-right: 10px;
    border-color: @colorText;

    .sendBtn {
        width: unset !important;
        height: unset;
        padding: 6px 9px;
        font-weight: 400;
        font-size: 12px;
        border: unset;
        border-radius: 9px;
        // .v2Theme-background-color(@button_background,@button_background_dark);
        // .v2Theme-color(#292c33,#F4F6FA) !important;

        &:hover,
        &:focus {
            color: @v2-primary-color !important;
            background: #f0f0f2;
            border: unset !important;
        }

        // &:disabled,
        // &:global(.disabled) {
        //     border: unset !important;
        //     // .v2Theme-background-color(@button_background,@button_background_dark) !important;
        //     // .v2Theme-color(@v2-grey-14,@v2-dark-5) !important;
        // }
    }
}

.verticalLine {
    width: 2px;
    height: 17px;
    margin-right: 15px;
    background-color: @colorDivider2;

    :global(html.theme-dark) & {
        background-color: @colorDivider2Dark;
    }
}

.input-beauty {
    height: 46px !important;
    font-size: 14px !important;
    border-color: @button_background !important;
    border-radius: 10px !important;

    .v2DarkTheme {
        border-color: @button_background_dark !important;
    }
}
.shadow-input-style {
    font-size: 14px !important;
}

.shadow-text-style {
    background-color: #ffffff !important;
    .v2DarkTheme({
        background-color: @v2-grey-1 !important;
    });
}

.code-input {
    width: 100%;
    height: 100%;

    .editWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 15px;

        :global {
            .ant-checkbox-inner {
                background-color: transparent;
                border: 1px solid @colorTextAccent;

                :global(html.theme-dark) & {
                    border: 1px solid @colorTextAccentDark;
                }
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #1890ff !important;
                border: 1px solid #2c75ff !important;
            }

            .ant-checkbox-input:focus + .ant-checkbox-inner {
                border: 1px solid @colorTextAccent;

                :global(html.theme-dark) & {
                    border: 1px solid @colorTextAccentDark;
                }
            }
        }

        .sendButton {
            margin-top: 0;

            :global {
                .ant-btn {
                    width: unset !important;
                }
            }
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;