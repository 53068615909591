@import "../../../../less/colors.less";
@import "~less/v2/vars.less";
.container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 700px;
    font-family: "PingFang SC";
    .v2Theme-color(@v2-grey-16, @v2-primary-bg-color-2);
    .v2Theme-background-color(#fff,#23272e);
    .remain_num {
        color: #ff7028;
    }
    .loading {
        position: absolute;
        z-index: 100;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 41, 46, 0.8) !important;
        > div {
            margin-top: 100px;
        }
    }
    .tips {
        .v2Theme-color(#9ea6b8, #AEB2BD);
        margin: 0;
        font-weight: 400;
        font-size: 12px;
    }
    .content {
        display: flex;
        flex-direction: column;
        width: 460px;
        .title {
            margin: 50px 0 30px 0;
            font-weight: 600;
            font-size: 26px;
            line-height: 39px;
        }
        .warn {
            .v2Theme-color(#292c33, #F2F3F5);
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            margin: 30px 0 37px 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 48px;
            border-radius: 46px;
        }
        .record {
            .has_expire {
                color: red;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
            }
            .expire_time {
                margin-top: 8px;
                color: #00b070;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
            }
            .recordTitle {
                // color: #292c33;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                // .v2Theme-color(#9EA6B8, #AEB2BD);
            }

            .recordInfo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 46px;
                margin-top: 12px;
                padding: 0 12px;
                font-weight: 400;
                font-size: 14px;
                line-height: 46px;
                background: #ffefd1;
                border-radius: 8px;
                .v2Theme-background-color(@v2-primary-bg-color-2,#353942);
            }
            .recordDownload {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 28px;
                line-height: 28px;
                text-align: center;
                border: none;
                border-radius: 46px;
                //.v2Theme-background-color(#C5CBD6,#dadfe9);
            }

            .recordDownloadSuccess {
                .v2Theme-background-color(#00b070,#00b070);
            }

            .recordInfoPeng {
                .v2Theme-background-color(#FFEFD1,rgba(155, 107, 13, 0.33));
                .v2Theme-color(#FFA858, #DD8737);
            }

            .recordinfoexpire {
                .v2Theme-background-color(#C5CBD6,#dadfe9);
            }
            .recordInfoSuccess {
                .v2NormalTheme({
                    background-color:  rgba(0,176,112,0.1);
                });

                .v2DarkTheme({
                    background-color:  rgba(0,176,112,0.3);
                });
            }
        }
    }
}

.modalCon {
    width: 100%;
    .info {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        font-size: 14px;
        line-height: 20px;
        .v2Theme-color(#292c33, #F2F3F5);
    }
    .warnInfo {
        font-size: 12px;
        .v2Theme-color(#9ea6b8, #626773);
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;