@import "../../../less/colors.less";

.PerGrid_padding {
    padding: 0 20px 0 20px;
}

.dowBg_height {
    height: 100%;
}

.dolond_buttonWapper {
    height: 87vh;
    background-color: @colorBackground;

    :global(html.theme-dark) & {
        background-color: @colorBackgroundDark;
    }
}

.screenBgColor {
    position: relative;
    width: 100%;
    height: 64vh;
    background-image: url(../../../images/home/pionex/screenBG.png);
}

.screenBgColor_centerIMG {
    position: absolute;
    top: -66px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 200px;
    margin: auto;
    content: "";

    img {
        width: 100%;
    }
}

.screenBgColor_Logo {
    position: relative;
    height: 40px;
    margin: 20px 20px 10px 20px;
}

.screenBgColor_imgAb {
    position: absolute;
    right: 0;
    left: 0;
    width: 160px;
    margin-right: auto;
    margin-left: auto;

    img {
        width: 100%;
    }
}

.screenBgColor_text {
    margin-bottom: 1.6rem;
    text-align: center;

    div:nth-of-type(1) {
        margin-bottom: 18px;
        color: @colorTextAccent;
        font-size: 18px;
    }

    div:nth-of-type(2) {
        color: @colorTextAccent;
        font-size: 16px;
    }
}

.afterLanding_button {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    height: 44px;
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
    line-height: 43px;
    text-align: center;
    cursor: pointer;

    // color: @white;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

.afterLanding_buttonUno {
    z-index: 9;
    color: @colorTextAccent;
    background-color: @colorBackground;
    border: 1px solid @colorAccent2;
    border-radius: 27px;
    box-shadow: 0 2px 4px 0 rgba(4, 38, 253, 0.08);

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
        background-color: @colorBackgroundDark;
    }
}

.PerGridBg {
    background-color: @colorBackground;

    :global(html.theme-dark) & {
        background-color: @colorBackgroundDark;
    }

    // height: 100vh;
}

.PerGrid_text {
    margin-bottom: 40px;
    padding-top: 46px;
    text-align: center;

    h2 {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 26px;
    }

    div:nth-of-type(1) {
        margin-bottom: 6px;
        font-weight: 500;
    }

    div:nth-of-type(2) {
        font-weight: 500;
    }
}

.PerGrid_buttons {
    margin-bottom: 40px;
    padding: 0 20px 0 20px;
}

.PerGrid_buttons2 {
    margin-bottom: 20px;
    padding: 0 20px 0 20px;
}

.afterLanding_buttonDos {
    z-index: 9;
    padding: 0 20px 0 20px;
    background: linear-gradient(90deg, rgba(254, 96, 63, 1) 0%, rgba(255, 152, 81, 1) 100%);
    border-radius: 27px;

    box-shadow: 0 2px 4px 0 rgba(4, 38, 253, 0.08);

    :global(html.theme-dark) & {
        background-color: @windowBackgroundDark;
    }

    .falzer_style {
        display: inline-block;
        width: 100%;

        .link_style {
            span {
                color: @colorBackground;

                :global(html.theme-dark) & {
                    color: @colorBackground;
                }
            }
        }
    }
}

.buafterLanding_buttonDos {
    z-index: 9;
    padding: 0 20px 0 20px;
    background: linear-gradient(90deg, rgba(87, 147, 241, 1) 0%, rgba(45, 113, 218, 1) 100%);
    border-radius: 27px;
    box-shadow: 0 2px 4px 0 rgba(4, 38, 253, 0.08);

    :global(html.theme-dark) & {
        background-color: @windowBackgroundDark;
    }

    .falzer_style {
        display: inline-block;
        width: 100%;

        .link_style {
            span {
                color: @colorBackground;

                :global(html.theme-dark) & {
                    color: @colorBackground;
                }
            }
        }
    }
}

.lingjoin_hover {
    &:hover {
        color: @colorAccent !important;
    }
}

.telegramimg_style {
    position: relative;
    // background-color: #fff;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
    transform: translateY(-2px);

    img {
        width: 100%;
        height: 100%;
    }
}

.imgwxewm {
    img {
        width: 100px;
        height: 100px;
    }
}

.PerGrid_Liner {
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    background-color: @activityTablheader;

    :global(html.theme-dark) & {
        background-color: @colorDivider2Dark;
    }
}

.dolond_Liner {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: @activityTablheader;

    :global(html.theme-dark) & {
        background-color: @colorDivider2Dark;
    }
}

.PerGrid_Content_text {
    margin-bottom: 20px;
    padding: 0 20px 0 20px;

    div {
        font-weight: 500;
        font-size: 14px;
    }

    div:nth-of-type(1) {
        margin-bottom: 26px;
        text-align: center;
    }
}

.PerGrid_Table {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    font-size: 13px;
    border: 1px solid @activityTablheader;

    :global(html.theme-dark) & {
        border: 1px solid @colorDivider2Dark;
    }
}

.PerGrid_Table_header {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;

    div {
        padding: 10px 0 10px 0;
        font-size: 12px;
    }
}

.PerGrid_Table_border {
    border-right: 1px solid @activityTablheader;
    border-left: 1px solid @activityTablheader;

    :global(html.theme-dark) & {
        border-right: 1px solid @colorDivider2Dark;
        border-left: 1px solid @colorDivider2Dark;
    }
}

.PerGrid_headerBG {
    background-color: @myBotContentBg;

    :global(html.theme-dark) & {
        background-color: @colorPrimaryDarkDark;
    }
}

.PerGrid_hbpttomLiner {
    color: @colorAccent2;
    font-weight: 600;
    border-bottom: 1px solid @activityTablheader;

    :global(html.theme-dark) & {
        border-bottom: 1px solid @colorDivider2Dark;
    }
}

.PerGrid_title_text1 {
    color: @colorAccent2;
    font-weight: 500;
}

.PerGrid_title_text2 {
    color: @textRaiseColor;
    font-weight: 500;
}

.PerGrid_title_text3 {
    color: @textFailColor;
}

.PerGridTableborder_height {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 64px;
}

.aboutSection {
    .aboutContent {
        flex-direction: column-reverse;
    }
}

.rnItemImg {
    height: 34px;
}

.mainContentTitle {
    margin-bottom: 8px;
    padding: 0 20px;
    font-size: 30px;
}

.tradingbotsSafe_bottom {
    padding-bottom: 38px;
}

// footer
.aboutSection {
    .aboutContent {
        .aboutOthers {
            .aboutItem {
                margin: 0 14px;
            }
        }
    }
}

.afterLanding_content {
    display: block;
}

.WelcomQuantSectionWrapper {
    .afterLanding_content {
        width: 100%;
    }

    .banner_img_chiled {
        display: none;
    }
}

.modelIsquantSection {
    width: 100%;
}

.WebButtonTradeLink {
    display: none;
}

.ModelButtonTradeLink {
    display: block;
}

.imgwxewm {
    img {
        width: 200px;
        height: 200px;
    }
}

.aboutSection {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

    .aboutContent {
        display: flex;
        // align-items: flex-start;
        align-items: center;
        justify-content: space-between;
        width: 1080px;

        .aboutLogoSection {
            display: flex;
            flex-flow: column;

            .aboutLogo {
                width: 184px;
                height: 30px;
            }

            .aboutCp {
                margin-top: 16px;
                margin-bottom: 28px;
                color: #828c99;
                font-size: 14px;
                line-height: 1.71;
            }

            .contactUs {
                color: #828c99;
                font-size: 14px;
                line-height: 1.71;
            }
        }

        .aboutTitle_color {
            color: #7c93ad;
            font-size: 12px;
        }

        .aboutOthers {
            display: flex;
            // margin-bottom: 30px;

            .aboutItem {
                display: flex;
                flex-flow: column;
                margin: 0 12px;

                .aboutTitle {
                    color: #7c93ad;
                    font-size: 12px;
                }

                .aboutSplit {
                    width: 140px;
                    height: 1px;
                    margin-top: 24px;
                    background-color: #2d3540;
                }

                .aboutSubTitle {
                    margin-top: 24px;
                    color: #828c99;
                    font-size: 14px;
                }

                .aboutLink {
                    margin-top: 24px;
                    color: #828c99;
                    font-size: 14px;
                }

                .qrGroup {
                    display: flex;
                    margin-top: 23px;

                    .qrItem {
                        display: flex;
                        flex-flow: column;
                        margin-right: 44px;

                        .qrTitle {
                            color: #828c99;
                            font-size: 14px;
                        }

                        .qrImage {
                            width: 80px;
                            height: 80px;
                            margin-top: 12px;
                        }
                    }
                }
            }

            .aboutItemLast {
                margin-right: 0;

                .aboutSplit2 {
                    width: 260px;
                    height: 1px;
                    margin-top: 24px;
                    background-color: #2d3540;
                }
            }
        }
    }
}

.iconColor {
    color: @colorAccent2;
}

// xiazai
.download_wapper {
    width: 1070px;
    margin: 20px auto;
    content: "";
}

.download_top_content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 98px;
}

.download_top_content_left {
    width: 100%;
}

.download_top_content_right {
    img {
        width: 590px;
    }
}

.download_top_content_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: -26px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;

    h2 {
        margin-bottom: 0;
    }
}

.download_top_content_iosbutton {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.download_top_content_androidbutton {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dow_ios {
    margin-bottom: 34px;
    padding-left: 24px;
}

.dow_android {
    padding-left: 24px;
}

.download_button_title {
    margin-bottom: 14px;
    margin-left: 40px;
    font-size: 16px;
}

.dowloadButton {
    position: relative;
    z-index: 0;
    z-index: 9;
    display: inline-block;

    width: 170px;
    height: 50px;
    padding: 0 20px 0 20px;
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
    background: linear-gradient(90deg, rgba(254, 96, 63, 1) 0%, rgba(255, 152, 81, 1) 100%);
    border-radius: 27px;
    box-shadow: 0 2px 4px 0 rgba(4, 38, 253, 0.08);
    cursor: pointer;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;

    :global(html.theme-dark) & {
        background-color: @windowBackgroundDark;
    }
}

.budowloadButton {
    position: relative;
    z-index: 0;
    z-index: 9;
    display: inline-block;

    width: 170px;
    height: 50px;
    padding: 0 20px 0 20px;
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
    background: linear-gradient(90deg, rgba(87, 147, 241, 1) 0%, rgba(45, 113, 218, 1) 100%);
    border-radius: 27px;
    box-shadow: 0 2px 4px 0 rgba(4, 38, 253, 0.08) !important;
    cursor: pointer;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;

    :global(html.theme-dark) & {
        background-color: @windowBackgroundDark;
    }
}

.otroDowloadButton {
    position: relative;
    z-index: 0;
    z-index: 9;
    display: inline-block;

    width: 161px;
    height: 50px;
    // background: linear-gradient(90deg,rgba(254, 96, 63, 1) 0%,rgba(255, 152, 81, 1) 100%);
    padding: 0 20px 0 20px;
    overflow: hidden;
    color: @colorTextAccent;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid @download_desebal;
    border-radius: 27px;
    box-shadow: unset !important;
    cursor: pointer;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
        background: @colorBackgroundDark;
    }

    .dowloadButton_version {
        div:nth-last-of-type(1) {
            color: @download_desebal;

            :global(html.theme-dark) & {
                color: @download_desebal;
            }
        }
    }
}

.otroDowload_position {
    position: relative;
    overflow: hidden;
}

.otroDowloadButtonMasker {
    position: absolute;
    top: 0;
    left: 0;
    // background-color: rgba(134, 44, 44, 0.39);
    z-index: 999;
    width: 100%;
    height: 100%;
    border-radius: 27px;
}

.iosbutton_uno {
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin-right: 40px;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(254, 96, 63, 0.3);
}

.iosbutton_dos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(254, 96, 63, 0.3);
}

.androidbutton_uno {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    box-shadow: 0 2px 4px 0 rgba(254, 96, 63, 0.3);
}

.androidbutton_dos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(254, 96, 63, 0.3);
}

.dowloadButton_logo {
    margin-right: 10px;

    img {
        width: 28px;
    }
}

.dowloadButton_version {
    display: flex;
    flex-direction: column;
    // align-items: center;
    color: @colorBackground;
    text-align: left;

    div:nth-of-type(1) {
        color: @colorBackground;
        font-weight: bold;
        font-size: 16px;
    }

    div:nth-of-type(2) {
        color: @colorBackground;
        font-size: 13px;
    }
}

.dowindex_footer {
    display: flex;
    flex-direction: row !important;
}

.basic_Drawer {
    // position: relative;
    z-index: 999999;

    :global {
        .ant-drawer-content-wrapper {
            width: 100% !important;
            height: unset !important;

            .ant-drawer-content {
                .ant-drawer-wrapper-body {
                    .ant-drawer-header {
                        background-color: @DrawerHeader;

                        .ant-drawer-title {
                            line-height: 40px;
                            text-align: center;
                        }

                        .ant-drawer-close {
                            top: 20px;
                        }
                    }
                }
            }
        }
    }
}

.basic_Drawer_content {
    padding-bottom: 30px;
}

.basic_Drawer_closeIcon {
    position: absolute;
    top: 0;
    right: 0;
}

.basic_Drawer_content_text {
    padding: 0 10px 0 10px;
}

.drawer_text {
    font-size: 12px;
}

.drawer_jointext {
    margin: 10px 0 20px 0;
    text-align: right;
}

.notification_content {
    display: flex;
    flex-direction: row;
}

.notification_content_text {
    padding-right: 10px;
    // width: 100px;
    content: "";
}

.notification_content_button {
    content: "";
}

.dowloadLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dowloadLogo {
    div:nth-of-type(1) {
        width: 56px;

        img {
            width: 100%;
        }
    }

    div:nth-of-type(2) {
        padding-bottom: 6px;
        padding-left: 8px;
        color: @colorAccent2;
        font-weight: bold;
    }
}

.notificationModelButton {
    height: 30px;
    padding: 0 12px 0 12px;
    overflow: hidden;
    color: @colorTextAccentDark;
    color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    background: linear-gradient(90deg, #fe603f 0%, #ff9851 100%);
    border-radius: 27px;
    box-shadow: 0 2px 4px 0 rgba(254, 96, 63, 0.3);
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;

    :global(html.theme-dark) & {
        background-color: @windowBackgroundDark;
    }
}

.dowloadButton_version,
.afterLanding_button,
.notificationModelButton,
.dowloadButton {
    a {
        color: @colorBackground;
    }

    a:link {
        color: @colorBackground;
    }

    a:active {
        color: @colorBackground;
    }
}

.wechantdownloadBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
}

.wechantdownloadBg_img {
    position: absolute;
    top: 5px;
    right: 5px;
}

.wechantdownloadBg_text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 0 10px 0 10px;
    font-weight: bold;
}

.wechantdownloadBg_closeimg {
    position: absolute;
    top: 10px;
    left: 10px;

    i {
        color: @colorBackground;
    }
}

.PerGrid_title_text2,
.PerGrid_title_text3 {
    span {
        display: inline-block;
        width: 70px;
        font-size: 12px;
        text-align: right;
    }
}

.table_model_headertext1 {
    flex: 0.5;
}

.table_model_headertext2 {
    flex: 1;
}

.table_model_headertext3 {
    flex: 1.5;
}

.dow_codeImg {
    width: 100px;

    img {
        width: 100%;
    }
}

.text_download {
    padding-left: 12px;
    text-align: left;

    h2 {
        font-weight: bold;
        font-size: 26px;
    }

    h3 {
        font-weight: bold;
        font-size: 18px;
    }
}

@media screen and (max-width: 767.98px) {
    .text_download {
        padding-left: 0;
        text-align: center;
    }

    .download_button_title {
        display: none;
    }

    .dowBg_height {
        height: unset !important;
    }

    .download_wapper {
        width: 100%;
    }

    .download_top_content {
        flex-direction: column;
        margin-top: 48px;
    }

    .download_top_content_right {
        text-align: center;

        img {
            width: 90%;
        }
    }

    .dow_ios {
        margin-bottom: 18px;
    }

    .dow_android {
        margin-bottom: 30px;
    }

    .dow_ios,
    .dow_android {
        padding-left: unset;
    }

    .download_top_content_left {
        width: 100%;
        padding: 0 6px 0 6px;
    }

    .download_top_content_title {
        margin-top: 6px;
        margin-bottom: 50px;

        h2 {
            color: #fe603f !important;
            font-weight: bold;
        }

        h3 {
            font-weight: bold;
        }
    }

    .download_top_content_left {
        margin-bottom: 40px;
    }

    .iosbutton_uno {
        margin-right: 1rem;
    }

    .androidbutton_uno {
        margin-right: 1rem;
    }

    .dowloadButton_logo {
        img {
            width: 26px;
        }
    }

    .aboutSection {
        .dowindex_footer {
            flex-direction: column !important;
        }
    }

    .aboutLogoSection {
        margin-bottom: 10px;
    }

    .dowloadButton_version {
        div:nth-of-type(1) {
            font-size: 14px;
        }
    }
}

.download_wrap_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    box-shadow: 0 6px 19px -4px rgba(75, 75, 81, 0.12);
}

.download_title {
    margin-top: 6800/750vw;
    margin-bottom: 5100/750vw;
    font-weight: bold;
    font-size: 4400/750vw;
}

.download_footer_img {
    position: relative;
    width: 71000/750vw;
    height: 37400/750vw;
    margin-top: 5000/750vw;
    background-image: url(../../../images/home/picture/app_web_pic_us.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;