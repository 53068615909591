@import "~less/v2/vars.less";

.container {
    position: relative;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    .v2MediaDesktopHD({
        padding: 0 20px;
    });
    .h1 {
        text-align: center;
    }
}

.footer {
    padding: 90px 0;
    background-color: #212633;
    // background-image: url(./assets/fpi.png);
    // background-repeat: no-repeat;
    // background-position: -30% center;
    // background-size: 1082px 952px;
    .v2MediaTablet({
        padding: 40px 0;
        background-position: -5% top;
        background-size: 100% auto;
    });
    .leftTitle {
        max-width: 400px;
        color: #fff;
        font-size: 64px;
        line-height: 1.2;
        .v2MediaDesktopHD({
            font-size: 48px;
        });
        .v2MediaTablet({
            font-size: 36px;
        });
    }
    .fBtn {
        height: 60px;
        padding: 0 40px;
        font-weight: 700;
        font-size: 18px;
        line-height: 60px;
        background-color: transparent;
        border-radius: 30px;
        .v2ConstantTheme({
            border-color: #fff;
            color: #fff;
        });
        &:hover {
            .v2ConstantTheme({
                background-color: #fff;
                color: #000000;
            });
        }
        .v2MediaTablet({
            height: 50px;
            padding: 0 30px;
            font-size: 14px;
            line-height: 48px;
            margin-bottom: 40px;
        });
    }
    .fNavTitle {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.5;
        .v2ConstantTheme({
            color: #fff;
        });
        .v2MediaTablet({
            font-size: 18px;
            margin-bottom: 15px;
        });
    }
    .fNavLis {
        padding: 0;
        font-size: 14px;
        line-height: 1.5;
        li {
            margin-bottom: 18px;
            :global(.t-report-link) {
                color: #fff;
                font-size: 14px;
                line-height: 21px;
                :global(.iconfont) {
                    margin-right: 10px;
                    font-size: 20px;
                    vertical-align: middle;
                }
                &:hover {
                    color: @primary-color;
                }
            }
            .v2MediaTablet({
                display: inline-block;
                width: 50%;
                :global(.t-report-link) {
                    font-size: 12px;
                    :global(.iconfont) {
                        font-size: 16px;
                        margin-right: 5px;
                    }
                }
            });
        }
    }
    .addr {
        margin-top: 80px;
        margin-bottom: 10px;
        color: #fff;
        font-size: 12px;
        .v2MediaTablet({
            margin-top: 20px;
        });
    }
    .copy {
        color: rgba(255, 255, 255, 0.3);
        font-size: 12px;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;