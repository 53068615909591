@import "../../../../less/variables.less";
@import "../../../../less/colors.less";
@import "~less/v2/vars.less";

.sectionContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 16px;
}

.sectionBottomContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding: 20px 16px;
    border-top: 1px solid @leaderboardbg;
}

.itemContainer {
    display: flex;
    flex-direction: column;
}

.titleStyle {
    color: @colorTextAccent;
    font-size: 20px;
    text-align: left;

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
    }
}

.contentStyle {
    color: @colorTextAccent;
    font-size: 20px;
    line-height: 24px;
    text-align: left;

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
    }
}

.titleStyle1 {
    color: @colorTextAccent;
    font-size: 20px;
    text-align: right;

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
    }
}

.contentStyle1 {
    color: @colorTextAccent;
    font-size: 20px;
    line-height: 24px;
    text-align: right;

    :global(html.theme-dark) & {
        color: @colorTextAccentDark;
    }
}

.table_wrapper {
    :global {
        .ant-table-content {
            width: 100%;
        }

        .ant-table-thead {
            tr {
                //.ant-table-row-cell-break-word {
                //    text-align: center;
                //}
                //
                //.ant-table-row-cell-break-word:nth-child(1) {
                //    text-align: left !important;
                //}

                .ant-table-row-cell-last {
                    text-align: right !important;
                }

                th {
                    color: @v2-grey-12 !important;
                    background-color: @colorBackground !important;
                    border-bottom: none !important;

                    :global(html.theme-dark) & {
                        color: @v2-grey-13 !important;
                        background-color: @v2-grey-15 !important;
                    }
                }
            }
        }

        .ant-table-tbody {
            background-color: @colorBackground !important;
            :global(html.theme-dark) & {
                background-color: @v2-grey-15 !important;
            }
            tr {
                //.ant-table-row-cell-break-word {
                //    text-align: center;
                //}
                //
                //.ant-table-row-cell-break-word:nth-child(1) {
                //    text-align: left !important;
                //}

                td {
                    // color: @capTitletextcolor;
                    border-bottom: none !important;

                    :global(html.theme-dark) & {
                        color: @capTitletextcolorDark;
                    }
                }
            }
        }
    }

    :global {
        .ant-table-placeholder {
            color: @colorTextHint;
            background-color: @colorBackground !important;
            // background-color: @buTableRowBg;
            border: none;

            :global(html.theme-dark) & {
                background-color: @v2-grey-15 !important;
            }
        }

        .ant-btn {
            margin-right: 4px;
        }
    }
}

.table_common_item {
    :global {
        .ant-table-column-sort {
            background-color: transparent !important;
        }
    }
}

.table_item_even {
    background-color: @dialogTitleBackground !important;

    :global {
        .ant-table-cell {
            background-color: @dialogTitleBackground !important;
        }
    }
    :global(html.theme-dark) & {
        background-color: @v2-grey-15 !important;
        :global {
            .ant-table-cell {
                background-color: @v2-grey-15 !important;
            }
        }
    }
}

.table_item_odd {
    background-color: @v2-table-odd-bg-color !important;

    :global {
        .ant-table-cell {
            background-color: @v2-table-odd-bg-color !important;
        }
    }
    :global(html.theme-dark) & {
        background-color: @v2-table-odd-bg-dark-color !important;
        :global {
            .ant-table-cell {
                background-color: @v2-table-odd-bg-dark-color !important;
            }
        }
    }
}

.option_common_button {
    width: 77px;
    height: 24px;
    border-radius: 1px;

    :global(html.theme-light) & {
        color: @colorText6;
        border: 1px solid @publicTextColor;
    }
}
.listBtn {
    .option_common_button {
        width: initial;
        border-color: @v2-grey-17;
        .v2DarkTheme({
            border-color: @v2-dark-11;
        });
        .v2Theme-color(@v2-grey-16, @v2-dark-10);

        &:disabled {
            .v2NormalTheme({
                color: @v2-dark-8;
                border-color: @v2-dark-8;
            });
            .v2DarkTheme({
                color: @v2-dark-5;
            });
        }
    }
    :global {
        .ant-btn {
            height: 26px;
            border-radius: 4px;
        }
    }
}

.progress {
    width: 90px;
    height: 6px;
    margin-right: 15px;

    :global {
        .ant-progress-inner {
            position: relative;
            height: 6px;
        }

        .ant-progress-bg {
            position: absolute;
            right: 0;
            height: 6px !important;
        }
        .ant-progress-text {
            .v2Theme-color(@v2-grey-16, @v2-dark-10);
        }
    }
}

.coinNameWrap {
    .coinName {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
    }

    .fullName {
        color: @transfomModetext;
        font-size: 12px;

        :global(html.theme-dark) & {
            color: @colorText;
        }
    }
}

.popoverText {
    :global(html.theme-dark) & {
        color: @colorBackground;
    }
}

.frozenValue {
    color: @transfomModetext;

    :global(html.theme-dark) & {
        color: @colorText;
    }
}

.disableButton {
    color: #c9c9c9 !important;
    border: 1px solid #c9c9c9 !important;

    :global(html.theme-dark) & {
        color: #3f4456 !important;
        border: 1px solid @v2-dark-11 !important;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;