@import "~less/colors";

.sider_wrap {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1050;
    display: inline-block;
    height: 100%;
    overflow: hidden;
    background-color: @aPairBg;
    transition: all 350ms ease-in-out 0ms;

    :global(html.theme-dark) & {
        background-color: @aPairBgDark;
        .sider_item_hover:hover {
            right: -3px;
            background-color: red;
        }
    }

    .sider_item {
        display: flex;
        align-items: center;
        padding: 12px 22px 12px 12px;
        cursor: pointer;
        user-select: none;
    }

    .sider_item_text {
        margin-left: 12px;
        padding-top: 2.5px;
        color: @invitationCodeBg;
        white-space: nowrap;

        :global(html.theme-dark) & {
            color: @colorText;
        }
    }

    .sider_item_hover {
        right: -3px;
        &:hover {
            background-color: #dee0e3;
        }

        :global(html.theme-dark) &:hover {
            background-color: #303542;
        }
    }

    .side_bar_icon {
        width: 21.6px;
        height: 21.6px;
    }

    .sider_header {
        display: flex;
        align-items: center;
        padding: 19.5px 22px 17px 12px;
        border-bottom: 4px solid @windowBackground3;
        cursor: pointer;
        user-select: none;
        :global(html.theme-dark) & {
            border-bottom: 4px solid @colorEditDark;
        }
    }

    .sider_item_popover {
        padding-right: 0;
        box-shadow: 0 0 20px 0 rgba(14, 27, 53, 0.12);
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;