@import "~less/v2/vars.less";

.modal {
    iframe {
        height: 600px !important;
    }

    :global {
        .pi-modal-content {
            background-color: white !important; // 兼容内部样式，固定亮色
        }

        .t-spin {
            .v2DarkTheme({
                background-color: rgba(255, 255, 255, 0.8) !important;
            });
        }

        .pi-modal-footer {
            button {
                &:before {
                    background-color: unset !important;
                }
            }
        }
    }
}

.waitingContainer {
    padding-top: 20px;
    .waitingIcon {
        width: 174px;
        height: 174px;
        margin: auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .tipText {
        margin: auto;
        margin-top: 32px;
        h3 {
            font-size: 18px;
        }
        h3,
        span {
            display: block;
            width: 100%;
            text-align: center;
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;