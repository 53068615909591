@import "../../less/colors.less";
@import "~less/v2/vars.less";

body {
    &,
    .appWrap {
        font-family: roboto, poppins, sans-serif;
    }
}

.appWrap {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: @v2-primary-bg-color-2;
    &.whiteBg {
        background-color: #ffffff;
    }
    .v2DarkTheme({
        &,
        &.whiteBg {
            background-color: @v2-dark-bg-color-2;
        }
    });
    .fullFillWrap {
        position: relative;
    }
    &.overflowHidden {
        max-height: 100vh;
        overflow: hidden;
    }
    // 全宽高
    &.fullFill {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        .bodyBox {
            display: flex;
            flex: 1;
        }
        .fullFillWrap {
            flex: 1;
            width: 100%;
            > .fullFillBox {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
        .v2MediaTablet({
			display: block;
			height: auto;
			.fullFillWrap > .fullFillBox {
				position: relative;
			}
		});
    }
    &.fullWidth {
        .fullFillWrap {
            width: 100%;
            max-width: 100%;
        }
    }
    &.tradingLayout {
        display: flex;
        flex-direction: column;
        min-width: 1200px;
        height: 100%;
        overflow: hidden;
        .bodyBox {
            display: flex;
            flex: 1;
            min-width: 1200px;
            height: 100%;
            overflow: hidden;
        }
        .fullFillWrap {
            flex: 1;
        }
    }
    .pageLoading {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        .v2DarkTheme({
            background-color: rgba(0, 0, 0, 0.6);
        });
    }
}

.fontKo {
    font-family: "Jeremy", "Noto Sans KR", "sans-serif", "AppleSDGothicNeo-Regular", "Malgun Gothic", "Dotum";
    :global(.number-font-family) {
        font-family: "Jeremy", "Noto Sans KR", "sans-serif", "AppleSDGothicNeo-Regular", "Malgun Gothic", "Dotum";
    }
}

.tradingLayoutContent {
    position: relative;
    flex: 1;
    width: 100%;
    overflow: hidden;
    & > div {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}

#sider_popover {
    height: 100%;
    background-color: red;
    :global {
        .ant-popover-arrow {
            background-color: blueviolet;
        }
    }

    .popover_text {
        color: @colorText4 !important;
        :global(html.theme-dark) & {
            color: @colorText2Dark !important;
        }
    }
}

.hiddenScrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.downloadAlert {
    padding-top: 70px;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;