@import "./vars.less";

.center-container {
    @innerPadding: 15px;
    position: relative;
    width: 100%;
    max-width: @v2-max-content-with + @innerPadding * 2;
    height: 100%;
    margin: 0 auto;
    padding: 0 @innerPadding;
}
.ant-modal {
    padding-bottom: 0;
}

// 解决移动端message不会换行
.ant-message-notice-content {
    .v2MediaMaxXS({
        width: calc(100vw - 50px);
    });
    span:last-child {
        .v2MediaMaxXS({
                word-wrap: break-word;
        });
    }
}

.addUSDT {
    position: relative;

    &::after {
        position: absolute;
        top: -3px;
        color: #7c8494;
        font-size: 8px;
        content: "USDT";
    }
}

.ant-input-group-wrapper {
    @addonWith: 2em;

    &.customAddonBefore,
    &.customAddonAfter {
        .ant-input-group {
            position: relative;

            .ant-input {
                background-color: transparent;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }

        .ant-input-group-addon {
            background-color: transparent;
        }

        .ant-input-group-addon {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: @addonWith;
            height: 100%;
            padding: 0;
            border: none;
        }

        & + .t-input-wrapper-placeholder span {
            html.theme-light & {
                background-color: #fff;
            }

            html.theme-dark & {
                background-color: @v2-grey-1;
            }
        }
    }

    &.customAddonBefore {
        .ant-input {
            padding-left: @addonWith;
        }

        .ant-input-group-addon {
            left: 0;
        }
    }

    &.customAddonAfter {
        .ant-input {
            padding-right: @addonWith;
        }

        .ant-input-group-addon {
            right: 0;
        }
    }
}

.ant-input-group-addon {
    html.theme-dark & {
        color: #fff;
    }

    html.theme-light & {
        color: @v2-grey-20;
    }
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.flex {
    display: flex;
}

.flex-h-center {
    justify-content: center;
    &:extend(.flex);
}

.flex-v-center {
    align-items: center;
    &:extend(.flex);
}

.flex-center {
    &:extend(.flex-h-center,
    .flex-v-center);
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
    &:extend(.flex);
}

.direction-column {
    flex-direction: column;
}

.fs-8p {
    font-size: 8px;
}

.fs-12p {
    font-size: 12px;
}

.fs-14p {
    font-size: 14px;
}

.fs-16p {
    font-size: 16px;
}

.fs-20p {
    font-size: 20px;
}

.fw-4 {
    font-weight: 400;
}

.fw-5 {
    font-weight: 500;
}

.fw-6 {
    font-weight: 600;
}

.text-bold {
    font-weight: bold;
}

.margin-left-1em {
    margin-left: 0.5em;
}

.color-333 {
    color: @v2-font-content;
}

.color-666 {
    color: #666;
}

.color-primary {
    color: @v2-primary-color;
}

.cursor-pointer {
    cursor: pointer;
}

.color-gray {
    color: @colorText;
}

.width-percent-100 {
    width: 100%;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-nowrap {
    white-space: nowrap;
}

.inline-block {
    display: inline-block;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.padding-right-40 {
    padding-right: 40px;
}

.padding-right-20 {
    padding-right: 20px;
}

.padding-right-10 {
    padding-right: 10px;
}

.margin-top-1e {
    margin-top: 1em;
}

.margin-top-2e {
    margin-top: 2em;
}

.margin-bottom-2e {
    margin-bottom: 2em;
}

.margin-left-1e {
    margin-left: 1em;
}

.margin-right-1e {
    margin-right: 1em;
}

.icon-gap-right {
    margin-right: 0.5em;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.text-decoration-dotted {
    text-decoration-color: #474747;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: from-font;
    text-underline-offset: 0.2em;
}

.shape-circle {
    border-radius: 50%;
    &:extend(.inline-block);
}

.border-radius {
    border-radius: 16px;
}

.round-tooltip .ant-tooltip-inner {
    .border-radius;
}

.pre-line {
    white-space: pre-line;
}

.inline-flex {
    display: inline-flex;
}

.main-text-color {
    html.theme-light & {
        color: #292c33;
    }

    html.theme-dark & {
        color: #fff;
    }
}

.sub-text-color {
    html.theme-light & {
        color: #9fa7b8;
    }

    html.theme-dark & {
        color: #707580;
    }
}

.warning-text-box {
    html.theme-light & {
        background-color: #fff7dd;
        border-color: #fbc0294d;
    }

    html.theme-dark & {
        background-color: #ffbf0026;
        border-color: #ffb7004d;
    }

    position: relative;
    margin-bottom: 2em;
    padding: 20px;
    padding-left: 70px;
    color: #7c8494;
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;

    &::before {
        position: absolute;
        top: 50%;
        left: 20px;
        width: 40px;
        height: 40px;
        margin-top: -20px;
        background: url("./assets/informationalNotes.svg") no-repeat center;
        background-size: contain;
        border-radius: 50%;
        content: "";
    }
}

.font-f-normal {
    font-family: "Jeremy", "PingFang SC", "Microsoft YaHei", sans-serif, "Apple gothic", "Malgun Gothic";
}

.pionex-font-system {
    font-family: "PingFang SC", "Microsoft YaHei", sans-serif, "Apple gothic", "Malgun Gothic";
}

.pionex-font-poppins {
    font-family: "Poppins", sans-serif;
}
.text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.vertical-align-middle {
    vertical-align: middle;
}
.mark-title {
    height: 20px;
    margin-bottom: 16px;
    line-height: normal;
    &:extend(.flex-v-center,
    .fs-20p,
    .fw-5);
    .v2Theme-color(#292c33,#ffffff);
    &::before {
        width: 4px;
        height: 100%;
        margin-right: 8px;
        background: #ff7028;
        border-radius: 2px;
        content: "";
    }
}

.padding-0 {
    padding: 0;
}

.round-corner-card.ant-card {
    .border-radius;
    html.theme-light & {
        color: #000;
        background-color: white;
    }
}

.pi-message {
    z-index: 1102; // 覆盖老的Modal
}
