@font-face {
    font-family: "iconfont"; /* Project id  */
    src: url("iconfont.ttf?t=1652176662063") format("truetype");
}

.iconfont {
    font-size: 16px;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-icon_account:before {
    content: "\e690";
}

.icon-icon_back:before {
    content: "\e691";
}

.icon-icon_BankTransfer:before {
    content: "\e692";
}

.icon-icon_Arrow:before {
    content: "\e693";
}

.icon-icon_Academy:before {
    content: "\e694";
}

.icon-icon_ClearButton:before {
    content: "\e695";
}

.icon-icon_CryptoOrder:before {
    content: "\e696";
}

.icon-icon_gou:before {
    content: "\e697";
}

.icon-icon_DownArrow:before {
    content: "\e698";
}

.icon-icon_Disclosure:before {
    content: "\e699";
}

.icon-icon_header_facebook:before {
    content: "\e69a";
}

.icon-icon_EmailVerification:before {
    content: "\e69b";
}

.icon-icon_Menu_BankCard:before {
    content: "\e69c";
}

.icon-icon_manual:before {
    content: "\e69d";
}

.icon-icon_KLine:before {
    content: "\e69e";
}

.icon-icon_infor2:before {
    content: "\e69f";
}

.icon-icon_download2:before {
    content: "\e6a0";
}

.icon-icon_nav_About_youtube:before {
    content: "\e6a1";
}

.icon-icon_nav_setting_DarkMode:before {
    content: "\e6a2";
}

.icon-icon_nav_order_bots:before {
    content: "\e6a3";
}

.icon-icon_nav_mine_balance:before {
    content: "\e6a4";
}

.icon-icon_nav_About_TeamInfo:before {
    content: "\e6a5";
}

.icon-icon_nav_surport_Email:before {
    content: "\e6a6";
}

.icon-icon_nav_setting_GreenRed:before {
    content: "\e6a7";
}

.icon-icon_nav_activity:before {
    content: "\e6a8";
}

.icon-icon_SignIN_phone:before {
    content: "\e6a9";
}

.icon-icon_nav_mine_commission:before {
    content: "\e6aa";
}

.icon-icon_nav_surport_Tutorial:before {
    content: "\e6ab";
}

.icon-icon_nav_activity-1:before {
    content: "\e6ac";
}

.icon-icon_nav_setting_Language:before {
    content: "\e6ad";
}

.icon-icon_LearnMore_A1:before {
    content: "\e6ae";
}

.icon-icon_nav_order_manual:before {
    content: "\e6af";
}

.icon-icon_search:before {
    content: "\e6b0";
}

.icon-icon_checked:before {
    content: "\e6b1";
}

.icon-icon_collect:before {
    content: "\e6b2";
}

.icon-icon_information:before {
    content: "\e6b3";
}

.icon-icon_AddressBook:before {
    content: "\e6b4";
}

.icon-icon_rank:before {
    content: "\e6b5";
}

.icon-icon_topcap:before {
    content: "\e6b6";
}

.icon-icon_WhiteList:before {
    content: "\e6b7";
}

.icon-icon_History:before {
    content: "\e6b8";
}

.icon-icon_header_FAQ:before {
    content: "\e6b9";
}

.icon-icon_nav_About_list:before {
    content: "\e6ba";
}

.icon-icon_nav_About_Fee:before {
    content: "\e6bb";
}

.icon-icon_nav_AppDownload:before {
    content: "\e6bc";
}

.icon-icon_nav_About_privacy:before {
    content: "\e6bd";
}

.icon-icon_RiskDisclaimer:before {
    content: "\e6be";
}

.icon-icon_referral:before {
    content: "\e6bf";
}

.icon-icon_window_account:before {
    content: "\e6c0";
}

.icon-icon_nav_setting:before {
    content: "\e6c1";
}

.icon-icon_nav_About_FAQ:before {
    content: "\e6c2";
}

.icon-icon_nav_Community_Reddit:before {
    content: "\e6c3";
}

.icon-icon_nav_Community_facebook:before {
    content: "\e6c4";
}

.icon-icon_nav_telegrame:before {
    content: "\e6c5";
}

.icon-icon_nav_mine_withdraw:before {
    content: "\e6c6";
}

.icon-icon_nav_Community_Discord:before {
    content: "\e6c7";
}

.icon-icon_header_twitter:before {
    content: "\e6c8";
}

.icon-icon_header_discord:before {
    content: "\e6c9";
}

.icon-icon_Play:before {
    content: "\e6ca";
}

.icon-icon_nav_mine_deposit:before {
    content: "\e6cb";
}

.icon-a-icon_header_telegram:before {
    content: "\e6cc";
}

.icon-icon_Mail:before {
    content: "\e6cd";
}

.icon-icon_nav_About_UserAgreement:before {
    content: "\e6ce";
}

.icon-icon_nav_About_TermsAndConditions:before {
    content: "\e6cf";
}

.icon-icon_input_invisible:before {
    content: "\e6d0";
}

.icon-icon_more_loud:before {
    content: "\e6d1";
}

.icon-icon_DownloadApp2:before {
    content: "\e6d2";
}

.icon-icon_AddInvestment:before {
    content: "\e6d3";
}

.icon-icon_input_visible:before {
    content: "\e6d4";
}

.icon-icon_PriceRange:before {
    content: "\e6d5";
}

.icon-iocn_label_revocation:before {
    content: "\e6d6";
}

.icon-iocn_label_check:before {
    content: "\e6d7";
}

.icon-iocn_label_defeated:before {
    content: "\e6d8";
}

.icon-icon_Reset:before {
    content: "\e6d9";
}

.icon-icon_WithdrwaInvestment:before {
    content: "\e6da";
}

.icon-iocn_label_waiting:before {
    content: "\e6db";
}

.icon-icon_SingIn:before {
    content: "\e6dc";
}

.icon-icon_TermsOfService:before {
    content: "\e6dd";
}

.icon-icon_LINE:before {
    content: "\e6de";
}

.icon-iocn_label_completed:before {
    content: "\e6df";
}

.icon-a-icon_SignIn_DownloadApp:before {
    content: "\e6e0";
}

.icon-icon_add:before {
    content: "\e6e1";
}

.icon-icon_i:before {
    content: "\e6e2";
}

.icon-collect:before {
    content: "\e6e3";
}

.icon-icon_rightarrow:before {
    content: "\e6e4";
}

.icon-icon_nav_About_statement:before {
    content: "\e6e5";
}

.icon-icon_header_reddit:before {
    content: "\e6e6";
}

.icon-icon_locking:before {
    content: "\e6e7";
}

.icon-icon_Search:before {
    content: "\e6e8";
}

.icon-icon_nav_Community_Twitter:before {
    content: "\e6e9";
}

.icon-icon_StructuredProduct:before {
    content: "\e6ea";
}

.icon-icon_SignIn_Deposit:before {
    content: "\e6eb";
}

.icon-icon_SignIn_Bot:before {
    content: "\e6ec";
}

.icon-icon_TrialFund:before {
    content: "\e6ed";
}

.icon-icon_nav_surport_LiveChat:before {
    content: "\e6ee";
}

.icon-icon_SignIn_ReceiveMoney:before {
    content: "\e6ef";
}

.icon-icon_checking:before {
    content: "\e6f0";
}

.icon-icon_ErrorLine:before {
    content: "\e6f1";
}

.icon-icon_IdentityCard:before {
    content: "\e6f2";
}

.icon-icon_time:before {
    content: "\e6f3";
}

.icon-icon_Menu_Deposit:before {
    content: "\e6f4";
}

.icon-icon_Menu_History:before {
    content: "\e6f5";
}

.icon-icon_Menu_Withdrow:before {
    content: "\e6f6";
}

.icon-icon_PhoneVerification:before {
    content: "\e6f7";
}

.icon-icon_copy:before {
    content: "\e6f8";
}

.icon-icon_close3:before {
    content: "\e6f9";
}

.icon-icon_More_down:before {
    content: "\e6fa";
}

.icon-icon_Leveraged:before {
    content: "\e6fb";
}

.icon-icon_nav_mine_detail:before {
    content: "\e6fc";
}

.icon-icon_pagination_nextpage:before {
    content: "\e6fd";
}

.icon-icon_input_delete:before {
    content: "\e6fe";
}

.icon-icon_spot:before {
    content: "\e6ff";
}

.icon-icon_SignIN:before {
    content: "\e700";
}

.icon-icon_roboto_edit:before {
    content: "\e701";
}

.icon-kolfanyong_nav_xiangqing:before {
    content: "\e702";
}

.icon-kolfanyong_nav_wode:before {
    content: "\e703";
}

.icon-icon_nav_setting_Currency:before {
    content: "\e704";
}

.icon-icon_nav_surport_Community:before {
    content: "\e705";
}

.icon-kolfangyong_buttoin_add:before {
    content: "\e706";
}

.icon-icon_SignUp:before {
    content: "\e707";
}

.icon-icon_Account:before {
    content: "\e708";
}

.icon-icon_AccountNnumber:before {
    content: "\e709";
}

.icon-icon_GridMode:before {
    content: "\e70a";
}

.icon-icon_KYC:before {
    content: "\e70b";
}

.icon-icon_LeveragedTokensTest:before {
    content: "\e70c";
}

.icon-icon_photo:before {
    content: "\e70d";
}

.icon-icon_Investment:before {
    content: "\e70e";
}

.icon-icon_EarnAccount:before {
    content: "\e70f";
}

.icon-icon_Password:before {
    content: "\e710";
}

.icon-icon_GoogleAuthenticator:before {
    content: "\e711";
}

.icon-icon_Referrals:before {
    content: "\e712";
}

.icon-iocn_PhoneVerification:before {
    content: "\e713";
}

.icon-icon_Name:before {
    content: "\e714";
}

.icon-icon_tips:before {
    content: "\e715";
}

.icon-icon_SlippageControl:before {
    content: "\e716";
}

.icon-icon_TradingAccount:before {
    content: "\e717";
}

.icon-icon_FaceRecognition:before {
    content: "\e718";
}

.icon-icon_RebalanceMode:before {
    content: "\e719";
}

.icon-icon_succeed:before {
    content: "\e71a";
}

.icon-icon_nav_surport_Notifications:before {
    content: "\e71b";
}

.icon-iocn_BotTutorial:before {
    content: "\e71c";
}

.icon-icon_nav_HistoryOrder:before {
    content: "\e71d";
}

.icon-icon_question:before {
    content: "\e71e";
}

.icon-icon_secceed:before {
    content: "\e71f";
}

.icon-icon_link:before {
    content: "\e720";
}

.icon-icon_wrong1:before {
    content: "\e721";
}

.icon-icon_NoResult:before {
    content: "\e722";
}

.icon-icon_rebalence:before {
    content: "\e723";
}

.icon-icon_pdf:before {
    content: "\e724";
}

.icon-icon_triangle:before {
    content: "\e725";
}

.icon-icon_region:before {
    content: "\e726";
}

.icon-icon_TriggerPrice:before {
    content: "\e727";
}

.icon-icon_close2:before {
    content: "\e728";
}

.icon-icon_close_normal:before {
    content: "\e729";
}

.icon-icon_menu:before {
    content: "\e72a";
}

.icon-icon_LearnMore_B1:before {
    content: "\e72b";
}

.icon-icon_MenuButton_close:before {
    content: "\e72c";
}

.icon-icon_nav_About_blog:before {
    content: "\e72d";
}

.icon-icon_LearnMore_A2:before {
    content: "\e72e";
}

.icon-icon_xxxxxxx:before {
    content: "\e72f";
}

.icon-img_AI2:before {
    content: "\e730";
}

.icon-icon_MoreInformation_Light:before {
    content: "\e731";
}

.icon-icon_MoreInformation_Dark:before {
    content: "\e732";
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;