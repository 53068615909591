@import "../colors";

html.theme-dark {
    .ant-modal {
        color: @colorTextAccentDark;

        &-content,
        &-header {
            background-color: @v2-grey-15;
        }
    }

    .ant-btn-background-ghost {
        background-color: @leaderboardTabletitlecolor;
    }

    .ant-btn-background-ghost {
        color: @colorTextAccentDark;
        border-color: @colorTextAccentDark;
    }

    .ant-message {
        z-index: 1102;
        color: @colorTextAccentDark;

        .ant-message-notice {
            .ant-message-notice-content {
                padding: 12px 32px;
                color: white;
                font-size: 16px;
                background: rgba(0, 0, 0, 0.8);
                border-radius: 4px;
            }
            .ant-message-notice-content .ant-message-error {
                color: @colorError;
            }

            .ant-message-notice-content .ant-message-warning {
                color: @colorError;
            }
        }
    }

    .ant-btn.disabled,
    .ant-btn:disabled {
        color: #cccccc;
        background: #9a9a9a;
        border: 1px solid #9a9a9a;
    }

    .ant-table {
        color: @colorTextAccentDark;
        background-color: @colorBackgroundDark;
    }

    .ant-table-thead > tr,
    .ant-table-thead > tr > th {
        border-bottom-color: @leaderboardTabletitlecolor;
    }

    .ant-table-placeholder {
        background-color: @colorBackgroundDark;
        border: none;
    }

    .ant-table-thead > tr > th {
        color: @colorTextAccentDark;
        background-color: unset;
    }

    .ant-table-thead > tr > th.ant-table-column-has-actions {
        // background: palegoldenrod !important;
        background-clip: unset !important;
    }

    .ant-tabs {
        color: @colorTextAccentDark;
    }

    .ant-tabs-nav {
        color: @colorText !important;
    }

    .ant-progress-inner {
        background-color: @windowBackground;
    }

    input::-webkit-input-placeholder {
        color: @colorText !important;
    }

    input:-moz-placeholder {
        color: @colorText !important;
    }

    input::-moz-placeholder {
        color: @colorText !important;
    }

    input:-ms-input-placeholder {
        color: @colorText !important;
    }

    .ant-modal-confirm-title {
        margin-left: 15px;
        color: @colorTextAccentDark;
        font-weight: 600 !important;
    }

    .ant-modal-confirm-body .ant-modal-confirm-content {
        color: @colorTextAccentDark;
    }

    .ant-empty-description {
        color: @colorTextAccentDark;
    }

    .ant-dropdown {
        color: @colorTextAccentDark;
    }

    .ant-dropdown-menu {
        padding: 0;
    }

    .ant-dropdown-menu-item-divider {
        margin: 0;
    }

    .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover {
        background-color: #858ca3;
    }

    .ant-dropdown-menu-item:hover {
        background-color: @colorText;
    }

    .ant-drawer-bottom .ant-drawer-content-wrapper {
        right: 0;
        width: 350px;
    }

    @media screen and (max-width: 600px) {
        .ant-drawer-bottom .ant-drawer-content-wrapper {
            right: 0;
            width: 100%;
            // height: 546px !important;
        }
    }

    // .ant-drawer-right .ant-drawer-content-wrapper {
    //   height: 62%;
    // }

    .ant-drawer-right .ant-drawer-content-wrapper {
        right: 0;
        bottom: 0;
    }

    .ant-drawer-header {
        position: relative;
        padding: 8px;
        color: @colorTextAccentDark;
        background-color: #2e323f;
        border-bottom: 1px solid #373b44;
        border-radius: 4px 4px 0 0;
    }

    .ant-drawer-close {
        position: absolute;
        top: 12px;
        right: 14px;
        z-index: 10;
        display: block;
        width: unset;
        height: unset;
        padding: 0;
        color: @colorTextAccentDark;
        font-weight: 700;
        font-size: 12px;
        font-style: normal;
        line-height: unset;
        text-align: center;
        text-transform: none;
        text-decoration: none;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        transition: color 0.3s;
        text-rendering: auto;

        :global {
            i {
                -webkit-transition: all 0.6s ease-in-out;
                -moz-transition: all 0.6s ease-in-out;
                -o-transition: all 0.6s ease-in-out;
                transition: all 0.6s ease-in-out;
            }
        }
    }

    .ant-drawer-close:hover i {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    .ant-drawer-content {
        height: 100%;
    }

    .ant-drawer-wrapper-body {
        display: flex;
        flex-direction: column;
        height: 100% !important;
        overflow: auto !important;
        background-color: #232732;
    }

    .ant-drawer-body {
        flex: 1;
        padding: 15px 10px 0 10px;
    }

    .swiper-container {
        overflow: inherit;
    }

    // 各种tab的 transform 卡帧问题
    .ant-tabs-top .ant-tabs-ink-bar-animated,
    .ant-tabs-bottom .ant-tabs-ink-bar-animated {
        -webkit-transform: translate(0);
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000px;
        -webkit-transition: -webkit-transform;
    }

    .gutter-example .ant-row > div {
        background: transparent;
        border: 0;
    }

    .ant-btn {
        color: @colorText;
        border-color: @colorText;
        box-shadow: unset;
    }

    .ant-btn:hover,
    .ant-btn:focus {
        color: @colorAccent;
        border-color: @colorAccent;
        box-shadow: unset;
    }

    // .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus {
    //   color: white;
    // }

    .ant-btn-primary {
        color: white;
        // background-color: @colorAccent !important;
        background: @isbtnPrimary;
        border-color: transparent;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        color: white;
        background-color: ~`colorPalette("@{isbtnPrimary}", 5) `;
        border-color: transparent;
    }

    .ant-btn-danger {
        color: white;
        background-color: @colorDanger;
        border-color: transparent;
    }

    .ant-btn-danger:hover,
    .ant-btn-danger:focus {
        color: white;
        background-color: ~`colorPalette("@{colorDanger}", 5) `;
        border-color: transparent;
    }

    .ant-select-selection {
        background-color: @singInputBackground;
        border-color: @singInputBackground;
    }

    .ant-select-arrow .ant-select-arrow-icon {
        color: @chongbiTableTrThBgDark;
    }

    .ant-dropdown-menu {
        background-color: @colorBackgroundDark;
    }

    .ant-list {
        color: @colorTextAccentDark;
    }

    // .ant-input-group .ant-input {
    //     height: 40px;
    //     color: #858ca3;
    //     font-size: 12px;
    //     background-color: @windowBackgroundDark;
    //     border: none !important;
    // }

    // .ant-input-group-addon {
    //     position: relative;
    //     color: @colorText !important;
    //     background-color: @windowBackgroundDark !important;
    //     border: 1px solid @colorDivider2Dark;
    // }

    // .ant-input-group-addon::before {
    //     position: absolute;
    //     top: 14px;
    //     left: 0;
    //     display: inline-block;
    //     width: 1px;
    //     height: 10px;
    //     background-color: #a9aebe;
    //     content: "";
    // }

    .ant-slider-step {
        height: 6px !important;
        background: #e3e2ef24;
    }

    .ant-checkbox-wrapper {
        color: @singTextBackground;
    }

    .ant-checkbox-wrapper:hover {
        color: @v2-primary-color;
    }

    #currencyPair {
        .ant-menu-item {
            background-color: @aPairBgDark;
        }

        .ant-menu-horizontal {
            background-color: @aPairBgDark;
        }
    }

    .index-jdrp-menu {
        color: @colorTextAccentDark;
        background-color: @colorBackgroundDark;

        .deop_menu_divider {
            background-color: #272e38;
        }

        span {
            color: @singTextBackground;

            .ant-dropdown-menu-submenu-arrow-icon {
                color: @colorTextAccentDark;
            }
        }
    }

    .ant-input {
        color: @colorTextAccentDark;
        background-color: transparent;
        border: 1px solid @colorText;
    }

    // .ant-input-search-icon {
    //     color: @colorText;
    // }

    // .ant-input[disabled] {
    //     background-color: #353940;
    //     border: none;
    // }

    .ant-select-lg .ant-select-selection__rendered,
    .ant-input-affix-wrapper .ant-input-suffix {
        color: @singTextBackground;
    }

    .ant-input-affix-wrapper .ant-input-suffix .ant-input-password-icon {
        color: @inputIconBgDark;
    }
    .ant-table-tbody > tr {
        background-color: @colorBackgroundDark;
    }
    .ant-table-tbody > tr:nth-child(even) {
        background-color: @colorBackground3Dark;
    }
    .ant-table-tbody > tr > td {
        color: @colorTextAccentDark;
        border-bottom-color: @colorDividerDark;
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
        background: @hoverTablesHoverBgDark;
    }

    .ant-select-selection__rendered {
        color: @invitationCodeBgDark;
    }

    .ant-slider-rail {
        background-color: @windowBackgroundDark;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: transparent;
    }

    .ant-tabs-nav .ant-tabs-tab-active {
        h4 {
            color: @leaderboardTabletitlecolor !important;
        }
    }

    .ant-pagination-item {
        background-color: transparent;
        border-color: transparent;

        a {
            color: @colorText;
        }
    }

    .ant-pagination-item-active {
        background-color: transparent;
        border-color: @colorAccent;

        a {
            color: @colorTextAccentDark;
            &:hover {
                color: @colorTextAccentDark;
            }
        }
    }

    .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
            color: @colorText;
        }
    }

    .ant-modal-title {
        color: @colorBackground;
    }

    .ant-modal-header {
        border-color: #343640;
    }

    .ant-radio-wrapper {
        color: @colorBackground;
    }

    .ant-modal-footer {
        border-color: #343640;

        button:nth-of-type(1) {
            background-color: #2e323f;
            border-color: #2e323f;
        }
    }

    .ant-rate {
        color: #ffab50;
    }

    .ant-select-dropdown {
        background-color: @v2-dark-bg;
        border: 1px solid #393e47;
    }

    .ant-select-dropdown-menu-item {
        color: @colorBackground !important;
    }

    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
        color: @colorText;
    }

    .ant-menu-horizontal > .ant-menu-item-selected {
        // font-weight: bold;
        color: @leaderboardTabletitlecolor;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: @colorTextAccentDark;
    }

    .ant-tree {
        color: @colorTextAccentDark;

        li .ant-tree-node-content-wrapper {
            color: @colorTextAccentDark;
        }

        .anticon {
            color: @colorTextAccentDark;
        }
    }

    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
        // background-color: red;
        background-color: #858ca3;
    }

    .ant-select-dropdown-menu-item-active {
        background-color: unset;
    }

    .ant-select-dropdown-menu-item-selected {
        background-color: unset;
    }

    .ant-progress-status-success .ant-progress-bg {
        background-color: @textRaiseColor;
    }

    // 中签+——+——+——+——+——++————++——————+
    .ant-modal-title {
        color: @singTextBackground;
    }

    .ant-modal-close {
        color: @singTextBackground;
    }

    .ant-modal-footer {
        border-color: #343640;

        button:nth-of-type(1) {
            background-color: @v2-dark-bg;
            border-color: @v2-primary-color;
        }
    }

    .ant-drawer-content {
        background-color: @windowBackgroundDark;
    }

    .ant-modal-confirm-btns {
        button:nth-of-type(1) {
            background-color: @v2-dark-bg;
            border-color: @v2-primary-color;
        }
    }

    .ant-pagination-prev .ant-pagination-item-link {
        color: @colorBackground;
        background-color: @colorDivider2Dark;
        border-color: @colorDivider2Dark;
    }

    .ant-pagination-next .ant-pagination-item-link {
        color: @colorBackground;
        background-color: @colorDivider2Dark;
        border-color: @colorDivider2Dark;
    }

    .ant-spin-nested-loading > div > .ant-spin {
        // background-color: @windowBackground2Dark;
        // background-color: rgba(23, 23, 23, 0.6);
    }

    .ant-tabs-ink-bar {
        background-color: @leaderboardTabletitlecolor;
    }

    .ant-tabs-tab-active {
        color: @leaderboardTabletitlecolor;
    }

    ::selection {
        color: @colorBackground;
        background: @leaderboardTabletitlecolor;
    }

    .ant-radio-button-wrapper-checked {
        box-shadow: -1px 0 0 0 transparent;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
        color: @leaderboardTabletitlecolor;
    }

    .ant-divider {
        color: @colorTextAccentDark;
        background-color: @colorDivider2Dark;
        border-top: 1px solid @colorDivider2Dark;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        box-shadow: -1px 0 0 0 transparent;
    }

    .ant-notification-bottomLeft {
        bottom: 0 !important;
        margin-left: 0;
    }

    .ant-notification-notice {
        margin-bottom: 0;
    }

    .ant-notification-notice-message {
        margin-left: 0;
    }

    .ant-notification-notice-close {
        top: 30px;
        color: @colorTextAccent;
    }

    .ant-tooltip-arrow::before {
        // background-color: rgb(87, 147, 241);
        background-color: @colorAccent2;
    }

    // .ant-radio-button-wrapper:hover{
    //   color: unset !important;
    // }

    .ant-tabs-tab-arrow-show {
        color: @colorText2Dark;
    }

    .ant-tabs-dropdown-menu {
        padding: 0;
        background-color: @colorBackgroundDark;
    }

    .ant-tabs-dropdown-menu-item:hover {
        background: #666666;
    }

    .ant-select {
        color: @colorTextAccentDark;
    }

    .ant-select-item {
        color: @colorTextAccentDark;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: unset;
    }
    .t-input-wrapper-placeholder {
        color: @colorTextAccentDark;
        //background-color: @v2-dark-bg;
    }
    .ant-radio-button-wrapper {
        color: @colorTextAccentDark;
        background-color: @v2-dark-bg;
    }
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        color: @colorTextAccentDark;
    }
    .ant-tabs-dropdown-menu-item {
        color: #ffffff;
    }
    .ant-picker,
    .ant-picker-header,
    .ant-picker-date-panel {
        color: @colorTextAccentDark;
        background-color: transparent;
        border-color: @v2-dark-13;
    }
    .ant-picker-panel {
        background-color: @v2-dark-bg;
        border-color: @v2-dark-bg;
    }
    .ant-picker-range-arrow::after {
        border-color: @v2-dark-bg;
    }
    .ant-picker-separator,
    .ant-picker-suffix {
        color: @colorTextAccentDark;
    }
    .ant-picker-input > input {
        color: @colorTextAccentDark;
    }
    .ant-picker-header button {
        color: @colorTextAccentDark;
    }
    .ant-picker-content {
        th,
        td {
            color: @colorTextAccentDark;
        }
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background: @colorBackgroundNewDark;
    }
    .ant-picker-cell-disabled .ant-picker-cell-inner {
        color: @colorTextDark3;
    }
    .ant-picker-cell-disabled::before {
        background: @v2-dark-bg;
    }
    .t-date-range-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .t-date-range-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
    .t-date-range-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: @colorBackgroundNewDark;
    }
    .t-date-range-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
    .t-date-range-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
        background: @v2-dark-bg;
    }
}
