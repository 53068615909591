@import "../../less/colors";

@contentwidth: 1180px;
@markOrange: #ff651e;
@fontNormalBold: bold;
@fontMedium: 500;

.contentwidth {
    max-width: @contentwidth;
    margin-right: auto;
    margin-left: auto;
}

.header_dropdown_overlay {
    :global {
        .ant-dropdown-menu-item,
        .ant-dropdown-menu-submenu-title {
            padding: 9px 30px 9px 25px;
        }

        .ant-dropdown-menu {
            padding-bottom: 9px !important;
            border-radius: 2px;

            :global(html.theme-dark) & {
                background-color: #0a0a0a;
                border-radius: 2px;
            }
        }
    }
}

// 仅用于需要此父级类和样式来实现元素父级滚动监听
.app_homepage {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.menu_bar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
    overflow: hidden;
    color: #ffffff;
    font-weight: @fontMedium;
    font-size: 14px;
    line-height: 16px;
    background-color: #0a0a0a;
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.menu_bar_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .menu_bar_left_item {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 35px;

        &:last-child {
            margin-right: 0;
        }

        .dropdown_comp_box {
            height: 100%;

            .menu_dropdown_txt {
                display: flex;
                align-items: center;
                // display: inline-block;
                height: 100%;
            }
        }
    }
}

.menu_bar_center {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 154px;
    height: 30px;
    font-size: 0;
    text-indent: -1000px;
    background-image: url("../../images/home/logo/Pionexlogo@2x.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transform: translate(-50%, -50%);
    cursor: pointer;
    &.isSG {
        background-image: url("../../images/home/logo/PionexSGlogo@2x.png");
    }
}

.menu_bar_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.menu_bar_signin {
    margin-right: 24px;
}

.menu_bar_register,
.menu_bar_myorders {
    margin-right: 36px;
}

.menu_bar_download {
    margin-right: 23px;
}

.menu_bar_setting {
    height: 100%;
    margin-right: 0;

    .dropdown_comp_box {
        height: 100%;

        .menu_dropdown_txt {
            display: flex;
            align-items: center;
            // display: inline-block;
            height: 100%;
        }
    }
}

.menu_dropdown_txt {
    display: flex;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
    user-select: none;
    :global(html.theme-light) & {
        color: #000000;
    }
    :global(html.theme-dark) & {
        color: #ffffff;
    }
    &.mark {
        padding: 2px 10px;
        color: #ffffff;
        line-height: 21px;
        background-color: @markOrange;
        border-radius: 2px;
    }

    &.trangle_ico {
        position: relative;

        &::after {
            position: relative;
            bottom: -2px;
            display: inline-block;
            margin-left: 6px;
            vertical-align: middle;
            border-style: solid;
            // width: 8px;
            // height: 8px;
            // margin-left: 8px;
            // background-repeat: no-repeat;
            // background-position: bottom;
            // background-size: contain;
            // background-image: url("../../images/home/icon/ico_trangle_more.png");
            // transition: transform linear 300ms;
            // transform-origin: center;
            // vertical-align: middle;
            // width:8px;
            // height:5px;
            // background: #fff;
            border-width: 4px;
            border-top-color: #ffffff;
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
            transform-origin: 4px 2px;
            transition: transform linear 300ms;
            content: "";
        }

        &.trangle_ico_top {
            &::after {
                transform: rotate(180deg);
            }
        }

        &.trangle_ico_bottom {
            &::after {
                transform: rotate(0deg);
            }
        }
    }
}

.menu_item_active {
    position: relative;
    color: rgba(51, 51, 51, 1);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background: rgba(232, 233, 234, 1) !important;
    cursor: default;
    &::before {
        position: absolute;
        top: 50%;
        left: 4px;
        display: inline-block;
        // margin-right: 7px;
        width: 5px;
        height: 13px;
        margin-top: -6px;
        background: #ff651e;
        border-radius: 2px;
        content: "";
        // vertical-align: middle;
        :global(html.theme-dark) & {
            background: #e65b1b;
        }
    }
    :global(html.theme-dark) & {
        color: #ffffff;
        background: rgba(43, 45, 50, 1) !important;
    }
}
.menu_item_box {
    display: flex;
    justify-content: space-between;
    .menu_item_ico {
        // min-width: 1em;
        // height: 1em;
        width: 17px;
        height: 17px;
        margin-right: 10px;
        // width: auto;
        vertical-align: top;

        :global(html.theme-dark) & {
            filter: invert(100%);
        }
        :global(html.theme-dark) &:global(.iconfont) {
            filter: none;
        }
    }

    .menu_item_txt {
        flex: 1;
    }
}

.forcehide {
    display: none !important;
}

.serve_link_methods_box {
    padding-top: 20px;

    .serve_link {
        display: block;

        & + .serve_link {
            margin-top: 20px;
        }

        .serve_link_ico {
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            border-radius: 50%;
        }

        .serve_link_name {
            margin-right: 8px;
        }

        .serve_link_loc {
            // cursor: text;
            user-select: text;
        }
    }
}

.downloadapp_ico {
    display: inline-block;
    width: 18.36px;
    height: 18.36px;
    margin-right: 4px;
    font-size: 0;
    text-indent: -1000px;
    vertical-align: top;
    background-image: url("../../images/home/icon/ico_downapp.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.setting_ico {
    display: inline-block;
    width: 18.36px;
    height: 18.36px;
    margin-right: 4px;
    font-size: 0;
    text-indent: -1000px;
    vertical-align: top;
    background-image: url("../../images/home/icon/ico_setting.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.setting_menu_overlay_box {
    :global {
        .ant-dropdown-menu-submenu-arrow {
            top: 9px;
        }
    }
}

.setting_sub_menu {
    max-height: 240px;
    overflow: auto;
    border-radius: 2px 0 0 2px;
    &::-webkit-scrollbar {
        width: 4px;
        border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-track {
        width: 4px;
        background: rgba(234, 238, 245, 1);
        border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
        width: 4px;
        background: rgba(115, 139, 176, 1) !important;
        border-radius: 2px;
    }

    :global {
        .ant-dropdown-menu {
            margin: 0;
            border-radius: 2px;
            :global(html.theme-dark) & {
                background-color: #0a0a0a;
                &::-webkit-scrollbar {
                    width: 4px;
                    border-radius: 0 2px 2px 0;
                }
                &::-webkit-scrollbar-track {
                    background: rgba(61, 90, 136, 1);
                    border-radius: 0 2px 2px 0;
                }
                &::-webkit-scrollbar-thumb {
                    background: rgba(40, 45, 53, 1) !important;
                    border-radius: 2px;
                }
            }

            .ant-dropdown-menu-item {
                padding: 11px 25px;
                font-size: 14px;
                line-height: normal;
            }
        }
    }
}

.ab_banner {
    display: flex;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    transition: all linear 450ms;
    .ad_banner_box {
        position: relative;

        .ad_bg {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        .ad_banner_cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .ad_banner_text_box {
            padding-top: 115px;
            padding-left: 43px;
            text-align: left;

            .text1 {
                color: #ffffff;
                font-weight: 300;
                font-size: 67px;
                line-height: 78px;
            }

            .text2 {
                margin-bottom: 26px;
                color: #ffffff;
                font-weight: @fontNormalBold;
                font-size: 60px;
                line-height: 71px;
                letter-spacing: 2px;
            }

            .mark {
                color: @markOrange;
            }

            .text3 {
                max-width: 520px;
                color: #8a8e95;
                font-weight: 200;
                font-size: 20px;
                line-height: 23px;
                word-break: normal;
            }
        }

        .operation_box {
            display: none;
        }
    }

    .ab_banner_left_area {
        flex: 1;
        background: linear-gradient(180deg, rgba(3, 26, 36, 1) 0%, rgba(24, 21, 22, 1) 100%);
    }

    .ab_banner_right_area {
        flex: 1;
        background: linear-gradient(178deg, rgba(5, 33, 47, 1) 0%, rgba(0, 4, 5, 1) 100%);
    }

    &.isnottimetoshow {
        min-height: 100px;
        opacity: 0;

        & > div {
            display: none;
        }
    }
}

.notice_box {
    position: relative;
    overflow: visible;
    background: #fcfcfc;
    visibility: visible;
}

.notice_list {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    // margin-bottom: 10px;
    padding-left: 50px;
    transform: translateY(-50px);

    .notice_item {
        position: relative;
        width: 326px;
        height: 100%;
        overflow: hidden;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0 8px 18px -5px rgba(0, 0, 0, 0.11);
        cursor: pointer;

        .content {
            position: relative;
            z-index: 1;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 15px 20px;
            border-radius: 5px;

            .notice_item_title {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 10px;
                color: rgba(5, 35, 55, 1);
                font-weight: 500;
                font-size: 20px;
                .notice_item_title_ico {
                    display: block;
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;

                    &.notice_list_announcement {
                        background-image: url("../../images/home/icon/Announcement@2x.png");
                    }

                    &.notice_list_update {
                        background-image: url("../../images/home/icon/Update@2x.png");
                    }

                    &.notice_list_activity {
                        background-image: url("../../images/home/icon/Activity@2x.png");
                    }
                }

                .notice_item_title_text {
                    display: block;
                    flex: 1;
                    color: #052337;
                    font-weight: @fontNormalBold;
                    font-size: 20px;
                    font-size: 20px;
                    text-align: left;
                }
            }

            .text_content {
                margin-bottom: 10px;
                color: rgba(152, 152, 152, 0.85);
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
            }

            .go_detail {
                position: absolute;
                right: 20px;
                bottom: 10px;
                text-align: right;
                .go_detail_btn {
                    position: relative;
                    width: 22px;
                    height: 22px;
                    background-color: transparent;
                    background-image: url("../../images/home/icon/gonext.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    // left: -50%;
                    opacity: 0;
                    transition: all ease-in-out 400ms;
                }
            }

            &:hover {
                .go_detail_btn {
                    // left: 0 !important;
                    opacity: 1 !important;
                }
            }
        }
    }
}
.fixedView {
    position: fixed;
    bottom: 0;
    left: -2px;
    width: 100%;
    transition: all linear 300ms;
    .notice_list {
        transform: none;
    }
}

.swiper_item {
    align-items: stretch !important;
    height: auto;
    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}
.notice_list_for_sign :extend(.notice_list all) {
    .notice_item {
        box-shadow: none;

        .go_detail {
            .go_detail_btn {
                opacity: 1 !important;
            }
        }
    }

    :global(html.theme-dark) & {
        .notice_item {
            background: #2a2e3a;

            .notice_item_title {
                .notice_item_title_text {
                    color: @colorTextAccentDark;
                }
            }

            .text_content {
                color: @colorTextAccentDark;
            }

            .go_detail {
                .go_detail_btn {
                    background-image: url("../../images/home/icon/gonext_dark.png");
                }
            }
        }
    }
}

.safe_intro {
    max-width: 100%;
    overflow-x: hidden;
    background: #f2f3f5;

    .safe_intro_content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 59px 45px 69px 50px;

        &::after {
            position: absolute;
            top: 0;
            left: 695px;
            z-index: 0;
            width: 668px;
            height: 100%;
            background-image: url("../../images/home/picture/operate_qual_bg.png");
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 100% auto;
            content: "";
        }

        & > div {
            position: relative;
            z-index: 1;
        }
    }
}

.operate_lasting_time {
    width: 420px;

    .operate_lasting_time_title {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-bottom: 8px;
    }

    .operate_lasting_time_title_mark {
        color: @markOrange;
        font-weight: @fontNormalBold;
        font-size: 38px;
    }

    .operate_lasting_time_title_txt {
        position: relative;
        top: -9px;
        margin-left: 0.6em;
        color: #052337;
        font-size: 20px;
    }

    .scroll_date_box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

.number_scroll_item {
    width: 32%;
    color: @markOrange;
    font-size: 124px;

    & + .number_scroll_item {
        margin-left: 1%;
    }

    :first-child {
        margin-left: 0;
    }
}

@keyframes frontFlipDown {
    0% {
        transform: rotateX(0);
        opacity: 1;
    }
    // 50%{
    //   transform: rotateX(90deg);
    //   opacity: 0;
    // }
    100% {
        transform: rotateX(90deg);
        opacity: 0;
    }
}

@keyframes backFlipDown {
    0% {
        transform: rotateX(-90deg);
    }
    100% {
        transform: rotateX(0);
    }
}

@rotate_scroll_timer: 600ms;

.rotate_scroll {
    position: relative;
    text-align: center;
    background-color: transparent;
    // background-color: #fff;
    background-image: url("../../images/home/picture/rotate_scroll_main_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    .rotate_scroll_top {
        position: relative;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 50%;
        overflow: hidden;
        // background: #ffffff;
        background-color: transparent;
        background-image: url("../../images/home/picture/rotate_scroll_base_bg.png");
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100% 100%;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
            width: 100%;
            height: 100%;
            // background: rgba(0, 0, 0, 0.11);
            background-color: transparent;
            background-image: url("../../images/home/picture/rotate_scroll_top_cover_bg.png");
            background-repeat: no-repeat;
            background-position: top;
            background-size: 101% 100%;
            content: "";
        }

        .rotate_scroll_top_pre {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: 100%;
            background: inherit;
            box-shadow: 0 -2px 6px rgba(255, 255, 255, 0.3);
            transform: rotateX(0);
            transform-origin: center bottom;
        }

        .rotate_scroll_top_back {
            position: relative;
            // left: 0;
            // top: 0;
            // width: 100%;
            // height: 100%;
            z-index: 1;
            background: inherit;
        }
    }

    .rotate_scroll_bottom {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 50%;
        overflow: hidden;
        // background: #ffffff;
        background: transparent;
        background-image: url("../../images/home/picture/rotate_scroll_base_bottom_bg.png");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100% 100%;
        .rotate_scroll_bottom_pre {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 200%;
            background: inherit;
            transform: rotateX(-90deg);
            // animation-delay: @rotate_scroll_timer;
        }

        .rotate_scroll_bottom_back {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 200%;
            background: inherit;
        }
    }

    &.running {
        .rotate_scroll_top {
            .rotate_scroll_top_pre {
                animation: frontFlipDown @rotate_scroll_timer ease-in-out;
                // animation-fill-mode: forwards;
            }
        }

        .rotate_scroll_bottom {
            .rotate_scroll_bottom_pre {
                animation: backFlipDown @rotate_scroll_timer ease-in-out;
                // animation-fill-mode: forwards;
            }
        }
    }
}

.dhms {
    display: flex;
    justify-content: space-between;

    .dhms_days {
        display: flex;
        // width: 124px;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        padding: 6px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0 3px 8px -2px rgba(204, 204, 207, 1);

        .dhms_days_item {
            flex: 1;
            height: 28px;
            color: #10375e;
            font-weight: 800;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            background: #e8e8e8;

            & + .dhms_days_item {
                margin-left: 5px;
            }

            :first-child {
                margin-left: 0;
            }
        }
    }

    .dhms_hms {
        display: flex;
        // width: 256px;
        flex: 2;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 10px;
        padding: 4px 15px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0 3px 8px -2px rgba(204, 204, 207, 1);

        .hms_item {
            flex: 1;

            .hms_item_val {
                color: #10375e;
                font-weight: 800;
                font-size: 20px;
            }

            .hms_item_unit {
                color: #10375e;
                font-weight: 500;
                font-size: 14px;
            }

            &:nth-child(2n) {
                text-align: center;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}

.operate_qual {
    max-width: 664px;
    :global {
        .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
            right: auto;
            left: 0;
        }

        .ant-tabs .ant-tabs-left-content {
            padding: 0;
            text-align: left;
            border: none;
        }

        .ant-tabs-left {
            display: flex;
        }

        .ant-tabs-left-bar {
            align-self: center;
            float: none;
        }

        .ant-tabs .ant-tabs-left-bar {
            float: none;
            margin: 0;
            border: none;
        }

        .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
            margin: 0 !important;
            padding-top: 24px !important;
            padding-bottom: 24px !important;
            // line-height: 22px;
            padding-left: 16px !important;
            color: #95a3b5;
            font-weight: @fontNormalBold;
            font-size: 20px;
            text-align: left;
        }

        .ant-tabs-nav .ant-tabs-tab-active {
            color: #000000 !important;
            font-weight: @fontNormalBold !important;
            font-size: 20px;
            // line-height: 24px;
        }

        .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
        .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
            width: 4px;
            background-color: @markOrange !important;
            border-radius: 2px;
        }

        .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
        .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background-color: #d8d8d8;
                border-radius: 2px;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
                content: "";
            }
        }
        // 以下适配ant4
        .ant-tabs {
            width: 526px;
            height: 100%;
            .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    justify-content: flex-end;
                    .ant-tabs-nav-list {
                        &::before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            width: 4px;
                            height: 100%;
                            background: rgba(216, 216, 216, 1);
                            border-radius: 2px;
                            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
                            content: "";
                        }
                        .ant-tabs-tab {
                            margin: 0 !important;
                            padding: 22px 18px;
                            color: rgba(149, 163, 181, 1);
                            font-weight: 600;
                            font-size: 20px;
                            &.ant-tabs-tab-active {
                                color: rgba(0, 0, 0, 1);
                            }
                        }
                        .ant-tabs-ink-bar {
                            right: auto;
                            left: 0;
                            z-index: 2;
                            width: 4px;
                            background: @markOrange;
                            border-radius: 2px;
                        }
                    }
                }
            }
            .ant-tabs-content-holder {
                margin: 0;
                border: none;
                .ant-tabs-content {
                    height: 100%;
                }
            }
        }
    }

    .qual_item_licensed {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        padding-bottom: 30px;
        // padding-left: 125px;
        // width: 414px;
        // text-align: right;
        // height: 282px;
        text-align: right;
        .mas_msb_logo {
            // padding-top: 74px;
            text-align: right;

            img + img {
                margin-left: 25px;
            }
        }

        .mas_msb_text {
            text-align: right;
            &::before {
                display: inline-block;
                width: 120px;
                height: 40px;
                background-image: url("../../images/home/logo/MSB.png");
                background-repeat: no-repeat;
                background-position: center right;
                background-size: auto 100%;
                content: "";
            }
            &.isSg {
                &::before {
                    width: 120px;
                }
            }
        }

        .qual_item_backed_text {
            color: #7f8c9d;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        .qual_item_backed_text_lin1 {
            text-align: right;
        }

        .qual_item_backed_text_lin2 {
            text-align: right;
        }
    }

    .qual_item_aggregated {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        // padding-left: 40px;
        // width: 414px;
        // text-align: right;
        // height: 282px;
        text-align: right;
        .bian_logo {
            margin-bottom: 7px;
            // padding-top: 108px;
            text-align: right;
        }

        .huobi_logo {
            margin-bottom: 26px;
            text-align: right;
        }

        .qual_item_backed_text {
            // width: 366px;
            height: 40px;
            color: #7f8c9d;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
        }
    }

    .qual_item_backed {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        // padding-left: 15px;
        // width: 414px;
        // text-align: right;
        // height: 282px;
        text-align: right;
        .bu_logo {
            // width: 217px;
            height: auto;
            margin-bottom: 31px;
            // padding-top: 103px;
            text-align: right;
        }

        .other_logo {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 20px;

            .zhenfund_logo {
                width: 116px;
                height: auto;
                margin-right: 28px;
            }

            .shunwei_logo {
                width: 27px;
                height: auto;
                margin-right: 28px;
            }

            .gaorong_logo {
                width: 115px;
                height: auto;
            }
        }

        .qual_item_backed_text {
            // width: 400px;
            height: 40px;
            color: #7f8c9d;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
        }
    }
}

.bot_support_row_box {
    background-color: #fcfcfc;
}

.bot_support {
    padding-top: 58px;
    padding-right: 45px;
    padding-bottom: 82px;
    padding-left: 50px;

    .bot_support_title_big {
        width: 800px;
        margin: 0 auto;
        color: #052337;
        font-weight: @fontNormalBold;
        font-size: 54px;
        line-height: 64px;
        text-align: center;
    }

    .bot_support_title_tip {
        margin-bottom: 44px;
        color: #6f6f6f;
        font-weight: 300;
        font-size: 40px;
        line-height: 64px;
        text-align: center;
    }

    .bot_support_title_tip_en {
        width: 727px;
        margin: 0 auto 44px;
        color: #001318;
        font-weight: 200;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
    }

    .bot_support_content {
        display: flex;
        justify-content: space-between;

        .bot_support_content_left {
            width: 527px;
            height: 289px;
            padding: 12px 14px;
            background: #ffffff;
            background-image: url("../../images/home/picture/bot_kline_bg.png");
            border-radius: 5px;
            box-shadow: 0 3px 9px -1px rgba(203, 203, 207, 1);
            cursor: pointer;

            .bot_support_content_left_title {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 300px;
                padding-top: 60px;
                padding-left: 25px;

                .bot_support_content_left_title_txt {
                    display: block;
                    flex: 1;
                    color: white;
                    font-weight: @fontNormalBold;
                    font-size: 31px;
                    line-height: 29px;
                    word-break: normal;
                }
                .bot_support_content_left_middle_txt {
                    display: block;
                    margin-top: 5px;
                    color: #ff6f2c;
                    font-size: 16px;
                    line-height: 29px;
                    word-break: normal;
                }
                .bot_support_content_left_desc_txt {
                    display: block;
                    margin-top: 5px;
                    color: white;
                    font-size: 16px;
                    line-height: 29px;
                    word-break: normal;
                }
            }
        }

        .bot_support_content_right {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 530px;

            .bot_intro_item {
                position: relative;
                display: flex;
                justify-content: space-between;
                width: 260px;
                height: 140px;
                margin-bottom: 10px;
                overflow: hidden;
                border-radius: 5px;
                // background: linear-gradient(to top right, red , yellow);
                cursor: pointer;
                .bot_intro_item_bg_content {
                    flex: 1;
                    // padding: 32px 20px;
                }

                .bot_intro_img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: right bottom;
                    background-size: 116px auto;
                }

                .bot_intro_txt_content {
                    position: relative;
                    z-index: 1;
                    // max-width: 150px;
                    padding: 32px 20px;
                    padding-right: 116px;
                    word-break: normal;

                    .bot_intro_item_title {
                        margin-bottom: 8px;
                        color: #ffffff;
                        font-weight: @fontNormalBold;
                        font-size: 16px;
                        line-height: 19px;
                    }

                    .bot_intro_item_txt {
                        color: #ffffff;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

.page_footer {
    padding-top: 53px;
    padding-bottom: 34px;
    background: #052337;
}

.about_my_com {
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
    padding: 10px 29px 44px 52px;
    font-weight: normal;
}

.easy_operation_box {
    flex: 1.5;
    margin-right: 20px;
    padding-top: 56px;
    color: #ffffff;
    h3 {
        color: inherit !important;
    }
    .operation_title {
        margin-bottom: 40px;
        color: inherit;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
    }

    .operation_title_en {
        width: 350px;
        color: #ffffff;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
    }

    .operation_box {
        display: flex;
        justify-content: flex-start;
        .operation_btn {
            width: 116px;
            height: 39px;
            padding: 0;
            color: @markOrange;
            font-weight: @fontNormalBold;
            font-size: inherit;
            font-size: 20px;
            text-align: center;
            background: none;
            border: 1px solid @markOrange;
            border-radius: 3px;
            outline: none;
            cursor: pointer;
            & + .operation_btn {
                margin-left: 39px;
            }

            &.operation_btn_mark {
                color: #ffffff;
                background: @markOrange;
            }
        }
    }
}

.bot_list {
    .bot_list_title {
        margin-bottom: 18px;
        color: #ffffff;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    .bot_list_items {
        display: flex;
        justify-content: flex-start;

        .col_list_itmes {
            margin-right: 40px;

            &:last-child {
                margin-right: 0;
            }
        }

        .bot_item {
            display: flex;
            height: 25px;
            margin-bottom: 20px;
            overflow: hidden;
            line-height: 25px;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            .bot_item_ico {
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: 10px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 25px 25px;
                border-radius: 50%;
            }

            .bot_item_name {
                display: inline-block;
                width: 150px;
                color: #ffffff;
                font-size: 13px;
                vertical-align: top;
            }

            &.active,
            &:hover {
                .bot_item_name {
                    font-weight: 500;
                }
            }
        }
    }
}

.communities_list {
    flex: 1;
    min-width: 120px;
    .communities_list_title {
        margin-bottom: 18px;
        color: #ffffff;
        font-weight: @fontNormalBold;
        font-size: 20px;
        line-height: 24px;
    }

    .communities_list_items {
        display: flex;
        justify-content: flex-start;

        .col_list_itmes {
            margin-right: 50px;

            &:last-child {
                margin-right: 0;
            }
        }

        .communities_item {
            height: 25px;
            margin-bottom: 20px;
            overflow: hidden;
            line-height: 25px;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            .communities_item_ico {
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: 10px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 25px 25px;
                border-radius: 50%;
            }

            .communities_item_name {
                display: inline-block;
                color: #ffffff;
                font-size: 13px;
                vertical-align: top;
            }

            &.active,
            &:hover {
                .communities_item_name {
                    font-weight: 500;
                }
            }
        }
    }
}

.page_rights {
    color: #ffffff;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
}

@media screen and (max-width: 1179px) {
    .page_footer {
        padding-top: 30px;
    }

    .about_my_com {
        flex-direction: column;
        padding-right: 0;
        padding-bottom: 27px;
        padding-left: 0;
    }

    .communities_list {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;

        .communities_list_title {
            margin-bottom: 20px;
            text-align: center;
        }

        .communities_list_items {
            flex-wrap: wrap;

            .communities_item {
                width: 50%;
            }
        }
    }

    .bot_list {
        width: 100%;
        margin-bottom: 69px;
        padding-right: 20px;
        padding-left: 20px;

        .bot_list_title {
            margin-bottom: 20px;
            text-align: center;
        }

        .bot_list_items {
            flex-wrap: wrap;

            .bot_item {
                display: flex;
                width: 50%;

                .bot_item_name {
                    flex: 1;
                    width: auto;
                }
            }
        }
    }

    .easy_operation_box {
        padding-right: 20px;
        padding-left: 20px;
        text-align: center;
        .operation_title {
            text-align: center;
        }

        .operation_box {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;

            .operation_btn {
                width: 154px;
                height: 24px;
                padding: 0;
                color: @markOrange;
                font-size: 12px;
                line-height: 24px;
                text-align: center;
                background: none;
                border: 1px solid @markOrange;
                border-radius: 2px;
                outline: none;
                cursor: pointer;
                & + .operation_btn {
                    margin-left: 28px;
                }

                &.operation_btn_mark {
                    color: #ffffff;
                    background: @markOrange;
                }
            }
        }
    }

    .download_row_box {
        display: none;
    }

    .bot_support {
        padding: 10px 5px 28px 5px;

        .bot_support_title_big {
            font-weight: 500;
            font-size: 20px;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
        }

        .bot_support_title_tip {
            margin-bottom: 14px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

        .bot_support_content {
            flex-direction: column;

            .bot_support_content_left {
                position: relative;
                width: 100%;
                height: auto;
                margin-bottom: 14px;
                padding-right: 5px;
                padding-left: 5px;

                &::after {
                    display: block;
                    width: 100%;
                    padding-top: 50%;
                    content: "";
                }

                .bot_support_content_left_title {
                    position: absolute;
                    top: 12px;
                    left: 14px;

                    .bot_support_content_left_title_ico {
                        width: 32px;
                        height: 32px;
                    }

                    .bot_support_content_left_title_txt {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .bot_support_content_right {
                width: 100%;

                .bot_intro_item {
                    width: calc(50% - 5px);
                    height: auto;
                    margin: 0 2.5px;
                    margin-bottom: 7px;

                    &::before {
                        display: block;
                        padding-top: 53.757%;
                        content: "";
                    }

                    .bot_intro_img {
                        background-size: 77px auto;
                    }

                    .bot_intro_item_bg_content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .bot_intro_txt_content {
                        padding: 7px;
                        padding-right: 77px;

                        .bot_intro_item_title {
                            margin-bottom: 5px;
                            font-weight: 500;
                            font-size: 13px;
                        }

                        .bot_intro_item_txt {
                            font-weight: 400;
                            font-size: 10px;
                            line-height: normal;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }

    .safe_intro {
        .safe_intro_content {
            flex-direction: column;
            padding: 10px;

            &::after {
                background: none;
            }
        }
    }

    .operate_lasting_time {
        width: 100%;

        .operate_lasting_time_title {
            display: flex;
            justify-content: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
        }

        .operate_lasting_time_title_mark {
            font-size: inherit;
        }

        .operate_lasting_time_title_txt {
            top: 0;
            font-size: inherit;
        }
    }

    .operate_qual {
        width: 100%;
        margin-top: 35px;
        background-image: url("../../images/home/picture/operate_qual_bg.png");
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100% auto;

        :global {
            .swiper-pagination {
                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    border: 1px solid rgba(125, 125, 125, 1) !important;
                }

                .swiper-pagination-bullet-active {
                    width: 8px;
                    height: 8px;
                    background: rgba(0, 0, 0, 0.5) !important;
                }
            }
        }

        .qual_item_licensed {
            width: 100%;
            padding-bottom: 40px;
        }

        .qual_item_aggregated {
            width: 100%;
            padding-bottom: 40px;

            .bian_logo {
                padding-top: 20px;
            }
        }

        .qual_item_backed {
            width: 100%;
            padding-bottom: 40px;

            .bu_logo {
                padding-top: 20px;
            }
        }

        .qual_name {
            position: absolute;
            top: 0;
            left: 0;
            padding: 4px 10px;
            color: #7f8c9d;
            font-size: 12px;
            background: #cccccc;
        }
    }

    .ab_banner .ad_bg {
        width: 100%;
        height: auto;
    }

    .notice_box {
        padding: 10px;
    }

    .notice_list {
        display: block;
        padding: 10px;
        transform: none;

        .notice_item {
            position: relative;
            width: 100%;
            padding-bottom: 36px;

            .blur_bg {
                bottom: 36px;
            }

            .content {
                padding: 15px;
                padding-bottom: 25px;

                .go_detail {
                    display: none;
                }
            }
        }

        :global {
            .swiper-pagination {
                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    border: 1px solid rgba(125, 125, 125, 1) !important;
                }

                .swiper-pagination-bullet-active {
                    width: 8px;
                    height: 8px;
                    background: rgba(0, 0, 0, 0.5) !important;
                }
            }
        }
    }

    .ab_banner {
        .ad_banner_box {
            .ad_banner_text_box {
                margin-bottom: 10px;
                padding-top: 15px;
                padding-left: 20px;
                .text1 {
                    color: #6e7478;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                }

                .text2 {
                    margin-bottom: 10px;
                    color: #6e7478;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;

                    .mark {
                        display: block;
                        color: #6e7478;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 29px;
                    }
                }

                .text3 {
                    display: none;
                }
            }

            .operation_box {
                display: flex;
                justify-content: center;

                .operation_btn {
                    width: 112px;
                    height: 24px;
                    padding: 0;
                    color: @markOrange;
                    font-size: 12px;
                    line-height: 24px;
                    text-align: center;
                    background: none;
                    border: 1px solid @markOrange;
                    border-radius: 2px;
                    outline: none;
                    cursor: pointer;

                    & + .operation_btn {
                        margin-left: 24px;
                    }

                    &.operation_btn_mark {
                        color: #ffffff;
                        background: @markOrange;
                    }
                }
            }
        }
    }

    .dropdown_comp_box {
        display: block;

        &.dropdown_force_static {
            & > div {
                position: relative !important;
                top: 0;
                left: 0;
            }

            :global {
                .ant-dropdown {
                    position: relative !important;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 766px) {
    .menu_bar_phone {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 50px;
        height: 100%;
        transform: translateY(-50%);
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: 18px 18px;
        // background-image: url("../../images/home/icon/ico_phone_menu.png");
        > i {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 2px;
            transform: translate(-50%, -50%);
            &,
            &::before,
            &::after {
                background-color: #00131a;
            }
            :global(html.theme-dark) & {
                &,
                &::before,
                &::after {
                    background-color: #ffffff;
                }
            }
            &::before,
            &::after {
                position: absolute;
                right: 0;
                height: 2px;
                content: "";
            }
            &::before {
                top: -8px;
                width: 100%;
            }
            &::after {
                bottom: -8px;
                width: 70%;
            }
        }
    }

    .menu_bar {
        padding: 0 10px;

        .menu_bar_left {
            display: none;
        }

        .menu_bar_right {
            display: none;
        }
    }
    .menu_dropdown_txt {
        &.trangle_ico {
            &::after {
                position: absolute;
                top: 50%;
                left: -30px;
                margin-top: -5px;
                :global(html.theme-light) & {
                    border-top-color: #000000;
                }
            }

            &.trangle_ico_top {
                &::after {
                    transform: rotate(0deg);
                }
            }

            &.trangle_ico_bottom {
                &::after {
                    transform: rotate(-90deg);
                }
            }
        }

        &.mark {
            padding: 0;
            color: @markOrange;
            background: none;
        }
    }
    .downloadapp_ico {
        :global(html.theme-light) & {
            filter: invert(1);
        }
    }
    .setting_ico {
        :global(html.theme-light) & {
            filter: invert(1);
        }
    }
}

.menu_bar_phone_drawer {
    :global {
        .ant-drawer-content-wrapper {
            .ant-drawer-content {
                overflow: hidden;
            }
        }

        .ant-dropdown-menu {
            background: none !important;
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }
}

.setting_menu_overlay_box {
    :global {
        .ant-dropdown-menu-submenu-arrow-icon {
            color: inherit !important;
        }
    }

    :global(html.theme-dark) & {
        :global {
            .ant-dropdown-menu {
                .ant-dropdown-menu-submenu-title:hover {
                    background-color: #858ca3;
                }
            }
        }
    }
}

.download_qrcode_wrap {
    margin-left: 54px;
}

.download_qrcode {
    width: 154px;
    height: 154px;
}

.download_app_popover {
    //box-shadow:0 3px 9px 0 rgba(11,25,54,0.07);
    //border-radius:7px;
    width: 407px;
    //padding-left: 20px;
    //padding-right: 20px;
    //padding-bottom: 20px;
    background-color: white;
    :global(html.theme-dark) & {
        background-color: #394054;
    }
}

.download_app_popover_item {
    display: flex;
    margin-top: 20px;
    background-color: white;
    :global(html.theme-dark) & {
        background-color: #394054;
    }

    .platform_icon {
        width: 30px;
        height: 30px;
    }

    .platform_icon_mobile {
        width: 4600/750vw;
        height: 4600/750vw;
    }

    .download_app_desc_wrap {
        position: relative;
        top: -6px;
        flex: 10;
        margin-left: 14px;
    }

    .download_app_desc_wrap_mobile {
        position: relative;
        top: 0;
        flex: 10;
        margin-left: 13px;
        padding-right: 2700/750vw;
    }

    .download_app_popover_item_title {
        color: @colorTextAccent;
        font-weight: 600;
        font-size: 24px;
        :global(html.theme-dark) & {
            color: @colorTextAccentDark;
        }
    }

    .download_app_popover_item_title_mobile {
        font-size: 3200/750vw;
    }

    .download_app_popover_item_desc {
        color: @colorText;
        font-weight: 400;
        font-size: 14px;
    }

    .download_app_popover_item_desc_mobile {
        display: inline-block;
        max-width: 37400/750vw;
        font-size: 2200/750vw;
    }

    .download_app_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 26px;
        color: @trade_actionbtn_bg_light;
        font-weight: 600;
        font-size: 14px;
        background-color: @windowBackground;
        border: none;
        border-radius: 13px;
        outline: none;
        cursor: pointer;

        :global(html.theme-dark) & {
            color: @trade_actionbtn_bg_dark;
            background-color: @helper_alert_hover_tab_dark;
        }
    }

    .download_app_button_mobile {
        font-size: 2800/750vw;
    }

    .download_app_button_disable {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 26px;
        color: #cfcfcf;
        font-weight: 600;
        font-size: 14px;
        background-color: @windowBackground;
        border: none;
        border-radius: 13px;
        cursor: no-drop;

        :global(html.theme-dark) & {
            color: @download_disable;
            background-color: @helper_alert_hover_tab_dark;
        }
    }
}

.popover_style {
    background-color: white;
}

.download_app_popover_item_disable {
    .download_app_popover_item_title {
        color: @helper_module_splitline_linght;
        font-weight: 600;
        :global(html.theme-dark) & {
            color: @download_disable;
        }
    }

    .download_app_popover_item_desc {
        color: @helper_module_splitline_linght;
        font-weight: 400;
        :global(html.theme-dark) & {
            color: @download_disable;
        }
    }
}

// mobile

.download_app_popover_mobile {
    width: 71000/750vw;
    padding-top: 4200/750vw;
    padding-bottom: 4200/750vw;
    background-color: white;
    border-radius: 500/750vw;
    box-shadow: 0 6px 19px -4px rgba(75, 75, 81, 0.12);

    :global(html.theme-dark) & {
        background-color: #394054;
    }
}

.download_app_popover_item_mobile {
    padding-right: 3400/750vw;
    padding-left: 3400/750vw;
}

.download_app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 73px 100px 93px;
    background-color: white;

    :global {
        .ant-popover-inner-content {
            padding: 20px;
            background-color: white;
            border-radius: 5px;
            :global(html.theme-dark) & {
                background-color: #394054;
            }
        }
        .ant-popover-inner {
            background-color: white;
            border-radius: 5px;
            box-shadow: 0 3px 9px 0 rgba(11, 25, 54, 0.07);
            :global(html.theme-dark) & {
                background-color: #394054;
                box-shadow: 0 3px 9px 0 rgba(11, 25, 54, 0.07);
            }
        }
        .ant-popover-content > .ant-popover-arrow {
            display: none;
        }
    }

    .download_app_left {
        //width: 464px;

        display: flex;
        flex-direction: column;
        align-items: center;

        .download_app_title {
            margin-bottom: 18px;
            color: #052337;
            font-weight: @fontNormalBold;
            font-size: 44px;
            line-height: 64px;
            :global(html.theme-dark) & {
                color: white;
            }
            &.download_app_title_home {
                :global(html.theme-dark) & {
                    color: #052337;
                }
            }
        }

        .dw_method_list {
            display: flex;
            margin-top: 40px;
            .dw_method_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-width: 450px;
                height: 70px;
                margin-bottom: 24px;
                padding: 0 23px;
                font-size: 22px;
                text-align: left;
                background: #fff;
                border: 1.5px solid #f0f0f2;
                border-radius: 35px;
                outline: none;
                cursor: pointer;

                :global(html.theme-dark) & {
                    background: #394054;
                    box-shadow: 0 3px 9px 0 rgba(11, 25, 54, 0.07);
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .dw_method_item_left {
                    flex: 1;
                    height: 36px;

                    .dw_method_item_ico {
                        display: inline-block;
                        width: 36px;
                        height: 36px;
                        margin-right: 20px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                    }

                    .dw_method_name {
                        display: inline-block;
                        height: 36px;
                        color: @colorTextAccent;
                        font-weight: 500;
                        line-height: 36px;
                        vertical-align: top;
                        :global(html.theme-dark) & {
                            color: @colorTextAccentDark;
                        }
                    }
                }

                .dw_method_item_right {
                    width: 72px;
                    height: 36px;
                    padding-right: 24px;
                    padding-left: 24px;
                    border-left: 1px solid #f2f3f5;

                    :global(html.theme-dark) & {
                        border-left: 1px solid #4e566b;
                    }

                    .dw_method_item_downico {
                        display: inline-block;
                        width: 23px;
                        height: 100%;
                        background-image: url("../../images/home/icon/ico_download.png");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 23px 23px;
                        :global(html.theme-dark) & {
                            background-image: url("../../images/home/icon/ico_download_night.png");
                        }
                    }
                }
            }
        }
    }

    .download_app_right {
        margin-top: 70px;

        img {
            width: 660px;
        }
    }
}

// en seo
.download_app_title_en {
    color: #00131a;
    font-weight: bold;
    font-size: 60px;
}

.download_app_subtitle_en {
    margin-top: 18px;
    color: rgba(0, 19, 26, 0.4);
    font-size: 18px;
    line-height: 20px;
}

.home_bots {
    position: relative;
    width: 326px;
    height: 185px;
    margin-bottom: 16px;
    cursor: pointer;
    .home_bots_title {
        height: 30px;
        margin: 0;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }
    .home_video {
        position: absolute;
        right: 20px;
        bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;