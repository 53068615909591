@import "~less/v2/vars.less";
@import "~less/colors";

@markOrange: #ff651e;

.download_alert_box {
    padding: 0 !important;
}

.download_alert {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: @colorBackground !important;
    box-shadow: 0 2px 4px 0 rgba(4, 38, 253, 0.08);

    .download_alert_left {
        display: flex;
        flex: 1;
        justify-content: flex-start;
    }

    .download_alert_logo_box {
        margin-right: 8px;

        .download_alert_logo {
            width: 44px;
            height: 44px;
        }
    }

    .download_alert_txtbox {
        .download_alert_name {
            color: @markOrange;
            font-weight: @fontNormalBold;
            font-size: 20px;
            line-height: 23px;
        }

        .download_alert_desc {
            margin: 0;
            color: #969dad;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
        }
    }

    .download_alert_down_btnbox {
        .download_alert_down_btn {
            padding: 5px 12px;
            color: #fcfcfc;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            background: @markOrange;
            border: none;
            border-radius: 17px;
            outline: none;
        }
    }
    :global {
        .ant-alert-close-icon {
            top: 5px;
            right: 10px;
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;