@import "~less/v2/vars.less";

.addCard {
    text-align: center;
    &Title {
        margin-top: 45px;
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
        .v2Theme-color(@v2-grey-16, @v2-dark-10);
    }
    &Step {
        width: 115px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 26px;
    }
    &Tips {
        .tips();
    }
    &Form {
        width: 460px;
        margin: 0 auto;
        text-align: left;
        .formStyle();
        textarea {
            resize: none;
        }
    }
    &Btns {
        text-align: center;
        :global {
            .t-button {
                width: 172px;
                margin-bottom: 110px;
                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }
    &Lable {
        .v2Theme-color(@v2-grey-16, @v2-dark-10);
        margin: 24px 0 12px;
    }

    .nameTips {
        margin-top: 6px;
        font-size: 13px;
        .v2Theme-color(@v2-grey-22, @v2-grey-13);
    }

    &LightWarn {
        color: @v2-primary-color;
    }
    &Warn {
        .v2Theme-color(@v2-grey-16, @v2-grey-6);
    }
    &Hint {
        margin-top: 30px;
        color: @v2-grey-14;
    }
    &Upload {
        margin: 16px 0 0px;
        :global {
            .ant-upload {
                width: 100%;
            }
        }
    }
    &Success {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 700px;
        padding-top: 90px;
        &Icon {
            text-align: center;
            img {
                width: 128px;
            }
        }
        &Title {
            margin-top: 24px;
            color: @v2-success-color;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
        }
        &Tips {
            .v2Theme-color(@v2-grey-20, @v2-grey-13);
            width: 352px;
            margin-top: 12px;
            text-align: left;
        }
        &Btn {
            margin-top: 50px;
            :global {
                .t-button {
                    width: 325px;
                }
            }
        }
    }
}

.formStyle {
    :global {
        .ant-input {
            border-color: @v2-grey-18;
            .v2DarkTheme({
                border-color: @v2-dark-13;
            });
        }
        .t-input-wrapper-has-error {
            .ant-input {
                border-color: @v2-danger-color;
                .v2DarkTheme({
                    border-color: @v2-danger-color;
                });
            }
        }
        .ant-form-item-has-error {
            .ant-select-selection-placeholder {
                color: @v2-danger-color;
                opacity: 1;
            }
        }
        .ant-form-item-with-help {
            margin-bottom: 24px;
        }
        .t-select.size-large .ant-select-selector {
            font-size: 14px !important;
        }
    }

    .nameTips {
        margin-top: 6px;
        font-size: 13px;
        .v2Theme-color(@v2-grey-22, @v2-grey-13);
    }
}

.tips {
    width: 460px;
    margin: 0 auto;
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    border-radius: 12px;
    .v2Theme-color(@v2-warn-color, @v2-warn-color-dark);
    .v2Theme-background-color(@v2-warn-bg, @v2-warn-bg-dark);
}

.step {
    margin-bottom: 16px;
    .v2Theme-color(@v2-grey-20,@v2-grey-13);
}

.cardLabel {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    .v2Theme-color(@v2-grey-16, @v2-dark-10);
}

.cardWarn {
    .v2Theme-color(@v2-grey-20, @v2-grey-13);
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    .cardDesc {
        font-weight: 400;
        font-size: 14px;
    }
    .cardRequire {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    .cardUpload {
        width: 100%;
        margin-top: 16px;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;