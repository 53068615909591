@import "../../../../../../less/colors.less";

.contentWrapper {
    display: flex;
    flex-direction: column;
    height: 400px;
    padding-top: 20px;
    overflow-y: auto;
    background: @colorBackground;
    box-shadow: 0 0 12px 6px rgba(185, 185, 185, 0.09);

    :global(html.theme-dark) & {
        background: @colorBackgroundDark;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    .list {
        height: 100%;
        overflow-y: auto;
    }

    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-left: 20px;
        cursor: pointer;

        &.text {
            font-size: 14px;
        }
    }

    .item:hover {
        background-color: @colorDivider2;
        cursor: pointer;

        :global(html.theme-dark) & {
            background: @colorDivider2Dark;
        }
    }
}

.header {
    padding-left: 20px;
}

.searchInput {
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.dropDownIcon {
    margin-bottom: 2px;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;