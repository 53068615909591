@import "~less/v2/vars.less";

.assetDisplay {
    flex: 1;
    padding: 20px;
    border-radius: 16px;
    .v2Theme-background-color(#fff, @v2-grey-15);
    &Title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        .v2Theme-color(@v2-grey-16, @v2-dark-10);
    }
    &Sum {
        margin-top: 36px;
        margin-bottom: 20px;
        &Title {
            margin-bottom: 5px;
            font-size: 12px;
            line-height: 17px;
            .v2Theme-color(@v2-grey-14, @v2-dark-5);
        }
        &Data {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            .v2Theme-color(@v2-grey-16, @v2-dark-10);
        }
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;