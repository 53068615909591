@import "../../../../less/colors";
@import "~less/v2/vars.less";
.historyContent {
    display: block;
    margin-bottom: 20px;
}

.historyList {
    display: none;
}

.card_style {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
    background-color: @colorBackground3;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    :global(html.theme-dark) & {
        background-color: @colorBackground3Dark;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    }
}

.dwHistoryItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
        font-weight: bold;
        font-size: 14px;
    }

    .content {
        margin-top: 2px;
        font-size: 14px;
    }

    .row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
    }
}

.dwTable {
    padding: 0 20px;

    :global {
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
            padding: 10px 2px;
            border-bottom-color: @colorBackground !important;

            :global(html.theme-dark) & {
                border-bottom-color: @colorBackgroundDark !important;
            }
        }

        .ant-table-expand-row {
            .td {
                display: none !important;
            }
        }

        .ant-card-head {
            border-bottom: none !important;
        }

        .ant-btn-primary {
            background: @colorAccent !important;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .dwTable {
        padding: 0 10px;
    }

    .historyContent {
        display: none;
    }

    .historyList {
        display: block;
        padding: 10px;

        :global(.ant-btn-primary) {
            background: @colorAccent !important;
        }
    }
}

.checkBoxWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    :global {
        .ant-checkbox-wrapper {
            color: @colorAccent2;
            font-size: 12px;

            :global(html.theme-dark) & {
                color: @colorAccent2;
                font-weight: bold;
                font-size: 12px;
            }

            :global(html.theme-light) & {
                color: @colorAccent2;
                font-weight: bold;
                font-size: 12px;
            }
        }

        .ant-checkbox-wrapper:hover {
            color: @colorAccent2;

            :global(html.theme-dark) & {
                color: @colorAccent2;
            }
        }

        .ant-checkbox-inner {
            background-color: transparent;
            border-color: @colorAccent2;
        }

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: @colorAccent2;
                border-color: @colorAccent2;
            }
        }

        .ant-checkbox-inner {
            border-radius: 15px;
        }

        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: @colorAccent2;
        }

        .ant-checkbox-checked::after {
            border: none !important;
        }
    }
}

.withdrawCheckWrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    :global {
        .ant-checkbox-wrapper {
            display: flex;
            font-size: 12px;
            word-break: keep-all;
        }

        .ant-checkbox-inner {
            border-radius: 15px;
        }
    }
}

.depositQRWrapper {
    :global {
        .ant-btn-ghost {
            border-color: @colorAccent !important;

            span {
                color: @colorAccent !important;
            }
        }
    }
}

.depositListWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    background: transparent;
    span {
        font-size: 14px;
    }

    .recordButton {
        min-width: 60px;
        color: @colorTextAccent;
        font-size: 13px;
        line-height: normal;

        :global(html.theme-dark) & {
            color: @colorTextAccentDark;
        }
    }
}

.listPopStyle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    background-color: @windowBackground;

    :global(html.theme-dark) & {
        background-color: @windowBackgroundDark;
    }

    :global {
        .ant-btn-ghost {
            border-color: #ff705d !important;

            span {
                color: #ff705d !important;
            }
        }
    }

    span {
        color: @colorTextAccent;
        font-size: 14px;

        :global(html.theme-dark) & {
            color: @colorTextAccentDark;
        }
    }
}

.listStyle {
    background-color: @windowBackground;

    :global(html.theme-dark) & {
        background-color: @v2-grey-15;
    }

    :global {
        .ant-list-header {
            padding-top: unset;
            padding-bottom: unset;
            border-bottom: unset;
        }

        .ant-list-empty-text {
            padding: 0;
        }
        .ant-spin-nested-loading > div > .ant-spin {
            .v2DarkTheme({
                background-color: @v2-grey-15;
            });
        }
    }

    .oddIndexItem {
        display: flex;
        align-items: center;
        height: 40px;
        background: rgba(247, 248, 250, 1);

        :global(html.theme-dark) & {
            background: @v2-grey-15;
        }
    }

    .evenIndexItem {
        display: flex;
        align-items: center;
        height: 40px;
        background: @colorBackground;

        :global(html.theme-dark) & {
            background: @v2-table-odd-bg-dark-color;
        }
    }
}

.popWarningTopArrow {
    width: 10px;
    height: 10px;
    margin-top: -5px;
    background-color: @windowBackground;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);

    :global(html.theme-dark) & {
        background-color: @windowBackgroundDark;
    }
}

.attentionLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    flex: 1;
    padding: 24px;
}

.blurLayer {
    filter: blur(30px);
}

.unBlurLayer {
    position: relative;
    width: 100%;
    height: 100%;
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;