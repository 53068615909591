@import "~less/variables.less";
@import "../../../../less/colors.less";
@import "~less/v2/vars.less";

.wrapper {
    display: flex;
    gap: 12px;
}

.orderBalancePieWrap {
    // flex: 1 1 50%;
    width: 50%;
    min-height: 260px;
    padding: 16px 22px 20px;
    background-color: @colorBackground2;
    border-radius: 16px;

    .v2MediaMaxLG({
        width: 100%;
    });
    :global(html.theme-dark) & {
        background-color: @v2-grey-15;
        box-shadow: none;
    }

    .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        .v2Theme-color(@v2-grey-16, @v2-dark-10);
    }
}

.todayProfitWrapper {
    flex: 1;
    height: 350px;
    margin-right: 16px;
    padding: 20px 20px 25px;
    background-color: @colorBackground2;
    border-radius: 6px;
    :global(html.theme-dark) & {
        background-color: @v2-grey-15;
        box-shadow: none;
    }

    .cutLine {
        width: 100%;
        height: 1px;
        margin: 32px 0;
        background-color: @activityTablheader;

        :global(html.theme-dark) & {
            background-color: @helper_module_splitline_dark;
        }
    }

    .iconSize {
        width: 26px;
        height: 26px;
    }

    .subTitle {
        color: #8a8f99;
        font-weight: 400;
        font-size: 20px;
    }

    .value {
        margin-top: 10px;
        margin-bottom: 8px;
        color: @colorTextAccent;
        font-weight: 400;
        font-size: 20px;

        :global(html.theme-dark) & {
            color: @colorTextAccentDark;
        }
    }

    .detailsButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 79px;
        height: 30px;
        background: @colorNav;
        border-radius: 3px;
        cursor: pointer;

        :global(html.theme-dark) & {
            color: @colorBackgroundNewDark;
            background-color: @colorBackgroundNewDark;
        }

        > span {
            color: #2c75ff;
            font-size: 16px;
        }
    }

    .title {
        margin-bottom: 18px;
        color: #040404;
        font-weight: 600;
        font-size: 24px;

        :global(html.theme-dark) & {
            color: @colorBackground2;
        }
    }

    .TradingAccountTodayProfitItemWrap {
        display: flex;
        flex: 1;
        justify-content: space-between;
    }

    .todayProfit {
        width: 95px;
        height: 30px;
        color: #ffffff;
        font-size: 20px;
        line-height: 35px;
        text-align: center;
        border-radius: 3px;
    }
}

@primary-color: #ff7028;@link-color: #ff7028;@color-primary: 255 112 40;@color-primary-50: 255 237 227;@color-primary-100: 255 220 203;@color-primary-200: 255 194 164;@color-primary-300: 255 164 118;@color-primary-400: 255 133 72;@color-primary-500: 255 112 40;@color-primary-600: 242 90 13;@color-primary-700: 214 72 0;@color-primary-800: 168 56 0;@color-primary-900: 130 43 0;@PIONEX_US: false;